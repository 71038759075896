import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetEducationBlocks } from '@/features/courses/api';
import { Breadcrumbs, EducationCard, TitleWithBackButton } from '@/features/courses/components';
import { styles } from '@/features/courses/styles';
import { ROUTES } from '@/routes';
import { Error, Skeleton } from '@funfarm/kit';

export const EducationCourse = () => {
  const { t } = useTranslation();

  const { courseId } = useParams() as { courseId: string };

  const { data: blocks, isLoading, isError } = useGetEducationBlocks(courseId);

  if (isLoading || !blocks) return <Skeleton />;
  if (isError) return <Error message={t('fetchingError')} />;

  const backLink = ROUTES.courses;
  const courseTitle = blocks[0].course_title;

  return (
    <>
      <Breadcrumbs links={[{ href: backLink, title: t('education.courses') }, { title: courseTitle }]} />
      <TitleWithBackButton title={courseTitle} link={backLink} />
      <div className={styles.container}>
        {blocks.map((block) => (
          <EducationCard
            key={block.id}
            {...block}
            link={ROUTES.modulesByBlockId({ courseId, blockId: block.id })}
          />
        ))}
      </div>
    </>
  );
};
