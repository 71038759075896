import React, { CSSProperties } from 'react';
import classNames from 'classnames';

import { IconSpinner } from '@funfarm/kit';
import { ESizes } from '@funfarm/kit/types';

import css from './loader.module.scss';

interface IProps {
  layout?: boolean;
  caption?: string;
  size?: keyof typeof ESizes;
  className?: string;
  style?: CSSProperties;
}

export const Loader = (props: IProps) => {
  const { layout, caption, size = ESizes.medium, className, style } = props;

  return (
    <div
      className={classNames(css.container, layout && css.layout, className)}
      style={style}
    >
      <IconSpinner className={classNames('spin', css[size])} />
      {caption && (
        <p className={classNames(css.caption, css[size])}>{caption}</p>
      )}
    </div>
  );
};
