import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Box, HStack, Text } from '@/shared/components';

export const AllTournamentsHeader = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box $borderTop={`2px solid ${theme.color['canvas-500']}`} $bgColor="canvas">
      <HStack $ml={14} $mr={4} $px="px" $h={12} $justifyContent="space-between">
        <Text as="span" $fontWeight={600} $color="gray-200" children={t('global.allTourneys')} />
      </HStack>
    </Box>
  );
};
