import React, {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  IconSettings,
  Switcher,
  TableContext,
  tableStorage,
  TColumn,
  useOnClickOutside,
} from '@funfarm/kit';

import css from './settings.module.scss';

interface IProps {
  columns: TColumn[];
  tableName: string;
  button?: ReactElement;
}

export const Settings = (props: IProps) => {
  const { columns, tableName, button } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const { tableColumns, setTableColumns } = useContext(TableContext);

  const [open, setOpen] = useState<boolean>(false);

  useOnClickOutside(containerRef, async () => setOpen(false));

  useEffect(() => {
    setTableColumns &&
      setTableColumns((prevColumns) => {
        const tableSettingsString = tableStorage.getItem();
        const tableSettings = tableSettingsString
          ? JSON.parse(tableSettingsString)
          : {};
        const defaultColumns =
          tableSettings[tableName] ??
          columns.filter((col) => col.default).map((col) => col.name);

        const uniquieColumns = new Set([...prevColumns, ...defaultColumns]);

        tableStorage.setItem(
          JSON.stringify({
            ...tableSettings,
            [tableName]: [...uniquieColumns],
          }),
        );

        return [...uniquieColumns];
      });
  }, [columns, setTableColumns, tableName]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    setTableColumns &&
      setTableColumns((prevColumns) => {
        const tableSettingsString = tableStorage.getItem();
        const tableSettings = tableSettingsString
          ? JSON.parse(tableSettingsString)
          : {};
        const checkedColumns = checked
          ? [...prevColumns, value]
          : prevColumns.filter((pcol) => pcol !== value);

        tableStorage.setItem(
          JSON.stringify({ ...tableSettings, [tableName]: checkedColumns }),
        );

        return checkedColumns;
      });
  };

  return (
    <div className={css.container} ref={containerRef}>
      {button ? (
        React.cloneElement(button, { onClick: () => setOpen((open) => !open) })
      ) : (
        <IconSettings onClick={() => setOpen((open) => !open)} />
      )}
      {open && (
        <div className={css.actionContainer}>
          {columns.map((col) => {
            return (
              <Switcher
                key={col.name}
                value={col.name}
                label={col.label}
                checked={tableColumns?.includes(col.name)}
                labelPosition="right"
                className={css.checkbox}
                onChange={onChange}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
