import React, {
  ChangeEvent,
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import {
  ELabelPosition,
  IconChevronDown,
  IconChevronUp,
  IconSpinner,
  IEventTarget,
  IOption,
} from '@funfarm/kit';

import css from './select.module.scss';

export interface ISelectProps extends InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  empty?: boolean | string;
  value?: string | number;
  label?: string;
  defaultLabel?: string;
  options: IOption[];
  labelPosition?: keyof typeof ELabelPosition;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  onInput?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  error?: string | boolean;
  displayError?: boolean;
  loading?: boolean;
  dataTestId?: string;
  addonRight?: ReactElement;
  dropUp?: boolean;
}

export const Select = forwardRef(
  (props: ISelectProps, _ref: ForwardedRef<HTMLInputElement>) => {
    const {
      name,
      label,
      labelPosition = ELabelPosition.top,
      error,
      displayError,
      required,
      disabled,
      readonly,
      placeholder,
      onFocus,
      onBlur,
      onChange,
      defaultLabel,
      empty,
      className,
      style,
      options,
      loading,
      dataTestId,
      addonRight,
    } = props;
    const value = props.value ? props.value.toString() : '';

    const [currentLabel, setLabel] = useState<ISelectProps['defaultLabel']>(
      defaultLabel ?? '',
    );
    const [open, setOpen] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);
    const [currentOptions, setOptions] = useState<IOption[]>(options ?? []);

    const labelRef = useRef<HTMLInputElement>(null);
    const valueRef = useRef<HTMLInputElement>(null);

    const handleSetLabel = useCallback(
      (optionValue: string = value) => {
        const option = currentOptions.filter(
          (option) => option.value.toString() === optionValue,
        );

        if (labelPosition === ELabelPosition.inside && !value) {
          setLabel(label);

          return;
        }

        if (option[0]) setLabel(option[0].label);
        else setLabel(defaultLabel ?? (loading ? '' : optionValue));
      },
      [currentOptions, value, defaultLabel, label, labelPosition, loading],
    );

    useEffect(() => {
      handleSetLabel(value);
    }, [value, handleSetLabel]);

    useEffect(() => {
      if (empty && !required) {
        const label = typeof empty === 'string' ? empty : '...';

        options.unshift({ label, value: '' });
      }

      setOptions(options);
    }, [options, empty, required]);

    useEffect(() => {
      handleSetLabel();
    }, [currentOptions, handleSetLabel]);

    const handleSetValue = useCallback((option: IOption) => {
      if (option.disabled) return;

      if (valueRef.current) {
        valueRef.current.value = option.value.toString();

        const event = new Event('input', { bubbles: true });

        valueRef.current.dispatchEvent(event);
      }
    }, []);

    const handleChange = (
      event: ChangeEvent<HTMLInputElement> | IEventTarget,
    ) => {
      if (onChange) onChange(event as ChangeEvent<HTMLInputElement>);
    };

    const handleOpen = useCallback(() => {
      if (readonly || disabled) return;

      setOpen(true);
    }, [disabled, readonly]);

    const handleClose = useCallback(() => {
      setOpen(false);
    }, []);

    const handleFocus = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (readonly || disabled) return;

        setFocus(true);
        labelRef.current?.focus();

        handleOpen();

        if (onFocus) onFocus(event);
      },
      [disabled, readonly, handleOpen, onFocus],
    );

    const handleBlur = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setFocus(false);

        handleClose();

        if (onBlur) onBlur(event);
      },
      [handleClose, onBlur],
    );

    return (
      <div
        className={classNames(
          css.select,
          error && css['error'],
          disabled && css['disabled'],
          readonly && css['readonly'],
          css.labelPosition,
          css[labelPosition],
          className,
        )}
        style={style}
      >
        {label && labelPosition !== ELabelPosition.inside && (
          <label
            htmlFor={name}
            className={classNames(css.label, css[labelPosition])}
          >
            {label}
            {required && <span className={css.mark}>*</span>}
          </label>
        )}
        <div className={css.errorWrapper}>
          <div className={css.addonWrapper}>
            <div
              className={classNames(
                css.wrapper,
                disabled && css.wrapperDisabled,
                readonly && css.wrapperReadonly,
                error && css.wrapperError,
                focus && css.focus,
              )}
            >
              <input
                id={name}
                readOnly
                type="hidden"
                name={name}
                value={value}
                disabled={disabled}
                onInput={(e) =>
                  handleChange(e as ChangeEvent<HTMLInputElement>)
                }
                data-testid={dataTestId}
                ref={valueRef}
              />
              <input
                id={name + '-label'}
                name={name + '-label'}
                disabled={disabled}
                required={required}
                readOnly
                placeholder={placeholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={currentLabel}
                type="text"
                autoComplete="off"
                ref={labelRef}
                className={classNames(
                  labelPosition === ELabelPosition.inside &&
                    !value &&
                    css[labelPosition],
                  labelPosition === ELabelPosition.inside &&
                    !!value &&
                    css.focus,
                )}
              />
              {loading ? (
                <IconSpinner className={classNames('spin', css.chevron)} />
              ) : open ? (
                <IconChevronUp
                  className={css.chevron}
                  onClick={() =>
                    handleBlur({} as ChangeEvent<HTMLInputElement>)
                  }
                />
              ) : (
                <IconChevronDown
                  className={css.chevron}
                  onClick={() =>
                    handleFocus({} as ChangeEvent<HTMLInputElement>)
                  }
                />
              )}
              <ul className={classNames(css.options, open && css.open)}>
                {currentOptions.map((option) => (
                  <li
                    key={option.value}
                    className={classNames(
                      css.option,
                      option.disabled && css.disabled,
                    )}
                    onMouseDown={() => handleSetValue(option)}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            </div>
            {addonRight &&
              React.cloneElement(addonRight, {
                className: classNames(
                  addonRight.props.className,
                  css['addonRight'],
                ),
              })}
          </div>
          {displayError && error && <p className={css.error}>{error}</p>}
        </div>
      </div>
    );
  },
);
