import React from 'react';
import { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { t } from 'i18next';
import css from '@funfarm/lk/src/__old/components/Finances/finances.module.scss';
import { timestampToDate } from '@funfarm/lk/src/__old/helpers';
import {
  money,
  symbolToCurrencyCode,
} from '@funfarm/lk/src/__old/helpers/numbers';
import { Badge } from '../Badge';
import { EColors } from '../types';

const columnTypes = {
  date: {
    valueFormatter: (p: ValueFormatterParams) => timestampToDate(p.value),
  },
  amount: {
    valueGetter: (p: ValueGetterParams) =>
      !p.data.amount
        ? '-'
        : money(Number(p.data.amount), symbolToCurrencyCode(p.data.currency)),
  },
  usdValue: {
    valueFormatter: (p: ValueFormatterParams) =>
      !p.value ? '-' : money(Number(p.value), 'USD'),
  },
  highlightAmount: {
    cellRenderer: (p: ValueFormatterParams) => (
      <span className={Number(p.value) > 0 ? css.green : css.red}>
        {Number(p.value) > 0 && '+ '}
        {money(Number(p.value), symbolToCurrencyCode(p.data.currency))}
      </span>
    ),
  },
  amountCurrencyObject: {
    valueGetter: (p: ValueGetterParams) =>
      money(p.data.amount, symbolToCurrencyCode(p.data.currency.abrname)),
  },
  roomObject: {
    valueFormatter: (p: ValueFormatterParams) => p.value.title,
  },
  accountLogo: {
    cellRenderer: (p: ValueFormatterParams) => {
      for (const title in accountLogos) {
        if (p.value.toLowerCase().includes(title))
          return (
            <img
              src={accountLogos[title]}
              alt={title}
              style={{
                maxHeight: '18px',
                maxWidth: '72px',
              }}
            />
          );
      }
      return p.value;
    },
  },
  badge: {
    cellRenderer: (p: ValueFormatterParams) => (
      <div style={{ overflow: 'hidden' }}>
        <Badge
          size="small"
          color={
            (p.colDef.refData![p.value] as keyof typeof EColors) || 'default'
          }
        >
          {p.colDef.refData!.translationPrefix
            ? t(p.colDef.refData!.translationPrefix + p.data.package_status)
            : p.value}
        </Badge>
      </div>
    ),
  },
};

const accountLogos: Record<string, string> = {
  skrill: '/logo/skrill.png',
  luxon: '/logo/luxon.png',
};

export default columnTypes;
