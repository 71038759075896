import { env } from 'env';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Session from 'supertokens-auth-react/recipe/session';

export const initSuperTokens = () => {
  SuperTokens.init({
    appInfo: {
      // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
      appName: 'FunFarm',
      apiDomain: env.FF_API_URL,
      websiteDomain: env.FF_APP_URL,
      apiBasePath: '/api/auth',
      websiteBasePath: '/',
    },
    recipeList: [
      //EmailVerification.init({
      //    mode: 'OPTIONAL', // or "OPTIONAL"
      //}),
      EmailPassword.init({
        onHandleEvent: (context) => {
          if (context.action === 'PASSWORD_RESET_SUCCESSFUL') {
            //
          } else if (context.action === 'RESET_PASSWORD_EMAIL_SENT') {
            //
          } else if (context.action === 'SUCCESS') {
            if (context.createdNewSession) {
              // console.log('context.user', context.user);

              if (context.isNewRecipeUser && context.user.loginMethods.length === 1) {
                // sign up success
              } else {
                // sign in success
              }
            } else {
              // this is during second factor login of step up auth flow
            }
          }
        },
      }),
      Session.init({
        tokenTransferMethod: 'header', // or "cookie"
      }),
    ],
  });
};
