import { JSX } from 'react';
import { clsx } from 'clsx';
import * as RadixDialog from '@radix-ui/react-dialog';
import styles from './dialog.module.scss';

interface Props {
  title: string;
  trigger?: JSX.Element;
  content: JSX.Element;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  closeTrigger?: JSX.Element;
  contentClassName?: string;
  titleClassName?: string;
}

export const Dialog = ({
  title,
  trigger,
  content,
  open,
  onOpenChange,
  closeTrigger,
  contentClassName,
  titleClassName,
}: Props) => (
  <RadixDialog.Root open={open} onOpenChange={onOpenChange}>
    {trigger && (
      <RadixDialog.Trigger asChild className={styles.trigger}>
        {trigger}
      </RadixDialog.Trigger>
    )}
    <RadixDialog.Portal>
      <RadixDialog.Overlay className={styles.overlay} />
      <RadixDialog.Content className={clsx(styles.content, contentClassName)} aria-describedby={undefined}>
        <RadixDialog.Title className={clsx(styles.title, titleClassName)}>{title}</RadixDialog.Title>
        {content}
        {closeTrigger && (
          <RadixDialog.Close asChild className={styles.close}>
            {closeTrigger}
          </RadixDialog.Close>
        )}
      </RadixDialog.Content>
    </RadixDialog.Portal>
  </RadixDialog.Root>
);
