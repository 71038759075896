import classNames from 'classnames';
import { CountInfo } from '@/features/courses/components';
import type { EducationItem } from '@/features/courses/types';
import { EducationCardButtons } from './EducationCardButtons/EducationCardButtons';
import styles from './EducationCard.module.scss';

type Props = EducationItem & {
  link: string;
};

export const EducationCard = (props: Props) => {
  const {
    link,
    title,
    description,
    total_length_minutes,
    number,
    image_url,
    is_available,
    is_done,
    tasks_done,
    tasks_total,
  } = props;

  const isModuleInProgress = is_available && !is_done && tasks_done;
  const progress = Math.round((tasks_done / tasks_total) * 100);

  return (
    <div className={styles.card}>
      <img src={image_url} alt={title} className={classNames(styles.img, !is_available && styles.grayscale)} />
      <div className={styles.cardItem}>
        {!!isModuleInProgress && <div style={{ width: `${progress}%` }} className={styles.progress} />}
        <div className={classNames(styles.cardInfo, !is_available && styles.opacityGrey)}>
          <div className={classNames(styles.title, is_done && styles.grey400)}>
            <div>{number}</div>
            <div>{title}</div>
          </div>
          <div className={styles.description}>{description}</div>
          <CountInfo
            className={is_done ? styles.grey400 : undefined}
            tasks_total={tasks_total}
            lengthMinutes={total_length_minutes}
          />
        </div>
        <EducationCardButtons
          link={link}
          is_available={is_available}
          is_done={is_done}
          tasks_done={tasks_done}
          number={number}
          progress={progress}
        />
      </div>
    </div>
  );
};
