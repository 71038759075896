import getSymbolFromCurrency from 'currency-symbol-map';

export const applyCurrency = (params: {
  amount: number | string;
  currency: string;
  maximumFractionDigits?: number;
}) => {
  const { amount, currency, maximumFractionDigits = 2 } = params;
  try {
    const symbol = currency === 'USDT' ? '$' : getSymbolFromCurrency(currency);
    return `${symbol}${Number.parseFloat(Number(amount).toFixed(maximumFractionDigits))}`;
  } catch (error) {
    return `${currency}${amount}`;
  }
};
