import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { tourneyFilterStorage } from '@/__old/helpers';
import { IFilterParams, IPermissionsParams, PaletteColorName, PaletteColorType, PaletteName } from '@/__old/types';
import { ITableProviderProps, useTable } from '@funfarm/kit';

export interface IProviderProps extends Omit<ITableProviderProps, 'tableRef'> {
  filterParams?: IFilterParams;
  setFilterParams?: (filterParams: IFilterParams) => void;
  palette: PaletteName;
  paletteLegendIsOpen: boolean;
  handlePaletteLegendIsOpen?: () => void;
  setPalette?: Dispatch<SetStateAction<PaletteName>>;
  createPermissionIsOpen: boolean;
  openCreatePermission?: () => void;
  closeCreatePermission?: () => void;
  playedTournamentIsOpen: boolean;
  openPlayedTournament?: () => void;
  closePlayedTournament?: () => void;
  currentPlayedTournament: IPermissionsParams | null;
  handleCurrentPlayedTournament?: (value: IPermissionsParams) => void;
}

export const defaultFilterParams: IFilterParams = {
  date_start: new Date().getTime(),
  date_end: new Date().getTime() + 43200 * 1000,
  bi_start: '',
  rooms_id: [],
  tourney_type: [],
  show_hidden: false,
  show_passed: false,
  is_series: '',
};

export const TOURNAMENT_PALETTES: PaletteName[] = ['1', '2', '3', '4'];

export const paletteColor: Record<PaletteColorType, PaletteColorName> = {
  top: 'dark_green',
  high: 'light_green',
  mid: 'yellow',
  low: 'red',
};

const defaultContextValue: IProviderProps = {
  filterParams: defaultFilterParams,
  palette: '1',
  paletteLegendIsOpen: true,
  createPermissionIsOpen: false,
  playedTournamentIsOpen: false,
  currentPlayedTournament: null,
};

export interface ITourContextValue extends Omit<IProviderProps, 'children'> {
  tableColumns: any;
  setTableColumns: Dispatch<SetStateAction<any>>;
  filterParams: IFilterParams;
  setFilterParams: (filterParams: IFilterParams) => void;
  paletteLegendIsOpen: boolean;
  handlePaletteLegendIsOpen: () => void;
  palette: PaletteName;
  setPalette: Dispatch<SetStateAction<PaletteName>>;
  createPermissionIsOpen: boolean;
  openCreatePermission: () => void;
  closeCreatePermission: () => void;
  playedTournamentIsOpen: boolean;
  openPlayedTournament: () => void;
  closePlayedTournament: () => void;
  currentPlayedTournament: IPermissionsParams | null;
  handleCurrentPlayedTournament: (value: IPermissionsParams) => void;
}

const TourContext = createContext<ITourContextValue>({} as ITourContextValue);

const TourProvider = ({ children }: { children: React.ReactNode }) => {
  const [paletteLegendIsOpen, setPaletteLegendIsOpen] = useState(defaultContextValue.paletteLegendIsOpen);
  const [palette, setPalette] = useState<PaletteName>(defaultContextValue.palette);
  const [createPermissionIsOpen, setCreatePermissionIsOpen] = useState(defaultContextValue.createPermissionIsOpen);
  const [playedTournamentIsOpen, setPlayedTournamentIsOpen] = useState(defaultContextValue.playedTournamentIsOpen);
  const [currentPlayedTournament, setCurrentPlayedTournament] = useState(
    defaultContextValue.currentPlayedTournament,
  );
  const { tableColumns, setTableColumns } = useTable();
  const [filterParams, setFilterParamsState] = useState<IFilterParams>(defaultFilterParams);

  const handlePaletteLegendIsOpen = () => {
    setPaletteLegendIsOpen((prev) => !prev);
  };

  const openCreatePermission = () => {
    setCreatePermissionIsOpen(true);
  };
  const closeCreatePermission = () => {
    setCreatePermissionIsOpen(false);
  };

  const openPlayedTournament = () => {
    setPlayedTournamentIsOpen(true);
  };
  const closePlayedTournament = () => {
    setPlayedTournamentIsOpen(false);
  };

  const handleCurrentPlayedTournament = (value: IPermissionsParams) => {
    setCurrentPlayedTournament(value);
  };

  const setFilterParams = (filterParams: IFilterParams) => {
    if (filterParams) {
      const storageParams = { ...filterParams };
      delete storageParams.date_start;
      delete storageParams.date_end;

      tourneyFilterStorage.setItem(JSON.stringify(storageParams));
    }

    setFilterParamsState(filterParams);
  };

  useEffect(() => {
    let isMounted = true;

    const stFilterParams = tourneyFilterStorage.getItem();

    if (isMounted && stFilterParams) {
      setFilterParams({
        ...JSON.parse(stFilterParams),
        date_start: filterParams.date_start,
        date_end: filterParams.date_end,
      });
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TourContext.Provider
      value={{
        tableColumns,
        setTableColumns,
        filterParams,
        setFilterParams,
        paletteLegendIsOpen,
        handlePaletteLegendIsOpen,
        palette,
        setPalette,
        createPermissionIsOpen,
        openCreatePermission,
        closeCreatePermission,
        playedTournamentIsOpen,
        openPlayedTournament,
        closePlayedTournament,
        currentPlayedTournament,
        handleCurrentPlayedTournament,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

export { TourProvider, TourContext };
