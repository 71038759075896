import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { getWithdrawalTypes } from '@/__old/actions/finances';
import { OperationTypesResponse } from '@/__old/types/finances';
import { Error, ISelectProps, Select, Skeleton } from '@funfarm/kit';
import { useQuery } from '@tanstack/react-query';

type OperationTypeSelectProps = Partial<ISelectProps> & {
  control: any;
};

const OperationTypeSelect: FC<OperationTypeSelectProps> = ({ control }) => {
  const { data, isLoading, isError } = useQuery<OperationTypesResponse>({
    queryKey: ['withdrawal', 'types'],
    queryFn: () => getWithdrawalTypes(),
  });

  return isLoading ? (
    <Skeleton />
  ) : isError || !data?.types ? (
    <Error message="Ошибка получения данных" />
  ) : (
    <Controller
      control={control}
      name="operationType"
      render={({ field }) => (
        <Select
          {...field}
          label="Тип операции"
          required
          options={data.types.map((type) => ({
            label: type.name,
            value: type.id,
          }))}
        />
      )}
    />
  );
};

export default OperationTypeSelect;
