import React, { FC, useContext, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { putPermissionsRequest } from '@/__old/actions';
import css from '@/__old/components/Tournaments/tournaments.module.scss';
import { Button, Error, Row } from '@funfarm/kit';
import NumberInput from '@funfarm/kit/Input/NumberInput';
import { TourContext } from '../TourProvider';

interface PlayedTournamentProps {
  onClose?: () => void;
}

const PlayedTournament: FC<PlayedTournamentProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { currentPlayedTournament: permission } = useContext(TourContext);
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      bi: '',
      reentry: '',
      ko: '',
      prize: '',
      profit: '',
    },
  });

  const [profit, setProfit] = useState<string>('');
  const bi = useWatch({ control, name: 'bi' });
  const reentry = useWatch({ control, name: 'reentry' });
  const ko = useWatch({ control, name: 'ko' });
  const prize = useWatch({ control, name: 'prize' });

  useEffect(() => {
    const calculateProfit = () => {
      const biValue = parseFloat(bi);
      const reentryValue = parseFloat(reentry);
      const koValue = parseFloat(ko);
      const prizeValue = parseFloat(prize);
      if (
        !isNaN(biValue) &&
        !isNaN(reentryValue) &&
        !isNaN(koValue) &&
        !isNaN(prizeValue)
      ) {
        const calculatedProfit = koValue + prizeValue - biValue * reentryValue;
        setProfit(calculatedProfit.toFixed(2));
      } else {
        setProfit('');
      }
    };
    calculateProfit();
  }, [bi, reentry, ko, prize]);

  const onSubmit = async () => {
    if (!permission?.id) return;
    const formData = {
      parameter: 'write_play',
      reentry: parseFloat(reentry) || null,
      ko: parseFloat(ko) || null,
      prize: parseFloat(prize) || null,
      profit: parseFloat(profit) || null,
      room_id: permission.roomid,
    };
    try {
      await putPermissionsRequest(permission.id, formData);
      reset();
      if (onClose) onClose();
    } catch (error) {
      console.error('Ошибка при отправке данных:', error);
    }
  };

  return (
    <form
      className={css.playedTournament_form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <legend className={css.playedTournament_form_legend}>
        Турнир: <span>{permission?.name}</span> - от себя
      </legend>
      <Row className={css.playedTournament_form_fields}>
        <Controller
          control={control}
          name="bi"
          rules={{ required: t('This field is required') }}
          render={({ field, fieldState: { error } }) => (
            <div className={css.playedTournament_form_bi}>
              <NumberInput
                {...field}
                label={t('Buy-in')}
                value={field.value || ''}
                onChange={(value) => field.onChange(value)}
              />
              {error && <Error message={error.message} view="inline" />}
            </div>
          )}
        />
        <Controller
          control={control}
          name="reentry"
          rules={{ required: t('This field is required') }}
          render={({ field, fieldState: { error } }) => (
            <div className={css.playedTournament_form_reentry}>
              <NumberInput
                {...field}
                label={t('Reentry')}
                value={field.value || ''}
                onChange={(value) => field.onChange(value)}
              />
              {error && <Error message={error.message} view="inline" />}
            </div>
          )}
        />
        <Controller
          control={control}
          name="ko"
          rules={{ required: t('This field is required') }}
          render={({ field, fieldState: { error } }) => (
            <div>
              <NumberInput
                {...field}
                label={t('KO')}
                value={field.value || ''}
                onChange={(value) => field.onChange(value)}
              />
              {error && <Error message={error.message} view="inline" />}
            </div>
          )}
        />
        <Controller
          control={control}
          name="prize"
          rules={{ required: t('This field is required') }}
          render={({ field, fieldState: { error } }) => (
            <div>
              <NumberInput
                {...field}
                label={t('Prize')}
                value={field.value || ''}
                onChange={(value) => field.onChange(value)}
              />
              {error && <Error message={error.message} view="inline" />}
            </div>
          )}
        />
        <div className={css.playedTournament_form_profit}>
          <label>{t('Profit')}</label>
          <div>{profit || '0.00'}</div>
        </div>
      </Row>
      <div className={css.playedTournament_form_desc}>
        В Входах/Выходах транзакция запишется автоматически
      </div>
      <Row className={css.formButtons}>
        <Button color="default" label="Отмена" onClick={onClose} />
        <Button
          color="secondary"
          type="submit"
          label="Внести"
          disabled={isSubmitting}
        />
      </Row>
    </form>
  );
};

export default PlayedTournament;
