import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { money } from '@funfarm/lk/src/__old/helpers/numbers';
import { Paper } from '../Paper';
import { Skeleton } from '../Skeleton';
import css from './metrics.module.scss';

export type MetricsProps = {
  caption: string;
  value?: number | string;
  percentage?: boolean;
  currency?: string;
  isLoading?: boolean;
  className?: string;
  button?: ReactElement;
};

const Metrics: FC<MetricsProps> = (props) => {
  const { className, button, ...others } = props;
  return (
    <Paper
      header={renderValue(others)}
      className={classNames(css.metrics, className)}
      helper={button}
    >
      <p>{props.caption}</p>
    </Paper>
  );
};

const renderValue = (props: MetricsProps) => {
  if (props.isLoading) return <Skeleton />;
  if (props.value === undefined || props.value === '-') return '-';
  if (props.percentage) return props.value + '%';
  if (props.currency && props.value) return money(props.value, props.currency);
  return String(props.value);
};

export default Metrics;
