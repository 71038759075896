import ReactDOM from 'react-dom/client';
import { App } from './app/app';
import { initSentry } from './app/sentry';
import { initSuperTokens } from './app/super-tokens';
import './index.scss';

initSentry();
initSuperTokens();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// TODO: add strict mode

root.render(<App />);
