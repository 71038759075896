import React, { ChangeEvent, useContext } from 'react';

import { IFilteredBy } from '@funfarm/kit/types';
import {
  ActionFilter,
  Autocomplete,
  Checkbox,
  IFilterProps,
  Select,
  TableContext,
} from '@funfarm/kit';

import css from './filter.module.scss';

interface IProps extends IFilterProps {
  filteredBy?: IFilteredBy;
  filterBy?: (fieldName: string, filterValues?: IFilteredBy) => void;
  type?: 'radio' | 'select' | 'autocomplete';
}

export const ByValues = (props: IProps) => {
  const { type = 'radio', ...rest } = props;
  const {
    fieldName,
    options,
    filteredBy: externalFilteredBy,
    filterBy: externalFilterBy,
  } = props;
  let { filteredBy, filterBy } = useContext(TableContext);

  filterBy = externalFilterBy ?? filterBy;
  filteredBy = externalFilteredBy ?? filteredBy;

  const clearAll = () => {
    filterBy && filterBy(fieldName);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    filterBy && filterBy(fieldName, { [fieldName]: event.target.value });
  };

  if (type === 'select')
    return (
      <ActionFilter {...rest}>
        <Select onChange={onChange} options={options} />
      </ActionFilter>
    );

  if (type === 'autocomplete')
    return (
      <ActionFilter {...rest}>
        <Autocomplete onChange={onChange} options={options} />
      </ActionFilter>
    );

  return (
    <ActionFilter {...rest}>
      <div>
        <Checkbox
          type="radio"
          value={''}
          checked={
            !filteredBy ||
            !filteredBy[fieldName] ||
            !Object.keys(filteredBy).filter(
              (filter) => filteredBy && !!filteredBy[filter],
            ).length
          }
          label="All"
          onChange={clearAll}
          labelPosition="right"
          className={css.checkbox}
        />
      </div>
      {options.map((option) => {
        return (
          <Checkbox
            type="radio"
            key={option.value}
            value={option.value}
            checked={
              filteredBy && filteredBy[fieldName] === option.value.toString()
            }
            label={option.label}
            labelPosition="right"
            className={css.checkbox}
            onChange={onChange}
          />
        );
      })}
    </ActionFilter>
  );
};
