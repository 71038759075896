import { styled } from 'styled-components';

type StyledProps = {
  $isVisible: boolean;
  $width: string;
};

export const StyledContainer = styled.div<StyledProps>(({ theme, $isVisible, $width }) => ({
  position: 'fixed',
  width: $width,
  left: '50%',
  bottom: theme.spacing[20],
  borderRadius: theme.radius.lg,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  transition: `all 0.2s ${theme.easing['ease-out-back']}`,
  transform: $isVisible ? 'translate(-50%, 0)' : 'translate(-50%, 100%)',
  opacity: $isVisible ? '1' : '0',
  visibility: $isVisible ? 'visible' : 'hidden',
  '& > button': {
    flex: '1',
    borderRadius: 0,
    whiteSpace: 'nowrap',
  },
  '& > button:nth-child(2)::before': {
    content: '""',

    position: 'absolute',
    top: '50%',
    left: -1,
    transform: 'translateY(-50%)',

    width: 2,
    height: theme.spacing[6],
    background: theme.color['gray-50'],
  },
}));
