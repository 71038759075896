import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { useNavigate, useParams } from 'react-router-dom';
import { clsx } from 'clsx';
import type { IUser } from '@/__old/types';
import { useCompleteEducationStep, useGetEducationStep } from '@/features/courses/api';
import { Breadcrumbs } from '@/features/courses/components';
import type { StepPageParams } from '@/features/courses/types';
import { ROUTES } from '@/routes';
import { AlertCard, Button, Fade, Icon, Skeleton } from '@/shared/components';
import { IconSpinner, useGlobalState } from '@funfarm/kit';
import { StepHeader } from './StepHeader/StepHeader';
import { StepSuccessModal } from './StepSuccessModal/StepSuccessModal';
import styles from './education-step.module.scss';

export const EducationStep = () => {
  const { t } = useTranslation();

  const [user] = useGlobalState<IUser>('user');
  const [isOpen, setIsOpen] = useState(false);

  const { courseId, blockId, moduleId, stepId } = useParams() as StepPageParams;
  const navigate = useNavigate();

  const { data, isLoading, isError } = useGetEducationStep({ courseId, blockId, moduleId, stepId });
  const { mutate: completeStep, isPending: isCompletionPending } = useCompleteEducationStep(() => setIsOpen(true));

  if (isLoading) return <Skeleton $h="calc(100vh - 12rem)" />;
  if (isError) return <AlertCard message={t('fetchingError')} />;

  const { module, step } = data!;

  const backLink = ROUTES.stepsByModuleId({ courseId, blockId, moduleId });
  const prevStep = module.steps.find((el) => el.number === step.number - 1);
  const nextStep = module.steps.find((el) => el.number === step.number + 1);

  const goToStep = (stepId: string) => {
    navigate(ROUTES.stepById({ courseId, blockId, moduleId, stepId: stepId }));
  };

  const handleCompleteStep = () => {
    completeStep({ courseId, blockId, moduleId, stepId });
  };

  const handleNextLesson = () => {
    if (nextStep) goToStep(nextStep.id);
    else navigate(ROUTES.modulesByBlockId({ courseId, blockId }));
  };

  const copyNickname = async () => {
    if (!user?.username) return;
    try {
      await navigator.clipboard.writeText(user?.username);
      console.log('Ник успешно скопирован в буфер обмена');
    } catch (err) {
      console.error('Ошибка:', err);
    }
  };

  const embedIframe = (
    <iframe
      src={step.materials_link}
      className={clsx(step.embed_type && styles[step.embed_type])}
      allow="fullscreen;"
    />
  );

  return (
    <>
      <Breadcrumbs
        links={[
          { href: ROUTES.courses, title: t('education.courses') },
          { href: ROUTES.blocksByCourseId(courseId), title: module.course_title },
          { href: ROUTES.modulesByBlockId({ courseId, blockId }), title: module.block_title },
          { href: backLink, title: module.title },
          { title: step.title },
        ]}
      />
      <StepHeader link={backLink} steps={module.steps} currentStepId={step.id} goToStep={goToStep} />
      <div className={styles.container}>
        <Fade $in>
          <div className={styles.taskParents}>
            {`${t('education.chapter')} ${module.number}. ${t('education.task')} ${step.number}`}
          </div>
          <h1 className={styles.title}>{step.title}</h1>
          <div className={styles.message}>
            <Markdown>
              {user?.username ? step.message.replace('@username', user?.username) : step.message}
            </Markdown>
            {step.embed_type === 'form' && !!user?.username && (
              <div className={styles.copyBox}>
                <div>{t('education.clickToCopyYourNickname')}:</div>
                <div className={styles.copy} onClick={copyNickname}>
                  <div>{user?.username}</div>
                  <Icon name="copy" />
                </div>
              </div>
            )}
          </div>
          {step.embed_type && step.embed_type !== 'video' && step.embed_type !== 'image' && (
            <div className={styles.embedBox}>
              {embedIframe}
              {step.embed_type === 'pdf' && !!step.materials_link_download && (
                <div className={styles.downloadBox}>
                  <a href={step.materials_link_download}>
                    <Button $variant="filled" $fullWidth>
                      {t('education.downloadPdf')}
                    </Button>
                  </a>
                </div>
              )}
            </div>
          )}
          {step.embed_type === 'video' && embedIframe}
          {step.embed_type === 'image' && !!step.materials_link_download && (
            <img src={step.materials_link_download} alt="step image" className={styles.image} />
          )}
          <div className={clsx(styles.buttonsBox, !!step.embed_type && styles.withMarginTop)}>
            {!!prevStep && (
              <Button $variant="outline" onClick={() => goToStep(prevStep.id)}>
                {t('education.prevLesson')}
              </Button>
            )}
            {step.completed_at ? (
              <Button onClick={handleNextLesson}>
                {nextStep ? t('education.nextLesson') : t('education.completeChapter')}
              </Button>
            ) : (
              <Button onClick={handleCompleteStep}>
                {isCompletionPending ? (
                  <IconSpinner className={clsx('spin', styles.chevron)} />
                ) : (
                  t('education.completeLesson')
                )}
              </Button>
            )}
          </div>
        </Fade>
      </div>
      <StepSuccessModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        length_minutes={step.length_minutes}
        nextStep={nextStep}
        handleNextLesson={handleNextLesson}
      />
    </>
  );
};
