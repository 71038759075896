import { memo, useCallback, useContext, useMemo } from 'react';
import {
  type ColumnState,
  type IsFullWidthRowParams,
  type ColumnMovedEvent,
  type AlignedGrid,
  type ColumnResizedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { TourContext } from '@/__old/components/Tournaments/TourProvider';
import { useGetWillPlayTournaments } from '@/features/tournaments/api';
import { AlertCard } from '@/shared/components';
import { useSynchronizedRef } from '@/shared/hooks';
import { TableContext } from '@funfarm/kit';
import { useTableColumns } from '../../../table-columns';
import { useTableTheme } from '../../tournaments-table-theme';
import { getPaletteClass, getRowId, useTournamentColumns } from '../../tournaments.resoures';
import { WillPlaySummary } from './will-play-summary';
import { WillPlayTotal } from './will-play-total';
import styles from '../../tournaments.module.scss';

const isFullWidthRow = (params: IsFullWidthRowParams) => params.rowNode.data.id === 'total';

const NoRows = memo(() => <div style={{ display: 'none' }} />);

interface Props {
  gridRef: React.Ref<AgGridReact>;
  alignedGrids: AlignedGrid[];
}

export const SelectedTournamentsTable = ({ gridRef, alignedGrids }: Props) => {
  const { orderedBy, orderBy } = useContext(TableContext);
  const { filterParams } = useContext(TourContext);

  const { data, isLoading, isError, isFetching } = useGetWillPlayTournaments({
    isSelectedTournamets: true,
    filters: filterParams,
    orderedBy,
  });

  /** add total as first row of table */
  const tourneys = useMemo(
    () =>
      data?.tourneys?.length
        ? [{ id: 'total', total: data.total, tournaments: data.tourneys }, ...data.tourneys]
        : [],
    [data],
  );

  const columnDefs = useTournamentColumns({ isSelectedTournaments: true });

  const { configurableColumns, reorderColumns, resizeColumn } = useTableColumns();

  const columnDefsRef = useSynchronizedRef(columnDefs);
  const configurableColumnsRef = useSynchronizedRef(configurableColumns);

  const handleReorderColumn = useCallback(
    (event: ColumnMovedEvent) => {
      // https://github.com/ag-grid/ag-grid/issues/8386

      const { column: tableColumn, toIndex, finished, source } = event;
      if (!finished || !tableColumn || source !== 'uiColumnMoved') return;

      /** here we have the logic with two sortable column arrays (global configurable columns and table columns) */
      const movableColumnName = tableColumn.getColId();

      /** the over item index from table columns */
      const tableOverColumnIndex = toIndex!;
      /** the over item from table columns */
      const tableOverColumn = columnDefsRef.current[tableOverColumnIndex];

      /** the movable item index from configurable columns */
      const configurableColumnIndex = configurableColumnsRef.current.findIndex(
        (item) => item.name === movableColumnName,
      );

      /** the over item index from configurable columns */
      const configurableOverColumnIndex =
        configurableColumnsRef.current.findIndex((item) => item.name === tableOverColumn?.field) ?? 0;

      reorderColumns(configurableColumnIndex, configurableOverColumnIndex);
    },
    [columnDefsRef, configurableColumnsRef, reorderColumns],
  );

  const handleSortChanged = useCallback(
    (params: any) => {
      const sortedColumn = params.api.getColumnState().find((col: ColumnState) => col.sort);
      if (!sortedColumn) return;
      const { colId: field, sort: direction } = sortedColumn;
      orderBy!(field, direction);
    },
    [orderBy],
  );

  const handleColumnResized = useCallback(
    (event: ColumnResizedEvent) => {
      const { column, finished, source } = event;
      if (!finished || !column || source !== 'uiColumnResized') return;
      const width = column.getActualWidth();
      if (width) resizeColumn(column.getColId(), width);
    },
    [resizeColumn],
  );

  const tableTheme = useTableTheme();

  if (isError) return <AlertCard message="Ошибка при загрузке выбранных турниров" />;

  return (
    <>
      <div className={!isLoading && !tourneys.length ? styles.noContent : undefined}>
        <AgGridReact
          ref={gridRef}
          alignedGrids={alignedGrids} // TODO: fix freezes with table columns reordering with aligned grids prop
          rowData={tourneys}
          getRowClass={getPaletteClass}
          getRowId={getRowId}
          columnDefs={columnDefs}
          /** actions */
          onSortChanged={handleSortChanged}
          onColumnMoved={handleReorderColumn}
          onColumnResized={handleColumnResized}
          /** behaviour */
          suppressDragLeaveHidesColumns
          suppressMoveWhenColumnDragging // required to avoid issue with non finished reordering event
          suppressRowHoverHighlight
          suppressMultiSort
          suppressCellFocus
          /** fullwidth row */
          isFullWidthRow={isLoading ? undefined : isFullWidthRow}
          fullWidthCellRenderer={isLoading ? undefined : WillPlayTotal}
          embedFullWidthRows
          /** appearance */
          className="will-play-table"
          loading={isFetching}
          theme={tableTheme}
          domLayout="autoHeight"
          headerHeight={isLoading ? 0 : undefined}
          noRowsOverlayComponent={NoRows}
        />
      </div>

      {!!data?.total && <WillPlaySummary summary={data.summary} />}
    </>
  );
};
