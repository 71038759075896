import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconHelpCircle = (props: IconProps) => {
  return (
    <Icon id="IconHelpCircle" {...props}>
      <path d="M8 9.3125C8 7.10337 9.79086 5.3125 12 5.3125C14.2091 5.3125 16 7.10337 16 9.3125C16 11.1763 14.7252 12.7424 13 13.1865V14.3125C13 14.8648 12.5523 15.3125 12 15.3125C11.4477 15.3125 11 14.8648 11 14.3125V12.3125C11 11.7602 11.4477 11.3125 12 11.3125C13.1046 11.3125 14 10.4171 14 9.3125C14 8.20794 13.1046 7.3125 12 7.3125C10.8954 7.3125 10 8.20794 10 9.3125C10 9.86479 9.55229 10.3125 9 10.3125C8.44772 10.3125 8 9.86479 8 9.3125Z" />
      <path d="M13.25 17.5625C13.25 18.2528 12.6903 18.8125 12 18.8125C11.3097 18.8125 10.75 18.2528 10.75 17.5625C10.75 16.8722 11.3097 16.3125 12 16.3125C12.6903 16.3125 13.25 16.8722 13.25 17.5625Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.22183 4.22182C6.21136 2.23232 8.96273 1 12 1C15.0373 1 17.7886 2.23231 19.7782 4.22182C21.7677 6.21135 23 8.96273 23 12C23 15.0373 21.7677 17.7886 19.7782 19.7782C17.7886 21.7677 15.0373 23 12 23C8.96273 23 6.21136 21.7677 4.22183 19.7782C2.23232 17.7886 1 15.0373 1 12C1 8.96273 2.23233 6.21135 4.22183 4.22182ZM12 3C9.51447 3 7.26584 4.00626 5.63603 5.63604C4.00625 7.26585 3 9.51447 3 12C3 14.4855 4.00626 16.7342 5.63604 18.3639C7.26584 19.9937 9.51447 21 12 21C14.4855 21 16.7342 19.9937 18.3639 18.3639C19.9937 16.7342 21 14.4855 21 12C21 9.51447 19.9937 7.26584 18.3639 5.63604C16.7342 4.00626 14.4855 3 12 3Z"
      />
    </Icon>
  );
};

export default IconHelpCircle;
