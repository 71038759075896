import React, { useRef } from 'react';

import { IconCopy } from '../Icon';
import { Toast } from '../Toast';

import css from './CopyToClipboard.module.scss';

interface CopyToClipboardProps {
  children: React.ReactNode;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (containerRef.current) {
      const textToCopy = containerRef.current.textContent;

      if (textToCopy) {
        const range = document.createRange();
        range.selectNodeContents(containerRef.current);

        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);

          document.execCommand('copy');

          Toast.success({ message: 'Copied!' });
        }
      }
    }
  };

  return (
    <div
      ref={containerRef}
      onClick={handleClick}
      className={css.children}
      style={{ userSelect: 'text' }} // Разрешаем выделение текста
    >
      {children}
      <IconCopy />
    </div>
  );
};

export default CopyToClipboard;
