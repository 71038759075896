import type { EducationBlock } from '@/features/courses/models';
import { ApiRequest } from '@/shared/api';
import { useQuery } from '@tanstack/react-query';

export const useGetEducationBlocks = (courseId: string | undefined) =>
  useQuery({
    queryKey: ['blocks', courseId],
    queryFn: () =>
      new ApiRequest<EducationBlock[]>({
        method: 'GET',
        url: `/api/gamification/way/courses/${courseId}/blocks`,
      }),
  });
