import { Interpolation, keyframes, styled } from 'styled-components';
import { generateResponsiveStyles } from '@/theme/resources';
import { BoxStyledProps } from '../box';

const animation = keyframes`
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
`;

export const Skeleton = styled.div<BoxStyledProps>`
  ${({ theme, ...rest }) => {
    const responsiveStyles = generateResponsiveStyles(theme, rest);
    return {
      backgroundColor: theme.color['canvas'],
      position: 'relative',
      overflow: 'hidden',

      ...responsiveStyles,
    } as Interpolation<BoxStyledProps>;
  }}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.02), transparent);
    animation: ${animation} 1.5s infinite;
  }
`;

Skeleton.defaultProps = {
  $borderRadius: 'md',
};
