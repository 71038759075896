import { Heading } from '../heading';
import { Paper } from '../paper';
import { Text } from '../text';

// TODO: add status

interface Props extends React.ComponentProps<typeof Paper> {
  title?: string;
  message: string;
}

export const AlertCard = (props: Props) => {
  const { title, message, ...paperProps } = props;

  // const { t } = useTranslation();

  return (
    <Paper $bgColor="#332227" {...paperProps}>
      <Heading>{title || 'Что-то пошло не так'}</Heading>
      <Text $mt={6} $mb={0}>
        {message}
      </Text>
    </Paper>
  );
};
