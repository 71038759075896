import {
  type ChangeEvent,
  type ChangeEventHandler,
  type ForwardedRef,
  forwardRef,
  type InputHTMLAttributes,
  type ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import { Label } from '@/shared/components';
import { IconPreviewClose, IconPreviewOpen } from '@funfarm/kit';
import styles from './input.module.scss';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  value?: string | number;
  label?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  onInput?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  error?: string;
  displayError?: boolean;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  dataTestId?: string;
  size?: number;
}

export const Input = forwardRef((props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    type = 'text',
    name,
    label,
    error,
    required,
    displayError,
    placeholder,
    onChange,
    onFocus,
    onBlur,
    onInput,
    className,
    dataTestId,
    leftIcon,
    rightIcon,
    value: _v,
    ...rest
  } = props;
  let rightIconDisplay = rightIcon;
  // value has to be a string
  const value = props.value ? props.value.toString() : '';

  const [currentValue, setValue] = useState<string>(value);
  const [showPass, setShowPass] = useState<boolean>(false);

  if (type === 'password') {
    rightIconDisplay = showPass ? (
      <IconPreviewOpen onClick={() => setShowPass((s) => !s)} />
    ) : (
      <IconPreviewClose onClick={() => setShowPass((s) => !s)} />
    );
  }

  useEffect(() => {
    if (type === 'date' && value !== '') {
      const date = new Date(value);

      setValue(
        date.getFullYear() +
          '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          date.getDate().toString().padStart(2, '0'),
      );
    } else setValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, type]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (type === 'date' && event.target.value !== '') {
        const date = new Date(event.target.value);

        setValue(
          date.getFullYear() +
            '-' +
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '-' +
            date.getDate().toString().padStart(2, '0'),
        );
      } else {
        setValue(event.target.value);
      }

      if (onChange) onChange(event);
    },
    [onChange, type],
  );

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (type === 'number') {
      event.target.value = event.target.value.replace(/\D/g, '');
    }
    onInput?.(event);
  };

  return (
    <div className={classNames(styles.root, styles[type], className)}>
      <Label label={label} htmlFor={name} required={required} isError={!!error} />
      <div className={styles.wrapper}>
        <div className={styles.leftIcon}>{leftIcon}</div>
        <input
          type={showPass ? 'text' : type}
          id={name}
          name={name}
          placeholder={placeholder}
          value={currentValue}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onInput={handleInput}
          data-testid={dataTestId}
          className={classNames(
            styles.input,
            leftIcon && styles.withLeftIcon,
            rightIcon && styles.withRightIcon,
            !!error && styles.errorInput,
          )}
          ref={ref}
          {...rest}
        />
        <div className={styles.rightIcon}>{rightIconDisplay}</div>
      </div>
      {displayError && !!error && <p className={styles.error}>{error}</p>}
    </div>
  );
});
