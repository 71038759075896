import React, {
  MouseEventHandler,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';

import css from './backdrop.module.scss';

interface Props {
  open?: boolean;
  onClick?: MouseEventHandler;
  children?: ReactNode;
  className?: string;
  animation?: boolean;
}

export const Backdrop = (props: Props) => {
  const { open, onClick, className, animation, ...rest } = props;

  const [animationOpen, setAnimationOpen] = useState<boolean>(true);
  const [animationClose, setAnimationClose] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setAnimationOpen(false);
      setAnimationClose(true);
    } else {
      setAnimationOpen(true);

      setTimeout(() => {
        setAnimationClose(false);
      }, 300);
    }
  }, [open]);

  return (
    <>
      {((animation && (open || animationClose)) || !animation) && (
        <div
          className={classNames(
            css.backdrop,
            className,
            ((open && !animation) || (!animationOpen && animationClose)) &&
              css.open,
          )}
          onClick={onClick}
          {...rest}
        >
          {props.children}
        </div>
      )}
    </>
  );
};
