import React, { useEffect } from 'react';
import { generateDashboardURL } from '@/__old/actions/trainings';
import css from './training_dashboard.module.scss';

export const TrainerDashboard = () => {
  const [url, setUrl] = React.useState<string | undefined>(undefined);
  useEffect(() => {
    generateDashboardURL(1288, ['trainer', 'groups_for_visual'])
      .then((response) => {
        setUrl(response.url);
      })
      .catch(() => {
        alert('Failed to generate dashboard URL');
      });
  }, []);

  return (
    <iframe
      src={url}
      title="Dashboard"
      width="100%"
      height="100%"
      className={css.iframe}
    />
  );
};
