import React, { useMemo, useState } from 'react';
import { AllCommunityModule, ColDef, ModuleRegistry } from 'ag-grid-community';
import { RowClassParams } from 'ag-grid-community/dist/types/src/entities/gridOptions';
import { AgGridReact } from 'ag-grid-react';
import usePagination from '@funfarm/lk/src/__old/hooks/usePagination';
import {
  ApiFetchFunction,
  FilterComponent,
  FilterPrototype,
  TableItemPrototype,
} from '@funfarm/lk/src/__old/types/table';
import { useQuery } from '@tanstack/react-query';
import Pagination from '../Pagination/Pagination';
import columnTypes from './dataGrid.columnTypes';
import { darkTheme } from './dataGrid.themes';

/**
 * Interface for DataGrid props:
 *      ItemType - type of object to be rendered in a row
 *      FilterType - type of filter values object
 */
type DataGridProps<ItemType extends TableItemPrototype, FilterType extends FilterPrototype> = {
  fetchData: ApiFetchFunction<ItemType[], FilterType>;
  fetchKey: string;
  columns: ColDef<ItemType>[];
  getRowClass?: (params: RowClassParams<ItemType>) => string;
  pagination?: boolean;
  filterComponent?: FilterComponent<FilterType>;
};

const DataGrid = <ItemType extends TableItemPrototype, FilterType extends FilterPrototype>(
  props: DataGridProps<ItemType, FilterType>,
) => {
  /********  PROPS & STATE  */
  const { fetchData, fetchKey, columns, getRowClass, pagination = true, filterComponent } = props;
  const [filter, setFilter] = useState<FilterType>();
  const { page, setPage, pageSize } = usePagination();

  /********  DATA  */
  const { data, isLoading } = useQuery({
    queryKey: [fetchKey, filter, page, pageSize],
    queryFn: () => fetchData(filter, (page - 1) * pageSize, pageSize + 1),
  });

  // Trim extra item before passing to grid
  const trimmedData = useMemo(() => data?.slice(0, pageSize), [data, pageSize]);

  /********  RENDERING  */
  const FilterElement = filterComponent as FilterComponent<FilterType>;
  return (
    <>
      {FilterElement && <FilterElement values={filter} setValues={setFilter} />}
      <AgGridReact
        // data
        loading={isLoading}
        rowData={trimmedData}
        // columns
        columnDefs={columns}
        columnTypes={columnTypes}
        // behaviour
        suppressDragLeaveHidesColumns
        autoSizeStrategy={{
          type: 'fitCellContents',
        }}
        // appearance
        theme={darkTheme}
        getRowClass={getRowClass}
        suppressRowHoverHighlight={true}
        domLayout="autoHeight"
      />

      {pagination && (
        <Pagination
          page={page}
          setPage={setPage}
          loading={isLoading}
          hasNextPage={!isLoading && data?.length ? data?.length > pageSize : false}
        />
      )}
    </>
  );
};

// Register all Community features
ModuleRegistry.registerModules([AllCommunityModule]);

export default DataGrid;
