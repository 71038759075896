import React, { FC, useState } from 'react';
import { addBalance } from '@/__old/actions/finances';
import useBalancesAndBrm from '@/__old/hooks/useBalancesAndBrm';
import { BalanceToAddToWayLine } from '@/__old/types/finances';
import { Button, IconPlus, IError, Row, Toast } from '@funfarm/kit';
import { Column } from '@funfarm/kit/Column';

type BalanceToAddToWayProps = {
  item: BalanceToAddToWayLine;
};

const BalanceToAddToWay: FC<BalanceToAddToWayProps> = ({ item }) => {
  const [loading, setLoading] = useState(false);
  const { refetch } = useBalancesAndBrm();

  return (
    <Row spaceBetween align="center">
      <Column>
        <h4>{item.title}</h4>
      </Column>
      <Row spaceBetween align="center" gap={1}>
        <Button
          view="light"
          color="secondary"
          tabIndex={-1}
          onClick={() => {
            setLoading(true);
            addBalance(item.id)
              .then(() => {
                Toast.success({ message: 'Баланс добавлен' });
                refetch();
              })
              .catch((error: IError) => {
                console.error('Error while adding a balance to a way: ', error);
              })
              .finally(() => setLoading(false));
          }}
          icon={<IconPlus />}
          disabled={loading}
        />
      </Row>
    </Row>
  );
};

export default BalanceToAddToWay;
