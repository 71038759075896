/// <reference types="vite/client" />
/// <reference types="vite-plugin-svgr/client" />

declare global {
  type EntityId = string;

  type ISODate = string;
  type Timestamp = number;

  type IconComponentProps = React.SVGProps<SVGSVGElement>;
  type IconComponent = React.ComponentType<IconComponentProps>;

  interface Window {
    env: any;
  }
}

// TODO: fix this

type EnvType = Record<string, string>;

export const env: EnvType = import.meta.env.PROD ? window.env : import.meta.env;
