import React, { FC, PropsWithChildren } from 'react';

import IconExclamation from '../Icon/icons/Exclamation';
import { Row } from '../Row';

import { Paper } from './index';

import css from './paper.module.scss';

const WarningPaper: FC<PropsWithChildren> = ({ children }) => (
  <Paper className={css.warningPaper}>
    <Row gap={1}>
      <IconExclamation size="medium" />
      <span>{children}</span>
    </Row>
  </Paper>
);

export default WarningPaper;
