import React from 'react';
import { FileUploader } from '@/__old/kit/FileUploader';
import { IUser } from '@/__old/types';
import { useGlobalState } from '@funfarm/kit';

export const Profile = () => {
  const [user] = useGlobalState<IUser>('user');

  return (
    <div>
      {user ? (
        <div>
          <p>Username: {user.username}</p>
          <p>First name: {user.first_name}</p>
          <p>Last name: {user.last_name}</p>
          <p>
            Email: {user.email}{' '}
            {user.email_verified_at ? 'Verified' : 'Not verified'}
          </p>
          <p>Telegram: {user.telegram}</p>
        </div>
      ) : (
        'Not auth'
      )}

      <FileUploader />
    </div>
  );
};
