import React, {
  CSSProperties,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import parse from 'html-react-parser';

import {
  IconClose,
  IconError,
  IconInfo,
  IconSuccess,
  IconWarning,
  Portal,
} from '@funfarm/kit';
import { EPositions, ETypes } from '@funfarm/kit/types';

import css from './toast.module.scss';

interface Props {
  open?: boolean;
  type?: keyof typeof ETypes;
  icon?: ReactElement;
  title?: string;
  message?: string | ReactNode;
  position?: keyof typeof EPositions;
  timeout?: number | boolean;
  onClose?: MouseEventHandler<HTMLElement>;
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
}

export const Toast = (props: Props) => {
  let { icon } = props;

  const {
    type = ETypes.info,
    title,
    message,
    position = EPositions.topright,
    open,
    timeout = 3000,
    onClose,
    className,
    style,
    ...rest
  } = props;

  const [animationOpen, setAnimationOpen] = useState<boolean>(true);
  const [animationClose, setAnimationClose] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setAnimationOpen(false);
      setAnimationClose(true);
    } else {
      setAnimationOpen(true);

      setTimeout(() => {
        setAnimationClose(false);
      }, 300);
    }
  }, [open]);

  const handleClose = useCallback(
    (event?: any) => {
      setAnimationOpen(true);

      setTimeout(() => {
        setAnimationClose(false);

        if (onClose) onClose(event);
      }, 300);
    },
    [onClose],
  );

  useEffect(() => {
    if (timeout) setTimeout(() => handleClose(), timeout as number);
  }, [timeout, handleClose]);

  if (!icon) {
    if (type === 'info') icon = <IconInfo />;

    if (type === 'error') icon = <IconError />;

    if (type === 'warning') icon = <IconWarning />;

    if (type === 'success') icon = <IconSuccess />;
  }

  return (
    <>
      {(open || animationClose) && (
        <Portal id="toast" className={classNames(css.wrapper, css[position])}>
          <div
            className={classNames(
              css.toast,
              css[type],
              className,
              !animationOpen && animationClose && css.open,
            )}
            style={style}
            {...rest}
          >
            {icon &&
              React.cloneElement(icon, { size: 'large', className: css.icon })}
            <div className={css.message}>
              {title && <h3 className={css.title}>{title}</h3>}
              <div className={css.body}>{message || props.children}</div>
            </div>
            <IconClose
              onClick={handleClose}
              size="large"
              className={css.close}
            />
          </div>
        </Portal>
      )}
    </>
  );
};

// use with react-tostify library
interface ITostifyOptions {
  title?: string;
  message?: string | ReactNode;
}

Toast.show = (type: keyof typeof ETypes = 'info', options: ITostifyOptions) => {
  const { title, message } = options;
  let icon;

  if (type === 'info') icon = <IconInfo />;

  if (type === 'error') icon = <IconError />;

  if (type === 'warning') icon = <IconWarning />;

  if (type === 'success') icon = <IconSuccess />;

  toast(
    <div className={classNames(css.toastify, css[type])}>
      {icon && React.cloneElement(icon, { size: 'large', className: css.icon })}
      <div className={css.message}>
        {title && <h3 className={css.title}>{title}</h3>}
        <div className={css.body}>{parse(message as string)}</div>
      </div>
    </div>,
    { type },
  );
};

Toast.error = (options: ITostifyOptions) => {
  const { title, message } = options;

  toast(
    <div className={classNames(css.toastify, css.error)}>
      <IconError size="large" className={css.icon} />
      <div className={css.message}>
        {title && <h3 className={css.title}>{title}</h3>}
        <div className={css.body}>{parse(message as string)}</div>
      </div>
    </div>,
    { type: 'error' },
  );
};

Toast.success = (options: ITostifyOptions) => {
  const { title, message } = options;

  toast(
    <div className={classNames(css.toastify, css.success)}>
      <IconSuccess size="large" className={css.icon} />
      <div className={css.message}>
        {title && <h3 className={css.title}>{title}</h3>}
        <div className={css.body}>{parse(message as string)}</div>
      </div>
    </div>,
    { type: 'success' },
  );
};

Toast.warning = (options: ITostifyOptions) => {
  const { title, message } = options;

  toast(
    <div className={classNames(css.toastify, css.warning)}>
      <IconWarning size="large" className={css.icon} />
      <div className={css.message}>
        {title && <h3 className={css.title}>{title}</h3>}
        <div className={css.body}>{parse(message as string)}</div>
      </div>
    </div>,
    { type: 'warning' },
  );
};
