import { Color, Interpolation, Radius, type ResponsiveStyle, styled, StyleFunction } from 'styled-components';
import { generateResponsiveStyles, type MarginStyleProps, type PaddingStyleProps } from '@/theme/resources';

export interface BoxStyledProps extends MarginStyleProps, PaddingStyleProps {
  $display?: ResponsiveStyle<React.CSSProperties['display']>;
  $overflow?: ResponsiveStyle<React.CSSProperties['overflow']>;

  $position?: ResponsiveStyle<React.CSSProperties['position']>;
  $top?: ResponsiveStyle<string | number>;
  $right?: ResponsiveStyle<string | number>;
  $bottom?: ResponsiveStyle<string | number>;
  $left?: ResponsiveStyle<string | number>;

  $gap?: ResponsiveStyle<string | number>;
  $justifyContent?: ResponsiveStyle<React.CSSProperties['justifyContent']>;
  $alignItems?: ResponsiveStyle<React.CSSProperties['alignItems']>;
  $flex?: ResponsiveStyle<React.CSSProperties['flex']>;
  $flexWrap?: ResponsiveStyle<React.CSSProperties['flexWrap']>;

  $w?: ResponsiveStyle<string | number>;
  $minW?: ResponsiveStyle<string | number>;
  $maxW?: ResponsiveStyle<string | number>;
  $h?: ResponsiveStyle<string | number>;
  $minH?: ResponsiveStyle<string | number>;
  $maxH?: ResponsiveStyle<string | number>;

  $bgColor?: ResponsiveStyle<Color | string>;
  $borderRadius?: ResponsiveStyle<Radius>;
  $border?: ResponsiveStyle<React.CSSProperties['border']>;
  $borderTop?: ResponsiveStyle<React.CSSProperties['borderTop']>;
  $borderBottom?: ResponsiveStyle<React.CSSProperties['borderBottom']>;
  $borderLeft?: ResponsiveStyle<React.CSSProperties['borderLeft']>;
  $borderRight?: ResponsiveStyle<React.CSSProperties['borderRight']>;
}

export const boxStyleFunction: StyleFunction<BoxStyledProps> = ({ theme, ...rest }) => {
  const responsiveStyles = generateResponsiveStyles(theme, rest);
  return responsiveStyles as Interpolation<BoxStyledProps>;
};

export const Box = styled.div<BoxStyledProps>(boxStyleFunction);

export const HStack = styled.div<BoxStyledProps>(({ theme, ...rest }) => {
  const responsiveStyles = generateResponsiveStyles(theme, rest);
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing[2],
    ...responsiveStyles,
  } as Interpolation<BoxStyledProps>;
});

export const VStack = styled.div<BoxStyledProps>(({ theme, ...rest }) => {
  const responsiveStyles = generateResponsiveStyles(theme, rest);
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing[2],
    ...responsiveStyles,
  } as Interpolation<BoxStyledProps>;
});
