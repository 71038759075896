import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { getPlayerHistory } from '@/__old/actions';
import css from '@/__old/components/Info/info.module.scss';
import { formatMonth } from '@/__old/helpers/formatDates';
import { IPlayerHistory } from '@/__old/types';
import { formatNumber, IError, Paper, roundDecimal, Table } from '@funfarm/kit';
import { useQuery } from '@tanstack/react-query';

export const ResultTable = () => {
  const { t } = useTranslation();

  const { data } = useQuery<IPlayerHistory, IError>({
    queryKey: ['player', 'history'],
    queryFn: getPlayerHistory,
  });

  return (
    <Paper header={t('Last 12 month results')} className={css.resultTable}>
      <Table cells={5} fontSize="small" view="condensed">
        <Table.Row>
          <Table.Cell>{t('Month')}</Table.Cell>
          <Table.Cell>{t('profit')}</Table.Cell>
          <Table.Cell>{t('ABI')}</Table.Cell>
          <Table.Cell>{t('Dist')}</Table.Cell>
          <Table.Cell>{t('EV')}</Table.Cell>
        </Table.Row>
        {data &&
          data.months.map((row, i) => {
            const rowColor = i % 2 ? 'light' : 'dark';

            return (
              <Table.Row
                className={classNames(css.values, css[rowColor])}
                key={row.month_start_date}
              >
                <Table.Cell>{formatMonth(row.month_start_date)}</Table.Cell>
                <Table.Cell>{formatNumber(Math.round(row.profit))}</Table.Cell>
                <Table.Cell>{formatNumber(roundDecimal(row.abi))}</Table.Cell>
                <Table.Cell>{formatNumber(row.mtt_count)}</Table.Cell>
                <Table.Cell>{formatNumber(roundDecimal(row.ev))}</Table.Cell>
              </Table.Row>
            );
          })}
        <Table.Row>
          <Table.Cell colspan={5}>&nbsp;</Table.Cell>
        </Table.Row>
        <Table.Row className={classNames(css.values, css.summary)}>
          <Table.Cell>{t('per year')}</Table.Cell>
          <Table.Cell>
            {formatNumber(data && data.year.profit?.toFixed())}
          </Table.Cell>
          <Table.Cell>
            {formatNumber(roundDecimal(data && data.year.abi))}
          </Table.Cell>
          <Table.Cell>{roundDecimal(data && data.year.mtt_count)}</Table.Cell>
          <Table.Cell>
            {formatNumber(roundDecimal(data && data.year.ev))}
          </Table.Cell>
        </Table.Row>
      </Table>
    </Paper>
  );
};
