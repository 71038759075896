import { env } from 'env';
import * as Sentry from '@sentry/react';

export const initSentry = () => {
  !env.DEV &&
    Sentry.init({
      dsn: 'https://6885fd833a2c578c7424f72c6d81e79c@o4505747824181248.ingest.us.sentry.io/4508122377879552',
      environment: 'local',
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/lk-web.funfarm\.dev\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
};
