import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { CountInfo } from '@/features/courses/components';
import type { EducationModuleWithSteps } from '@/features/courses/models';
import { Icon } from '@/shared/components';
import styles from './ModuleHeader.module.scss';

type Props = {
  module: EducationModuleWithSteps;
  link: string;
};

export const ModuleHeader = ({ module, link }: Props) => {
  const { title, description, number, image_url, length_minutes, steps, tasks_total } = module;

  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className={styles.header}>
      <img src={image_url} alt={`${title} image`} className={styles.image} />
      <div className={styles.progressBlock}>
        <div className={styles.titleBox}>
          <div onClick={() => navigate(link)} className={styles.backButton}>
            <Icon name="cheveron-left" $color="gray-200" />
          </div>
          <div className={styles.title}>{`${t('education.chapter')} ${number}. ${title}`}</div>
        </div>
        <div className={styles.description}>{description}</div>
        <div className={styles.progressWithCountBlock}>
          <div className={styles.stepsIndicatorsBlock}>
            {steps.map((step) => (
              <div key={step.id} className={classNames(styles.stepIndicator, step.is_done && styles.done)} />
            ))}
          </div>
          <CountInfo className={styles.grey400} tasks_total={tasks_total} lengthMinutes={length_minutes} />
        </div>
      </div>
    </div>
  );
};
