import { type DefaultTheme } from 'styled-components';
import { parseNumberToRem, parsePaletteColor, parseRadiusValue, parseSpacingValue } from '../utils';

/**
 * Shared styled properties.
 * Register your styled properties here to use them in your components
 */

export const sharedStyledPropertyMap: Record<
  string,
  {
    property: keyof React.CSSProperties;
    parser?: (value: string | undefined, theme: DefaultTheme) => string | number | undefined;
  }
> = {
  $m: {
    property: 'margin',
    parser: parseSpacingValue,
  },

  $mt: {
    property: 'marginTop',
    parser: parseSpacingValue,
  },
  $mr: {
    property: 'marginRight',
    parser: parseSpacingValue,
  },
  $mb: {
    property: 'marginBottom',
    parser: parseSpacingValue,
  },
  $ml: {
    property: 'marginLeft',
    parser: parseSpacingValue,
  },
  $mx: {
    property: 'marginInline',
    parser: parseSpacingValue,
  },
  $my: {
    property: 'marginBlock',
    parser: parseSpacingValue,
  },

  $p: {
    property: 'padding',
    parser: parseSpacingValue,
  },
  $pt: {
    property: 'paddingTop',
    parser: parseSpacingValue,
  },
  $pr: {
    property: 'paddingRight',
    parser: parseSpacingValue,
  },
  $pb: {
    property: 'paddingBottom',
    parser: parseSpacingValue,
  },
  $pl: {
    property: 'paddingLeft',
    parser: parseSpacingValue,
  },
  $px: {
    property: 'paddingInline',
    parser: parseSpacingValue,
  },
  $py: {
    property: 'paddingBlock',
    parser: parseSpacingValue,
  },

  $w: {
    property: 'width',
    parser: parseSpacingValue,
  },
  $minW: {
    property: 'minWidth',
    parser: parseNumberToRem,
  },
  $maxW: {
    property: 'maxWidth',
    parser: parseNumberToRem,
  },

  $h: {
    property: 'height',
    parser: parseSpacingValue,
  },
  $minH: {
    property: 'minHeight',
    parser: parseNumberToRem,
  },
  $maxH: {
    property: 'maxHeight',
    parser: parseNumberToRem,
  },

  $gap: {
    property: 'gap',
    parser: parseSpacingValue,
  },

  $justifyContent: {
    property: 'justifyContent',
  },
  $alignItems: {
    property: 'alignItems',
  },
  $flex: {
    property: 'flex',
  },
  $flexWrap: {
    property: 'flexWrap',
  },

  $fontSize: {
    property: 'fontSize',
  },
  $lineHeight: {
    property: 'lineHeight',
  },
  $fontWeight: {
    property: 'fontWeight',
  },
  $whiteSpace: {
    property: 'whiteSpace',
  },
  $textTransform: {
    property: 'textTransform',
  },
  $textAlign: {
    property: 'textAlign',
  },
  $color: {
    property: 'color',
    parser: parsePaletteColor,
  },

  $display: {
    property: 'display',
  },
  $overflow: {
    property: 'overflow',
  },

  $position: {
    property: 'position',
  },
  $top: {
    property: 'top',
    parser: parseSpacingValue,
  },
  $right: {
    property: 'right',
    parser: parseSpacingValue,
  },
  $bottom: {
    property: 'bottom',
    parser: parseSpacingValue,
  },
  $left: {
    property: 'left',
    parser: parseSpacingValue,
  },

  $bgColor: {
    property: 'backgroundColor',
    parser: parsePaletteColor,
  },

  $borderRadius: {
    property: 'borderRadius',
    parser: parseRadiusValue,
  },

  $border: {
    property: 'border',
  },
  $borderTop: {
    property: 'borderTop',
  },
  $borderBottom: {
    property: 'borderTop',
  },
  $borderLeft: {
    property: 'borderTop',
  },
  $borderRight: {
    property: 'borderTop',
  },
};
