import { ChartOptions, Tick } from 'chart.js';

export const ticksCallback = (
  value: string | number,
  _index: number,
  _ticks: Tick[],
) => `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;

export const defaultChartOptions: ChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    x: {
      // type: 'category',
      // labels: [],
      grid: {
        color: 'rgba(189, 180, 180, 0.4)',
      },
      ticks: {
        color: '#99979F',
        callback: ticksCallback,
      },
    },
    y: {
      border: {
        display: false,
      },
      grid: {
        color: 'rgba(189, 180, 180, 0.4)',
      },
      ticks: {
        color: '#99979F',
        callback: ticksCallback,
      },
    },
  },
};
