import React from 'react';

import { EVChart } from './EVChart';
import { Indicators } from './Indicators';
import { ProfitChart } from './ProfitChart';
import { ResultTable } from './ResultTable';
import { SelectRating } from './SelectRating';

import css from './info.module.scss';

export const Info = () => {
  return (
    <div className={css.pageGrid}>
      <Indicators />

      <ProfitChart />

      <ResultTable />

      <EVChart />

      <SelectRating />
    </div>
  );
};
