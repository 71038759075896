import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Icon } from '@/shared/components';
import styles from './CountInfo.module.scss';

const MINUTES_IN_HOUR = 60;

type Props = {
  className?: string;
  tasks_total: number;
  lengthMinutes: number;
};

export const CountInfo = ({ className, tasks_total, lengthMinutes }: Props) => {
  const { t } = useTranslation();

  const getItemDuration = (lengthMinutes: number) => {
    if (lengthMinutes < MINUTES_IN_HOUR)
      return `~${lengthMinutes} ${t('education.minutes', { count: lengthMinutes })}`;
    let hoursCount = Math.floor(lengthMinutes / MINUTES_IN_HOUR);
    const minutesRemainder = lengthMinutes % MINUTES_IN_HOUR;
    if (minutesRemainder >= 15 && minutesRemainder < 45) hoursCount += 0.5;
    if (minutesRemainder >= 45) hoursCount += 1;
    return `~${hoursCount} ${t('education.hours', { count: Math.floor(hoursCount) })}`;
  };

  return (
    <div className={classNames(styles.countInfoBlock, className)}>
      <div className={styles.countInfoItem}>
        <Icon name="clipboard-list" />
        {`${tasks_total} ${t('education.tasks', { count: tasks_total })}`}
      </div>
      <div className={styles.countInfoItem}>
        <Icon name="clock" $color="gray-200" />
        {getItemDuration(lengthMinutes)}
      </div>
    </div>
  );
};
