import { useRef } from 'react';
// import { useTranslation } from 'react-i18next';
// import type { RowClassParams } from 'ag-grid-community';
// import classNames from 'classnames';
// import { getSchedule } from '@/__old/actions/tournaments';
// import { FloatingAction } from '@/__old/components/Tournaments/Fab';
// import { TourContext } from '@/__old/components/Tournaments/TourProvider';
// import prepareTournamentColumns from '@/__old/components/Tournaments/TournamentsAll/tournaments.columns';
import useQueryParams from '@/__old/hooks/useQueryParams';
// import { ITournament } from '@/__old/types/index';
import { TournamentsTable } from '@/features/tournaments/components/tournaments-table';
// import { Spinner } from '@/shared/components';
import { IOrderedBy, TableProvider, useTable } from '@funfarm/kit';

// import DataGridWithKey from '@funfarm/kit/DataGrid/DataGridWithKey';
// import InfiniteLoading from '@funfarm/kit/DataGrid/InfiniteLoading';
// import { TournamentsFilters } from './TournamentsFilters';

// import paletteCss from '../TableColumnSettings/settings.module.scss';
// import styles from './table.module.scss';

export const TournamentsAllNew = () => {
  // const { t } = useTranslation();
  const queryParams = useQueryParams();

  // const { palette, tableColumns } = useContext(TourContext);

  const tableRef = useRef(null);

  const tableProps = useTable({
    pagination: { take: 50, skip: 0 },
    order:
      queryParams.orderBy && queryParams.orderType
        ? ({ [queryParams.orderBy]: queryParams.orderType } as IOrderedBy)
        : { start_date: 'asc' },
  });

  // const willPlayColumns = useMemo(
  //   () => prepareTournamentColumns(t).filter((item) => tableColumns.includes(item.field)),
  //   [t, tableColumns],
  // );

  // const allTourneyColumns = useMemo(
  //   () => prepareTournamentColumns(t).filter((item) => tableColumns.includes(item.field)),
  //   [t, tableColumns],
  // );

  return (
    <TableProvider tableRef={tableRef} {...tableProps}>
      {/* <TournamentsFilters /> */}

      <TournamentsTable />

      {/* <div className={css.selectedTable}>
        <DataGridWithKey<ITournament, 'tourneys'>
          fetchData={getSchedule}
          selectKey="tourneys"
          fetchKey={['tourneys', 'selected']}
          columns={willPlayColumns}
          getRowClass={(p) =>
            classNames(styles.tournamentsGridRow, paletteCss[`color-v2-${p?.data?.color}-${palette}`])
          }
          pagination={false}
          defaultFilters={{ selected: true }}
        />
      </div> */}

      {/* <div className={styles.allTables}>
        <InfiniteLoading<ITournament, 'tourneys'>
          fetchData={getSchedule}
          selectKey="tourneys"
          columns={allTourneyColumns}
          getRowClass={(p: RowClassParams<ITournament>) =>
            classNames(
              styles.tournamentsGridRow,
              paletteCss[`gradient-${p?.data?.color}-${palette}`],
              paletteCss[`color-v2-${p?.data?.color}-${palette}`],
            )
          }
        />
      </div> */}

      {/* <FloatingAction open /> */}
    </TableProvider>
  );
};
