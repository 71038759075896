import { useState } from 'react';
import { TableFiltersContext, TableFiltersContextValue } from './table-filters.context';

export const TableFilters = ({ children }: React.PropsWithChildren) => {
  const [hiddenTournamentsCount, setHiddenTournamentsCount] = useState<number>();

  const value: TableFiltersContextValue = {
    hiddenTournamentsCount,
    setHiddenTournamentsCount,
  };

  return <TableFiltersContext.Provider value={value}>{children}</TableFiltersContext.Provider>;
};
