import React, {
  ChangeEvent,
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
} from 'react';
import classNames from 'classnames';

import css from './radio.module.scss';

interface RadioProps {
  id: string;
  name: string;
  value: any;
  checked?: boolean;
  onChange?:
    | ((event: ChangeEvent<HTMLInputElement>) => Promise<boolean | undefined>)
    | ChangeEventHandler<HTMLInputElement>;
  className?: string;
}

const Radio = forwardRef(
  (props: RadioProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { id, name, value, checked, onChange, className } = props;

    return (
      <>
        <input
          className={css.radio_default}
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          ref={ref}
        />
        <span className={classNames(css.radio_custom, className)}></span>
      </>
    );
  },
);

export default Radio;
