import type { EducationModuleWithSteps } from '@/features/courses/models';
import type { ModulePageParams } from '@/features/courses/types';
import { ApiRequest } from '@/shared/api';
import { useQuery } from '@tanstack/react-query';

export const useGetEducationSteps = ({ courseId, blockId, moduleId }: ModulePageParams) =>
  useQuery({
    queryKey: ['steps', courseId, blockId, moduleId],
    queryFn: () =>
      new ApiRequest<EducationModuleWithSteps>({
        method: 'GET',
        url: `/api/gamification/way/courses/${courseId}/blocks/${blockId}/modules/${moduleId}/steps`,
      }),
  });
