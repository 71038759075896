import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import css from './radio.module.scss';

interface LabelProps extends PropsWithChildren {
  id: string;
  className?: string;
}

const Label = (props: LabelProps) => {
  const { id, className, children } = props;

  return (
    <label htmlFor={id} className={classNames(css.radio_label, className)}>
      {children}
    </label>
  );
};

export default Label;
