import React from 'react';

import { TableCell, Tooltip } from '@funfarm/kit';
import TableHeader from '@funfarm/kit/Table/TableHeader';

import css from './limits.module.scss';

export const LimitsTableHeader = () => {
  return (
    <TableHeader>
      <TableCell colspan={2} />
      <TableCell colspan={2}>
        Регспид 6-макс
        <Tooltip
          className={css.tooltip}
          description="6 или меньше людей за столом и уровни по 7 минут или больше"
        />
      </TableCell>
      <TableCell colspan={2}>
        Регспид фуллринг
        <Tooltip
          className={css.tooltip}
          description="7 или больше людей за столом и уровни по 7 минут или больше"
        />
      </TableCell>
      <TableCell colspan={2}>
        Турбо 6-макс
        <Tooltip
          className={css.tooltip}
          description="6 или меньше людей за столом и уровни по 4-6 минут"
        />
      </TableCell>
      <TableCell colspan={2}>
        Турбо фуллринг
        <Tooltip
          className={css.tooltip}
          description="7 или больше людей за столом и уровни по 4-6 минут"
        />
      </TableCell>
    </TableHeader>
  );
};
