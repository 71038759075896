import type { EducationItem } from '@/features/courses/types';
import { ApiRequest } from '@/shared/api';
import { useQuery } from '@tanstack/react-query';

export const useGetEducationCourses = () =>
  useQuery({
    queryKey: ['courses'],
    queryFn: () =>
      new ApiRequest<EducationItem[]>({
        method: 'GET',
        url: '/api/gamification/way/courses',
      }),
  });
