import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconExclamation = (props: IconProps) => {
  return (
    <Icon id="IconExclamation" viewBox="0 0 18 16" fill="none" {...props}>
      <path
        d="M9.00125 6.5V8.16667M9.00125 11.5H9.00958M3.22775 14.8333H14.7748C16.0578 14.8333 16.8596 13.4444 16.2181 12.3333L10.4446 2.33333C9.80312 1.22222 8.19937 1.22222 7.55787 2.33333L1.78437 12.3333C1.14287 13.4444 1.94475 14.8333 3.22775 14.8333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default IconExclamation;
