import type { DefaultTheme } from 'styled-components';
import { rem } from './utils';

export const styledTheme: DefaultTheme = {
  color: {
    white: '#FFFFFF',
    black: '#000000',
    canvas: '#1C1E23',
    'canvas-500': '#121315',
    'accent-10': '#F8FAFF',
    'accent-25': '#F3F7FF',
    'accent-50': '#E7EFFF',
    'accent-100': '#C3D7FF',
    'accent-200': '#87AFFE',
    'accent-300': '#6397FE',
    'accent-400': '#3F7FFE',
    'accent-500': '#1E70F0',
    'accent-600': '#0D51D8',
    'accent-700': '#0B43B2',
    'accent-800': '#093998',
    'accent-900': '#072F7F',
    'neutral-10': '#F9FAFB',
    'neutral-25': '#F2F3F5',
    'neutral-50': '#E7E9ED',
    'neutral-100': '#D0D4DC',
    'neutral-200': '#A1A9B8',
    'neutral-300': '#858FA3',
    'neutral-400': '#69758E',
    'neutral-500': '#445371',
    'neutral-600': '#3A4760',
    'neutral-700': '#303A4F',
    'neutral-800': '#293244',
    'neutral-900': '#202735',
    'gray-10': '#FAFAFB',
    'gray-25': '#F3F4F5',
    'gray-50': '#E9EAEC',
    'gray-100': '#D5D7DA',
    'gray-200': '#ACAFB5',
    'gray-300': '#93979F',
    'gray-400': '#7A7F89',
    'gray-500': '#595F6B',
    'gray-600': '#444851',
    'gray-700': '#3A3E46',
    'gray-800': '#34373E',
    'gray-900': '#2F3239',
    'red-25': '#FFF6F6',
    'red-50': '#FFEDED',
    'red-100': '#FFD1D1',
    'red-200': '#FFA3A3',
    'red-300': '#FF8787',
    'red-400': '#FF6C6C',
    'red-500': '#FF4747',
    'red-600': '#D93C3C',
    'red-700': '#B33232',
    'red-800': '#992B2B',
    'red-900': '#802323',
    'orange-25': '#FEF9F4',
    'orange-50': '#FDF4E9',
    'orange-100': '#FBE3C8',
    'orange-200': '#F7C891',
    'orange-300': '#F4B870',
    'orange-400': '#F1A74F',
    'orange-500': '#EE9123',
    'orange-600': '#CA7B1E',
    'orange-700': '#A76519',
    'orange-800': '#8F5715',
    'orange-900': '#774811',
    'green-25': '#F3FCF8',
    'green-50': '#E7F9F0',
    'green-100': '#C3EFDA',
    'green-200': '#86DFB4',
    'green-300': '#62D59E',
    'green-400': '#3DCB87',
    'green-500': '#0DBE69',
    'green-600': '#0BA159',
    'green-700': '#098549',
    'green-800': '#08723F',
    'green-900': '#075F35',
  },

  fontFamily: {
    primary: "'Inter', sans-serif",
  },

  font: {
    xs: {
      fontSize: rem(12),
      lineHeight: rem(16),
    },
    sm: {
      fontSize: rem(14),
      lineHeight: rem(16),
    },
    md: {
      fontSize: rem(16),
      lineHeight: rem(20),
    },
    lg: {
      fontSize: rem(20),
      lineHeight: rem(24),
    },
    xl: {
      fontSize: rem(24),
      lineHeight: rem(32),
    },
    '2xl': {
      fontSize: rem(32),
      lineHeight: rem(40),
    },
  },

  spacing: {
    px: '1px',
    0.5: rem(2),
    1: rem(4),
    1.5: rem(6),
    2: rem(8),
    2.5: rem(10),
    3: rem(12),
    3.5: rem(14),
    4: rem(16),
    4.5: rem(18),
    5: rem(20),
    6: rem(24),
    7: rem(28),
    8: rem(32),
    9: rem(36),
    10: rem(40),
    11: rem(44),
    12: rem(48),
    13: rem(52),
    14: rem(56),
    16: rem(64),
    20: rem(80),
    24: rem(96),
    28: rem(112),
    32: rem(128),
    36: rem(144),
    40: rem(160),
    44: rem(176),
    48: rem(192),
    52: rem(208),
    56: rem(224),
    60: rem(240),
    64: rem(256),
    72: rem(288),
    80: rem(320),
    96: rem(384),
    'container-xs': rem(352),
    'container-sm': rem(448),
    'container-md': rem(576),
  },

  radius: {
    xs: rem(4),
    sm: rem(6),
    md: rem(8),
    lg: rem(12),
    xl: rem(16),
    '2xl': rem(20),
    '3xl': rem(24),
    full: rem(9999),
  },

  zIndex: {
    docked: '10',
    dropdown: '1000',
    sticky: '1100',
    banner: '1200',
    overlay: '1300',
    modal: '1400',
    popover: '1500',
    skipLink: '1600',
    toast: '1700',
    tooltip: '1800',
  },

  shadow: {
    xs: '', // TODO
    lg: '0px 4px 25px 0px #0000008C',
  },

  breakpoint: {
    xs: '480px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  },

  // https://www.cssportal.com/css-cubic-bezier-generator/
  easing: {
    'ease-in-sine': 'cubic-bezier(0.12, 0, 0.39, 0)',
    'ease-out-sine': 'cubic-bezier(0.39, 0.575, 0.565, 1)',
    'ease-in-out-sine': 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
    'ease-in-quad': 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
    'ease-out-quad': 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
    'ease-in-out-quad': 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
    'ease-in-cubic': 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
    'ease-out-cubic': 'cubic-bezier(0.215, 0.61, 0.355, 1)',
    'ease-in-out-cubic': 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    'ease-in-quart': 'cubic-bezier(0.895, 0.03, 0.685, 0.22)',
    'ease-out-quart': 'cubic-bezier(0.165, 0.84, 0.44, 1)',
    'ease-in-out-quart': 'cubic-bezier(0.77, 0, 0.175, 1)',
    'ease-in-quint': 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
    'ease-out-quint': 'cubic-bezier(0.23, 1, 0.32, 1)',
    'ease-in-out-quint': 'cubic-bezier(0.86, 0, 0.07, 1)',
    'ease-in-expo': 'cubic-bezier(0.95, 0.05, 0.795, 0.035)',
    'ease-out-expo': 'cubic-bezier(0.19, 1, 0.22, 1)',
    'ease-in-out-expo': 'cubic-bezier(1, 0, 0, 1)',
    'ease-in-circ': 'cubic-bezier(0.6, 0.04, 0.98, 0.335)',
    'ease-out-circ': 'cubic-bezier(0.075, 0.82, 0.165, 1)',
    'ease-in-out-circ': 'cubic-bezier(0.785, 0.135, 0.15, 0.86)',
    'ease-in-back': 'cubic-bezier(0.6, -0.28, 0.735, 0.045)',
    'ease-out-back': 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
    'ease-in-out-back': 'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
  },
};
