import { useContext, useEffect, useRef } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getRooms, getTourneyTypes } from '@/__old/actions';
import { TourContext } from '@/__old/components/Tournaments/TourProvider';
import { useOptions } from '@/__old/helpers';
import { useTableFilters } from '@/features/tournaments/components/table-filters';
import {
  Badge,
  Box,
  CheckboxFormControl,
  DatePicker,
  HStack,
  Input,
  MultiSelect,
  type Option,
} from '@/shared/components';
import type { IError } from '@funfarm/kit';
import { useQuery } from '@tanstack/react-query';
import styles from '../tournaments.module.scss';

export const FilterInputs = () => {
  const { t } = useTranslation();
  const { filterParams, setFilterParams } = useContext(TourContext);

  const { data: roomsData, isLoading: isRoomsLoading } = useQuery<any, IError>({
    queryKey: ['rooms'],
    queryFn: () => getRooms(),
  });

  const roomsOptions = useOptions(roomsData, 'id', 'abrname');

  const { data: tourneyTypesData, isLoading: isTourneyTypesLoading } = useQuery<any, IError>({
    queryKey: ['tourney-types'],
    queryFn: () => getTourneyTypes(),
  });
  const tourneyTypesOptions = useOptions(tourneyTypesData, 'value', 'label');

  const seriesData = [
    { label: 'Regular', value: 'false' },
    { label: 'Series', value: 'true' },
  ];
  const seriesOptions = useOptions(seriesData, 'value', 'label');

  const { control, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: filterParams,
  });

  const watchedFields = useWatch({ control });
  const previousFieldsRef = useRef(watchedFields);

  useEffect(() => {
    if (JSON.stringify(previousFieldsRef.current) !== JSON.stringify(watchedFields)) {
      setFilterParams && setFilterParams(watchedFields);
      previousFieldsRef.current = watchedFields;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedFields]);

  useEffect(() => {
    reset(filterParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  const { hiddenTournamentsCount } = useTableFilters();

  return (
    <div className={styles.form}>
      <Controller
        control={control}
        name="rooms_id"
        render={({ field }) => (
          <MultiSelect
            {...field}
            options={roomsOptions as Option[]}
            label={t('Rooms')}
            placeholder={t('Rooms')}
            isLoading={isRoomsLoading}
            width={130}
          />
        )}
      />
      <Controller
        control={control}
        name="date_start"
        render={({ field }) => (
          <DatePicker
            {...field}
            showTimeInput
            label={t('tournaments.from')}
            timeInputLabel={t('Time')}
            dateFormat="dd.MM.yyyy HH:mm"
          />
        )}
      />
      <Controller
        control={control}
        name="date_end"
        render={({ field }) => (
          <DatePicker
            {...field}
            showTimeInput
            label={t('tournaments.to')}
            timeInputLabel={t('Time')}
            dateFormat="dd.MM.yyyy HH:mm"
          />
        )}
      />
      <Controller
        control={control}
        name="tourney_type"
        render={({ field }) => (
          <MultiSelect
            {...field}
            options={
              tourneyTypesOptions.map((item) => ({ ...item, label: t('Tourney ' + item.label) })) as Option[]
            }
            label={t('Tourney type')}
            placeholder={t('Tourney type')}
            isLoading={isTourneyTypesLoading}
            width={140}
          />
        )}
      />
      <Controller
        control={control}
        name="is_series"
        render={({ field }) => (
          <MultiSelect
            {...field}
            options={seriesOptions.map((item) => ({ ...item, label: t(item.label) })) as Option[]}
            label={t('Regular/Series')}
            placeholder={t('Regular/Series')}
            width={174}
          />
        )}
      />
      <Controller
        control={control}
        name="bi_start"
        render={({ field }) => (
          <Input {...field} label={t('Min Buy-in')} placeholder={t('Min Buy-in')} style={{ minWidth: '130px' }} />
        )}
      />

      <HStack $mb={1.5}>
        <Controller
          control={control}
          name="show_hidden"
          render={({ field }) => <CheckboxFormControl {...field} label={t('Hidden')} />}
        />
        <Box $ml={0.5}>{!!hiddenTournamentsCount && <Badge>{hiddenTournamentsCount}</Badge>}</Box>
      </HStack>

      <Controller
        control={control}
        name="show_passed"
        render={({ field }) => <CheckboxFormControl {...field} label={t('Past')} className={styles.checkbox} />}
      />
    </div>
  );
};
