import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconPreviewClose = (props: IconProps) => {
  return (
    <Icon id="IconPreviewClose" {...props}>
      <path d="M21.4617 7.11297C21.9516 7.36798 22.142 7.97184 21.887 8.46173C21.5071 9.19149 20.942 9.86145 20.2225 10.4511L20.022 10.6078L22.2123 12.7981C22.6028 13.1886 22.6028 13.8218 22.2123 14.2123C21.8518 14.5728 21.2846 14.6005 20.8923 14.2955L20.7981 14.2123L18.2632 11.6783C17.4741 12.0552 16.604 12.3582 15.6786 12.5785L16.49 15.6049C16.633 16.1383 16.3164 16.6867 15.7829 16.8296C15.2875 16.9623 14.7794 16.6989 14.5949 16.2334L14.5582 16.1225L13.6981 12.913C13.1424 12.9705 12.5749 13 12 13C11.421 13 10.8494 12.97 10.2899 12.9117L9.42972 16.1225C9.28678 16.656 8.73844 16.9726 8.20497 16.8296C7.70961 16.6969 7.40126 16.2146 7.47419 15.7193L7.49787 15.6049L8.30951 12.5757C7.38972 12.3559 6.52474 12.0544 5.73981 11.6797L3.20709 14.2123C2.81657 14.6028 2.1834 14.6028 1.79288 14.2123C1.4324 13.8518 1.40467 13.2846 1.70969 12.8923L1.79288 12.7981L3.98003 10.6119C3.91134 10.5591 3.84382 10.5055 3.77751 10.4511C3.05801 9.86148 2.49288 9.19152 2.11299 8.46174C1.85797 7.97185 2.04837 7.36799 2.53825 7.11298C3.02814 6.85797 3.632 7.04836 3.88701 7.53825C4.13569 8.01596 4.5261 8.47879 5.04526 8.90426C6.62344 10.1977 9.18916 11 12 11C14.8108 11 17.3766 10.1977 18.9547 8.90425C19.4739 8.47877 19.8643 8.01594 20.113 7.53826C20.368 7.04837 20.9718 6.85797 21.4617 7.11297Z" />
    </Icon>
  );
};

export default IconPreviewClose;
