import { FC, useContext } from 'react';
import classNames from 'classnames';
import { TourContext } from '@/__old/components/Tournaments/TourProvider';
import ColorRow from './ColorRow';
import css from '../settings.module.scss';

const PaletteActive: FC = () => {
  const { palette, paletteLegendIsOpen } = useContext(TourContext);
  return (
    <div
      className={classNames(
        paletteLegendIsOpen ? '' : css.isHidden,
        css.paletteActive_wrapper,
      )}
    >
      <div className={css.paletteActive}>
        <ColorRow paletteName={palette} />
      </div>
    </div>
  );
};

export default PaletteActive;
