import React, {
  ChangeEvent,
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import { Input } from '@funfarm/kit';
import { ELabelPosition } from '@funfarm/kit/types';

import css from './code.module.scss';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  value?: string | number;
  label?: string;
  labelPosition?: keyof typeof ELabelPosition;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  onInput?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  error?: string | boolean;
  dataTestId?: string;
  length?: number;
  focus?: boolean;
}

export const Code = forwardRef(
  (props: IProps, _ref: ForwardedRef<HTMLInputElement>) => {
    const {
      name,
      label,
      labelPosition = ELabelPosition.top,
      error,
      required,
      disabled,
      readonly,
      onChange,
      onFocus,
      onBlur,
      onInput,
      className,
      style,
      focus,
      // remove from rest
      value: propValue = '',
      length = 6,
      ...rest
    } = props;
    // value has to be a string
    const value = propValue.toString();

    const [currentValue, setValue] = useState<string[]>([...value]);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    useEffect(() => {
      if (focus && inputRefs.current[0]) inputRefs.current[0].focus();
    }, [focus]);

    useEffect(() => {
      setValue([...value]);
    }, [value]);

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const value = event.target.value.replace(/\D/g, '');

        const newValue = [...currentValue];
        newValue[index] = value;

        setValue(newValue);

        if (value && index < length - 1) {
          inputRefs.current[index + 1]?.focus();
        }

        if (value === '' && index > 0) {
          inputRefs.current[index - 1]?.focus();
        }

        if (onChange) {
          event.target.value = newValue.join('');
          onChange(event);
        }
      },
      [currentValue, onChange, length],
    );

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Backspace' && !currentValue[index]) {
          if (index > 0) {
            inputRefs.current[index - 1]?.focus();
          }
        }
      },
      [currentValue],
    );

    const renderCode = () => {
      return Array.from({ length }, (_, i) => (
        <Input
          type="number"
          size={1}
          maxLength={1}
          key={`${name}-${i}`}
          id={`${name}-${i}`}
          name={`${name}-${i}`}
          disabled={disabled}
          required={required}
          readOnly={readonly}
          value={currentValue[i]}
          onChange={(e) => handleChange(e, i)}
          onKeyDown={(e) => handleKeyDown(e, i)}
          onFocus={onFocus}
          onBlur={onBlur}
          onInput={onInput}
          className={css.code}
          error={error}
          ref={(el) => (inputRefs.current[i] = el)}
          {...rest}
        />
      ));
    };

    return (
      <div
        className={classNames(
          css.container,
          error && css.error,
          disabled && css.disabled,
          readonly && css.readonly,
          css.labelPosition,
          css[labelPosition],
          className,
        )}
        style={style}
      >
        {label && (
          <label htmlFor={name} className={css.label}>
            {label}
            {required && <span className={css.mark}>*</span>}
          </label>
        )}
        <div className={css.wrapper}>{renderCode()}</div>
      </div>
    );
  },
);
