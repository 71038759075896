import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser } from '@/__old/types';
import { useGlobalState } from '@funfarm/kit';

const FeatureRequests: FC = () => {
  const [user] = useGlobalState<IUser>('user'); // Получение данных пользователя из глобального состояния
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate('/auth');
    }
    const script = document.createElement('script');
    script.src = '/feature_upvote.js';
    script.async = true;
    script.onload = () => {
      console.log('onload', user, window.FeatureUpvote);
      if (user && window.FeatureUpvote) {
        window.FeatureUpvote.init({
          target: 'featureUpvoteBoard',
          boardRef: 'brd_4pjphtwkpsn2ogp',
          user: {
            id: user.id || 'unknown',
            displayName: user.username || 'Name unavailable',
            email: user.email || 'noemail@example.com',
          },
        });
      }
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [user]);
  // Запускаем эффект только при изменении данных пользователя

  return <div id="featureUpvoteBoard"></div>;
};

export default FeatureRequests;
