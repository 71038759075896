import { type ComponentPropsWithoutRef, type ComponentRef, forwardRef } from 'react';
import { clsx } from 'clsx';
import { Icon } from '@/shared/components';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import styles from './dropdown.module.scss';

export const DropdownMenu = DropdownMenuPrimitive.Root;

export const DropdownMenuTrigger = forwardRef<
  ComponentRef<typeof DropdownMenuPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Trigger>
>(({ className, children, ...restProps }, ref) => (
  <DropdownMenuPrimitive.Trigger
    asChild
    ref={ref}
    className={clsx(styles.dropdownMenuTrigger, className)}
    {...restProps}
  >
    {children}
  </DropdownMenuPrimitive.Trigger>
));
DropdownMenuTrigger.displayName = DropdownMenuPrimitive.Trigger.displayName;

export const DropdownMenuContent = forwardRef<
  ComponentRef<typeof DropdownMenuPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, children, ...restProps }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={8}
      align="start"
      className={clsx(styles.dropdownMenuContent, className)}
      {...restProps}
    >
      {children}
    </DropdownMenuPrimitive.Content>
  </DropdownMenuPrimitive.Portal>
));
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

export const DropdownMenuCheckboxItem = forwardRef<
  ComponentRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
>(({ children, className, ...props }, forwardedRef) => (
  <DropdownMenuPrimitive.CheckboxItem
    onSelect={(event) => event.preventDefault()}
    {...props}
    ref={forwardedRef}
    className={clsx(styles.dropdownCheckboxItem, className)}
  >
    <div className={styles.checkBox}>
      <DropdownMenuPrimitive.ItemIndicator className={styles.check}>
        <Icon name="check" $size={12} $color="accent-200" />
      </DropdownMenuPrimitive.ItemIndicator>
    </div>
    {children}
  </DropdownMenuPrimitive.CheckboxItem>
));
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;
