'use client';

import { styled, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

interface Props {
  $in: boolean;
  $duration?: React.CSSProperties['animationDuration'];
  $function?: React.CSSProperties['animationTimingFunction'];
}

const initialDuration = '0.25s';

export const Fade = styled.div<Props>`
  ${({ $in, $function = 'ease-in-out', $duration = initialDuration }) => ({
    visibility: $in ? 'visible' : 'hidden',
    transition: `visibility ${$duration} ${$function}`,
  })}
  animation:
   ${({ $in }) => ($in ? fadeIn : fadeOut)}
   ${({ $function = 'ease-out', $duration = initialDuration }) => `${$function} ${$duration}`} forwards;
`;
