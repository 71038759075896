import React from 'react';
import classNames from 'classnames';
import { getPermissions } from '@/__old/actions/tournaments';
import css from '@/__old/components/Tournaments/TournamentsAll/table.module.scss';
import { PermissionTableHeader } from '@/__old/components/Tournaments/TournamentsPermissions/PermissionTableHeader';
import { PermissionTableRow } from '@/__old/components/Tournaments/TournamentsPermissions/PermissionTableRow';
import { IPermissionsParams } from '@/__old/types/index';
import { Error, IError, Loader, Table } from '@funfarm/kit';
import { useQuery } from '@tanstack/react-query';

export const PermissionTable = () => {
  const {
    isLoading,
    isError,
    error,
    data = [],
    isFetching,
    refetch,
  } = useQuery<IPermissionsParams[], IError>({
    queryKey: ['permissions'],
    queryFn: getPermissions,
  });

  if (isError) return <Error layout {...error} />;

  if (isLoading)
    return (
      <Loader
        size="small"
        caption="Fetching table data..."
        className={css.loader}
      />
    );

  return (
    <>
      <Table
        className={classNames(css.tournamentsTable, isFetching && css.fetching)}
        cells={13}
      >
        <PermissionTableHeader />
        {data?.map((permission, index: number) => {
          return (
            <PermissionTableRow
              permission={permission}
              key={index}
              refetch={refetch}
            />
          );
        })}
      </Table>
    </>
  );
};
