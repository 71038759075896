import React, { ElementType, useEffect, useState } from 'react';
import { Error, Table, TableCell, TableHeader, TableRow } from '@funfarm/kit';
import TableSkeleton from '@funfarm/lk/src/__old/components/Finances/TableSkeleton';
import usePagination from '@funfarm/lk/src/__old/hooks/usePagination';
import {
  ApiFetchFunction,
  FilterComponent,
  FilterPrototype,
  TableItemPrototype,
} from '@funfarm/lk/src/__old/types/table';
import { useQuery } from '@tanstack/react-query';
import Pagination from '../Pagination/Pagination';

export type ColumnConfig<ItemType> = {
  header: string;
  fieldName?: keyof ItemType;
  renderCell?: (item: ItemType, column?: ColumnConfig<ItemType>) => JSX.Element;
  colspan?: number;
  sortable?: boolean;
};

type TableRendererProps<
  ItemType extends TableItemPrototype,
  FilterType extends FilterPrototype,
> = {
  fetchKey: string;
  fetchData: ApiFetchFunction<ItemType[], FilterType>;
  refetchCounter: number;
  columns: ColumnConfig<ItemType>[];
  filterComponent?: FilterComponent<FilterType>;
  classNames?: Record<
    'table' | 'header' | 'row',
    string | ((row?: ItemType) => string)
  >;
};

/** @deprecated */
const TableRenderer: ElementType = <
  ItemType extends TableItemPrototype,
  FilterType extends FilterPrototype,
>({
  fetchKey,
  fetchData,
  refetchCounter = 0,
  columns,
  filterComponent,
  classNames,
}: TableRendererProps<ItemType, FilterType>) => {
  const [filter, setFilter] = useState<FilterType>({} as FilterType);
  const { page, pageSize, setPage } = usePagination();

  const { data, isLoading, isError, refetch } = useQuery<ItemType[]>({
    queryKey: [fetchKey, filter, page, pageSize],
    queryFn: () => fetchData(filter, page, pageSize),
  });

  useEffect(() => {
    refetch();
  }, [refetch, refetchCounter]);

  const FilterElement = filterComponent;
  return (
    <>
      {FilterElement && <FilterElement values={filter} setValues={setFilter} />}
      {isLoading ? (
        <TableSkeleton rows={pageSize} />
      ) : isError ? (
        <Error message="Ошибка получения данных" />
      ) : (
        <>
          <Table
            className={
              typeof classNames?.table === 'function'
                ? classNames?.table()
                : classNames?.table
            }
          >
            <TableHeader
              className={
                typeof classNames?.header === 'function'
                  ? classNames?.header()
                  : classNames?.header
              }
            >
              {columns?.map((column, index) => (
                <TableCell colspan={column.colspan || 1} key={index}>
                  {column.header}
                  {column.fieldName && column.sortable !== false && (
                    <Table.ActionSort fieldName={column.fieldName as string} />
                  )}
                </TableCell>
              ))}
            </TableHeader>

            {data?.map((item: ItemType, index) => (
              <TableRow
                className={
                  typeof classNames?.row === 'function'
                    ? classNames?.row(item)
                    : classNames?.row
                }
                key={index}
              >
                {columns?.map((column, colIndex) => (
                  <TableCell colspan={column.colspan || 1} key={colIndex}>
                    {column.renderCell
                      ? column.renderCell(item, column)
                      : column.fieldName
                        ? (item[column.fieldName] as string)
                        : ''}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </Table>

          <Pagination page={page} setPage={setPage} />
        </>
      )}
    </>
  );
};

export default TableRenderer;
