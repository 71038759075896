import { useContext, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import classNames from 'classnames';
import { TourContext } from '@/__old/components/Tournaments/TourProvider';
import { TournamentsFilters } from '@/__old/components/Tournaments/TournamentsAll/TournamentsFilters';
import { TableFilters } from '../table-filters';
import { TournamentSelection } from './components/tournament-selection';
import { AllTournamentsTable } from './sub-tables/all-tournaments';
import { SelectedTournamentsTable } from './sub-tables/selected-tournaments';
import styles from './tournaments.module.scss';

// TODO: problems with data fetching and revalidation
// TODO: issue with page swithing and colorizing cells of first column (add custom classname to cell or row?)
// TODO: virtualization

export const TournamentsTable = () => {
  const { palette } = useContext(TourContext); // TODO

  const gridOne = useRef<AgGridReact>(null);
  const gridTwo = useRef<AgGridReact>(null);

  return (
    <TableFilters>
      <TournamentsFilters />

      <TournamentSelection>
        <div className={classNames(styles.wrapper, styles[`palette-variant-${palette}`])}>
          <SelectedTournamentsTable gridRef={gridOne} alignedGrids={[gridTwo]} />
          <AllTournamentsTable gridRef={gridTwo} />
        </div>
      </TournamentSelection>
    </TableFilters>
  );
};
