import React, { FC, useContext, useMemo, useState } from 'react';
import { ColDef } from 'ag-grid-community';
import { getWithdrawals } from '@/__old/actions/finances';
import DrawerLayout from '@/__old/components/Finances/DrawerLayout';
import AddOperation from '@/__old/components/Finances/TransfersHistory/AddOperation';
import TransfersFilter from '@/__old/components/Finances/TransfersHistory/TransfersFilter';
import { NavigationContext } from '@/__old/components/Layout/NavigationProvider';
import useBalancesAndBrm from '@/__old/hooks/useBalancesAndBrm';
import { Withdrawal, WithdrawalsFilter } from '@/__old/types/finances';
import { Button, Dialog, IconClose, IconPlus } from '@funfarm/kit';
import DataGrid from '@funfarm/kit/DataGrid/DataGrid';
import css from '../finances.module.scss';

const TransfersHistory: FC = () => {
  const [operationOpened, setOperationOpened] = useState(false);
  const { closeDrawer } = useContext(NavigationContext);
  const { metrics } = useBalancesAndBrm();

  const columnDefs = useMemo<ColDef<Withdrawal>[]>(
    () => [
      { field: 'date', headerName: 'Дата', type: 'date' },
      { field: 'curramount', headerName: 'Сумма', type: ['highlightAmount'] },
      { field: 'room', headerName: 'Счёт', type: 'accountLogo' },
      { field: 'type', headerName: 'Тип' },
      { field: 'balance', headerName: 'Остаток', type: 'usdValue' },
      {
        field: 'modcom',
        headerName: 'Статус',
        type: 'badge',
        refData: {
          Проведено: 'green',
          Удален: 'red',
        },
      },
    ],
    [],
  );

  return (
    <DrawerLayout
      pageHeadingProps={{
        title: 'История операций',
        renderButtons: () => (
          <>
            <Button
              color="secondary"
              iconLeft={<IconPlus />}
              onClick={() => setOperationOpened(true)}
            >
              Добавить операцию
            </Button>
            <Button
              view="contained"
              color="default"
              size="xlarge"
              className={css.drawerClose}
              icon={<IconClose fill="white" size="xlarge" />}
              onClick={closeDrawer}
            />
            {operationOpened && (
              <Dialog
                header="Добавить операцию"
                open={operationOpened}
                onClose={() => setOperationOpened(false)}
              >
                <AddOperation onClose={() => setOperationOpened(false)} />
              </Dialog>
            )}
          </>
        ),
      }}
      metricsProps={{
        caption: 'Доступно к выводу',
        value: metrics?.dvijpl || '-',
      }}
    >
      <DataGrid<Withdrawal, WithdrawalsFilter>
        fetchData={getWithdrawals}
        fetchKey="finances/withdraws"
        columns={columnDefs}
        filterComponent={TransfersFilter}
      />
    </DrawerLayout>
  );
};

export default TransfersHistory;
