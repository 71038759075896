import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

import { ucFirst } from '@funfarm/kit';

import css from './table.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  nowrap?: boolean;
  align?: string;
  valign?: string;
  action?: boolean;
  checked?: boolean;
}

export const TableRow = (props: Props) => {
  const { nowrap, valign, align, action, checked, className, style, ...rest } =
    props;

  return (
    <div
      {...rest}
      className={classNames(
        css.row,
        valign && css['valign' + ucFirst(valign)],
        align && css['align' + ucFirst(align)],
        nowrap && css['nowrap'],
        action && css.action,
        checked && css.checked,
        className,
      )}
      style={style}
    >
      {props.children}
    </div>
  );
};

export default TableRow;
