import React, {
  Fragment,
  isValidElement,
  MouseEvent,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';

import { ESizes, IconHelpCircle } from '@funfarm/kit';

import css from './tooltip.module.scss';

interface IProps {
  title?: string;
  description?: string;
  size?: ESizes;
  className?: string;
  children?: ReactElement | ReactNode;
  icon?: ReactElement;
}

export const Tooltip = (props: IProps) => {
  const { size = ESizes.small, description, title, className, icon } = props;
  let hideTimeout: ReturnType<typeof setTimeout>;
  let showTimeout: ReturnType<typeof setTimeout>;

  const [tooltip, showTooltip] = useState<boolean>(false);

  if (!description && !title && !icon) return null;

  const handleMouseOver = (_event: MouseEvent<HTMLElement>) => {
    showTimeout = setTimeout(() => showTooltip(true), 300);
    clearTimeout(hideTimeout);
  };

  const handleMouseLeave = (_event: MouseEvent<HTMLElement>) => {
    hideTimeout = setTimeout(() => showTooltip(false), 500);
    clearTimeout(showTimeout);
  };

  const minWidth =
    (description && description.length > 25
      ? 20
      : title
        ? title.length / 2 + 1
        : 6) + 'em';

  const tooltipWindow = (): ReactElement => {
    return (
      <Fragment key="anyKey">
        {tooltip && (
          <div
            className={classNames(css.tooltip, tooltip && css.visible)}
            style={{ minWidth }}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
          >
            {title && <h3 className={css.title}>{title}</h3>}
            {description && parse(description)}
            <div className={css.pointer} />
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <>
      {props.children && isValidElement(props.children) ? (
        React.cloneElement(
          props.children as ReactElement<any>,
          {
            onMouseOver: handleMouseOver,
            onMouseLeave: handleMouseLeave,
            className: classNames(
              props.children.props.className,
              css.parent,
              css.element,
              className,
            ),
          },
          [props.children.props.children, tooltipWindow()],
        )
      ) : (
        <span
          className={classNames(css.parent, className)}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          {icon ? (
            React.cloneElement(icon, {
              size,
              className: classNames(icon.props.className, css.icon),
            })
          ) : props.children ? (
            <span className={css.element}>{props.children}</span>
          ) : (
            <IconHelpCircle size={size} className={css.icon} />
          )}
          {tooltipWindow()}
        </span>
      )}
    </>
  );
};
