import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  DndItemData,
  DndList,
  DndListItem,
  DragEndEvent,
  HStack,
  IconButton,
  Popover,
  SwitchFormControl,
} from '@/shared/components';
import { useTableColumns } from '../table-columns.context';
import { TournamentTableColumn } from '../table-columns.resources';

const MemoizedSwitchFormControl = memo(
  SwitchFormControl,
  (prevProps, nextProps) => prevProps.label === nextProps.label && prevProps.value === nextProps.value,
);

export const TableColumnsPicker = memo(() => {
  const { t } = useTranslation();

  const { configurableColumns, toggleColumn, resetColumns, reorderColumns } = useTableColumns();

  const columnLabels: Record<TournamentTableColumn, string> = {
    [TournamentTableColumn.Room]: t('global.room'),
    [TournamentTableColumn.Name]: t('global.name'),
    [TournamentTableColumn.StartDate]: t('global.startDate'),
    [TournamentTableColumn.Status]: t('global.status'),
    [TournamentTableColumn.EndDate]: t('global.endDate'),
    [TournamentTableColumn.BuyIn]: t('global.buyin'),
    [TournamentTableColumn.Rank]: t('global.rank'),
    [TournamentTableColumn.Type]: t('global.type'),
    [TournamentTableColumn.Winrate]: t('global.winrate'),
    [TournamentTableColumn.ROI]: t('global.roi'),
    [TournamentTableColumn.AFS]: t('global.afs'),
    [TournamentTableColumn.TourneyDollar]: t('global.$/tour'),
    [TournamentTableColumn.AboutTournament]: t('global.aboutTourney'),
  };

  const handleChange = (value: boolean, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    toggleColumn(name);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { index: activeItemIndex } = event.active?.data.current as DndItemData;
    const overItemIndex = (event.over?.data.current as DndItemData)?.index;
    /** check drag indexes */
    if (activeItemIndex === undefined || overItemIndex === undefined || activeItemIndex === overItemIndex) return;
    reorderColumns(activeItemIndex, overItemIndex);
  };

  return (
    <Popover.Root>
      <Popover.Trigger>
        <Button $icon="three-columns" $colorScheme="neutral" children={t('Setup columns')} />
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content asChild align="end">
          <Box $w="container-xs">
            <Popover.ContentHeader>Настройка столбцов таблицы</Popover.ContentHeader>

            <Popover.ContentBody $px={0}>
              <DndList onDragEnd={handleDragEnd}>
                {configurableColumns.map(({ name, checked, required }, index) => (
                  <DndListItem
                    key={name}
                    item={({ listeners, attributes, setActivatorNodeRef }) => (
                      <HStack $pl={2} $gap={0} $bgColor="canvas" $borderRadius="sm">
                        <IconButton
                          ref={setActivatorNodeRef}
                          {...listeners}
                          {...attributes}
                          $icon="v-dots"
                          $size="sm"
                          $cursor="inherit"
                          $colorScheme="neutral"
                        />
                        <MemoizedSwitchFormControl
                          $flex="auto"
                          $pl={2}
                          $pr={6}
                          $justifyContent="space-between"
                          $h={10}
                          label={columnLabels[name]}
                          value={Boolean(checked)}
                          name={name}
                          onChange={handleChange}
                          disabled={required}
                        />
                      </HStack>
                    )}
                    itemId={name}
                    itemIndex={index}
                    itemData={{ index }}
                    listGap={0}
                  />
                ))}
              </DndList>
            </Popover.ContentBody>

            <Popover.ContentFooter $p={0}>
              <Popover.ContentFooterButton onClick={resetColumns} children="Настроить по умолчанию" />
            </Popover.ContentFooter>
          </Box>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
});
