import React, {
  CSSProperties,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  useCallback,
} from 'react';
import classNames from 'classnames';

import { ESizes } from '@funfarm/kit/types';

import { IconSpinner } from './';

import css from './icon.module.scss';

export interface IconProps {
  id?: string;
  size?: keyof typeof ESizes;
  children?: ReactNode;
  disabled?: boolean;
  checked?: boolean;
  button?: boolean;
  loading?: boolean;
  viewBox?: string;
  onClick?: MouseEventHandler<HTMLElement | SVGElement>;
  onMouseOver?: MouseEventHandler<HTMLElement | SVGElement>;
  onMouseLeave?: MouseEventHandler<HTMLElement | SVGElement>;
  className?: string;
  style?: CSSProperties;
  alt?: string;
  title?: string;
  fill?: CSSProperties['fill'];
}

export const Icon = (props: IconProps) => {
  const {
    size = ESizes.medium,
    disabled,
    checked,
    onClick,
    onMouseOver,
    onMouseLeave,
    button,
    className,
    style,
    loading,
    viewBox = '0 0 24 24',
    title,
    fill,
  } = props;

  const onIconClick = useCallback(
    (event: MouseEvent<HTMLElement | SVGElement>) => {
      if (disabled) return;

      if (onClick) {
        // can't use it because of Button with icon
        // event.stopPropagation();
        onClick(event);
      }
    },
    [onClick, disabled],
  );

  const onIconMouseOver = useCallback(
    (event: MouseEvent<HTMLElement | SVGElement>) => {
      onMouseOver && onMouseOver(event);
    },
    [onMouseOver],
  );

  const onIconMouseLeave = useCallback(
    (event: MouseEvent<HTMLElement | SVGElement>) => {
      onMouseLeave && onMouseLeave(event);
    },
    [onMouseLeave],
  );

  if (loading) {
    const { loading: _loading, ...loadingProps } = props;

    return (
      <IconSpinner
        className={classNames('spin', css.loading)}
        {...loadingProps}
      />
    );
  }

  return (
    <div
      className={classNames(
        css.icon,
        css[size],
        disabled && css.disabled,
        (onClick || button) && button !== false && css.action,
        className,
      )}
      onClick={onIconClick}
      onMouseOver={onIconMouseOver}
      onMouseLeave={onIconMouseLeave}
      style={style}
      title={title}
    >
      <svg viewBox={viewBox} {...(fill && { fill })}>
        {props.children}
        {checked && <circle cx="18" cy="6" r="4" className={css.checked} />}
      </svg>
    </div>
  );
};

export default Icon;
