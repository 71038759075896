import { FC } from 'react';
import { Helper } from '@/__old/components/Helper';
import PaletteActive from '@/__old/components/Tournaments/TableColumnSettings/Palette/PaletteActive';
import PaletteChoice from '@/__old/components/Tournaments/TableColumnSettings/Palette/PaletteChoice';
// import { TourContext } from '@/__old/components/Tournaments/TourProvider';
// import prepareTournamentColumns from '@/__old/components/Tournaments/TournamentsAll/tournaments.columns';
import { TableColumnsPicker } from '@/features/tournaments/components/table-columns';
import { Box, Button, CompactPopover, HStack } from '@/shared/components';
import { IconHelpCircle } from '@funfarm/kit';

type TableColumnSettingsProps = {
  defaultColumns: string[];
};

export const mandatoryColumns = [
  'checkbox',
  'room',
  'room_abr',
  'name',
  'room',
  'bi',
  'bi_usd',
  'start_date',
  'actions',
];

export const TableColumnSettings: FC<TableColumnSettingsProps> = ({ defaultColumns }) => {
  // const { t } = useTranslation();
  // const tableName = 'tournaments';

  // const { tableColumns, setTableColumns } = useContext(TourContext);

  // const [columns, setColumns] = useState<{ label: string; name: string; checked: boolean }[]>([]);

  // const tournamentTableColumns = useMemo(
  //   () =>
  //     prepareTournamentColumns(t, true).map((column) => ({
  //       label: t(column.headerName || ''),
  //       name: column.field,
  //       default: defaultColumns.includes(column.field),
  //     })),
  //   [defaultColumns, t],
  // );

  // useLayoutEffect(() => {
  //   setColumns(
  //     tournamentTableColumns
  //       .filter((item) => !mandatoryColumns.includes(item.name))
  //       .map((item) => ({
  //         label: item.label,
  //         name: item.name,
  //         checked: tableColumns ? tableColumns.includes(item.name) : item.default,
  //       })),
  //   );
  // }, [tableColumns, tournamentTableColumns]);

  // useEffect(() => {
  //   setTableColumns &&
  //     setTableColumns((prevColumns: unknown[]) => {
  //       const tableSettingsString = tableStorage.getItem();
  //       const tableSettings = tableSettingsString ? JSON.parse(tableSettingsString) : {};
  //       const defaultColumns =
  //         tableSettings[tableName] ?? tournamentTableColumns.filter((col) => col.default).map((col) => col.name);

  //       const uniqueColumns = new Set([...prevColumns, ...defaultColumns]);

  //       tableStorage.setItem(JSON.stringify({ ...tableSettings, [tableName]: [...uniqueColumns] }));

  //       return [...uniqueColumns];
  //     });
  // }, [tournamentTableColumns, setTableColumns, tableName]);

  // const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const { name, checked } = event.target;

  //   setTableColumns &&
  //     setTableColumns((prevColumns: unknown[]) => {
  //       const tableSettingsString = tableStorage.getItem();
  //       const tableSettings = tableSettingsString ? JSON.parse(tableSettingsString) : {};
  //       const checkedColumns = !checked ? [...prevColumns, name] : prevColumns.filter((col) => col !== name);

  //       tableStorage.setItem(JSON.stringify({ ...tableSettings, [tableName]: checkedColumns }));

  //       return checkedColumns;
  //     });
  // };

  // const handleReorder = (activeItemIndex: number, overItemIndex: number) => {
  //   const reorderedColumns = reorder(columns, activeItemIndex, overItemIndex);
  //   setColumns(reorderedColumns);
  // };

  // const setDefault = () => {
  //   setTableColumns && setTableColumns(defaultColumns);
  // };

  return (
    <HStack $gap={2}>
      <Helper
        path="tourneys"
        buttonProps={{
          label: 'Памятка',
          iconLeft: <IconHelpCircle />,
        }}
        xlarge
      />

      <CompactPopover trigger={<Button $icon="palette" $colorScheme="neutral" children="Настроить легенду" />}>
        <Box $p={2}>
          <PaletteChoice />
        </Box>
      </CompactPopover>

      <TableColumnsPicker />

      <PaletteActive />
    </HStack>
  );
};
