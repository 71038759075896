import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useDebounce } from 'use-debounce';

import {
  IconChevronDown,
  IconChevronUp,
  IconSpinner,
  IMultiSelectProps,
  useOnClickOutside,
} from '@funfarm/kit';
import { SelectedBadges } from '@funfarm/kit/Select/SelectedBadges';
import { ELabelPosition, IEventTarget, IOption } from '@funfarm/kit/types';

import { convertInputValue } from './helpers/convertInputValue';
import { getSelectedValues } from './helpers/getSelectedValues';

import css from './select.module.scss';

export interface IAutocompleteProps
  extends Omit<IMultiSelectProps, 'defaultLabel' | 'maxHeight'> {
  searchOptions?: (searchValue: string) => void;
  filterOutSelectedOptions?: boolean;
  multiple?: boolean;
}

export const Autocomplete = forwardRef(
  (props: IAutocompleteProps, _ref: ForwardedRef<HTMLInputElement>) => {
    const {
      name,
      label,
      labelPosition = ELabelPosition.top,
      error,
      displayError,
      empty,
      required,
      disabled,
      readonly,
      placeholder,
      onFocus,
      onBlur,
      onChange,
      className,
      style,
      options,
      loading,
      dataTestId,
      addonRight,
      dropUp,
      maxBadges = 1,
      searchOptions,
      filterOutSelectedOptions,
      multiple = false,
    } = props;
    const { t } = useTranslation();

    // value has to be a string
    const value = props.value ? props.value.toString() : '';

    const valueRef: React.RefObject<HTMLInputElement> =
      useRef<HTMLInputElement>(null);
    const labelRef: React.RefObject<HTMLInputElement> =
      useRef<HTMLInputElement>(null);
    const listRef: React.RefObject<HTMLUListElement> =
      useRef<HTMLUListElement>(null);

    const [internalValue, setInternalValue] = useState<(string | number)[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [debouncedSearchValue] = useDebounce(searchValue, 500);
    const [sortedOptions, setSortedOptions] = useState<IOption[]>([]);
    const [internalOptions, setInternalOptions] = useState<IOption[]>([]);
    const [filteredOptions, setFilteredOptions] = useState<IOption[]>([]);
    const [open, setOpen] = useState(false);
    const [focus, setFocus] = useState(false);
    const [listFocus, setListFocus] = useState(false);

    useEffect(() => {
      if (multiple) {
        setInternalValue(convertInputValue(value));
        setSearchValue(
          (labelPosition === ELabelPosition.inside && !value
            ? label
            : '') as string,
        );
      } else setSearchValue(getLabel(value) as string);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
      if (empty && !required) {
        const label = typeof empty === 'string' ? empty : '...';

        options.unshift({ label, value: '' });
      }

      setSortedOptions(
        options
          .slice()
          .sort((a: IOption, b: IOption): 1 | -1 =>
            a.label > b.label ? 1 : -1,
          ),
      );
    }, [options, empty, required]);

    useEffect(() => {
      if (filterOutSelectedOptions) {
        setInternalOptions(
          sortedOptions.filter(
            (sortedOption: IOption): boolean =>
              !internalValue.some(
                (item: IOption['value']): boolean =>
                  String(item) === String(sortedOption.value),
              ),
          ),
        );
      } else {
        setInternalOptions(sortedOptions);
      }
    }, [filterOutSelectedOptions, internalValue, sortedOptions]);

    useEffect(() => {
      if (!listFocus && !focus) setOpen(false);
    }, [focus, listFocus]);

    const getLabel = useCallback(
      (optionValue: string = value) => {
        const option = options.filter(
          (option) => option.value.toString() === optionValue,
        );

        if (labelPosition === ELabelPosition.inside && !value) {
          return label;
        }

        if (option[0]) return option[0].label;
        else return loading ? '' : optionValue;
      },
      [options, value, label, labelPosition, loading],
    );

    const handleFocus = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (readonly || disabled) return;

        setSearchValue('');

        setFocus(true);
        labelRef.current?.focus();

        setOpen(true);

        if (onFocus) onFocus(event);
      },
      [disabled, readonly, onFocus],
    );

    const handleBlur = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setFocus(false);

        if (onBlur) onBlur(event);
      },
      [onBlur],
    );

    const updateValue = (newValue: (string | number)[]) => {
      if (valueRef.current) {
        setInternalValue(newValue);
        valueRef.current.value = newValue.join();

        const event = new Event('input', { bubbles: true });
        valueRef.current.dispatchEvent(event);
      }
    };

    const handleSetValue = useCallback(
      (option: IOption) => {
        setListFocus(true);

        if (option.disabled) return;

        if (multiple) {
          updateValue(getSelectedValues(option.value, internalValue));
        } else {
          setOpen(false);
          updateValue([option.value]);
          setSearchValue(option.label as string);
        }
      },
      [internalValue, multiple],
    );

    const handleDelete = (optionValue: string | number) => {
      return () => {
        const newValue = internalValue.filter(
          (itemValue: string | number) =>
            String(itemValue) !== String(optionValue),
        );

        updateValue(newValue);
      };
    };

    const handleChange = (
      event: ChangeEvent<HTMLInputElement> | IEventTarget,
    ) => {
      if (onChange) onChange(event as ChangeEvent<HTMLInputElement>);
    };

    const handleSearch = ({
      target: { value },
    }: ChangeEvent<HTMLInputElement>) => {
      if (searchValue !== value) setSearchValue(value);
    };

    useEffect(() => {
      if (searchOptions) searchOptions(debouncedSearchValue);
    }, [debouncedSearchValue, searchOptions]);

    useEffect(() => {
      setFilteredOptions(
        searchOptions
          ? internalOptions
          : internalOptions.filter((option: IOption) =>
              String(option.label)
                .toLowerCase()
                .includes(debouncedSearchValue.toLowerCase()),
            ),
      );
    }, [internalOptions, searchOptions, debouncedSearchValue]);

    useOnClickOutside(listRef, ({ target }: Event) => {
      if (multiple) {
        setListFocus(false);
        const { current } = labelRef;

        if (
          current &&
          (target instanceof Node || target === null) &&
          !current.contains(target)
        )
          setSearchValue(
            (labelPosition === ELabelPosition.inside && !value
              ? label
              : '') as string,
          );
      }
    });

    return (
      <div
        className={classNames(
          css.autocomplete,
          error && css['error'],
          disabled && css['disabled'],
          readonly && css['readonly'],
          css.labelPosition,
          css[labelPosition],
          className,
        )}
        style={style}
      >
        {label && labelPosition !== ELabelPosition.inside && (
          <label
            htmlFor={name}
            className={classNames(css.label, css[labelPosition])}
          >
            {label}
            {required && <span className={css.mark}>*</span>}
          </label>
        )}
        <div className={css.errorWrapper}>
          {internalValue.length > 0 && multiple && (
            <div className={css.badges}>
              <SelectedBadges
                options={options}
                values={internalValue}
                maxBadges={maxBadges}
                disabled={disabled}
                handleDelete={handleDelete}
                readonly={readonly}
              />
            </div>
          )}
          <div className={css.addonWrapper}>
            <div
              className={classNames(
                css.wrapper,
                disabled && css.wrapperDisabled,
                readonly && css.wrapperReadonly,
                error && css.wrapperError,
                focus && css.focus,
              )}
            >
              <input
                id={name}
                readOnly
                type="hidden"
                name={name}
                value={internalValue.join()}
                disabled={disabled}
                data-testid={dataTestId}
                ref={valueRef}
                onInput={(e) =>
                  handleChange(e as ChangeEvent<HTMLInputElement>)
                }
              />
              <input
                id={name + '-label'}
                name={name + '-label'}
                readOnly={readonly}
                disabled={disabled}
                required={required}
                placeholder={placeholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onInput={handleSearch}
                value={searchValue}
                type="text"
                autoComplete="off"
                ref={labelRef}
                className={classNames(
                  labelPosition === ELabelPosition.inside &&
                    !value &&
                    css[labelPosition],
                )}
              />
              {loading ? (
                <IconSpinner className={classNames('spin', css.chevron)} />
              ) : open ? (
                <IconChevronUp
                  className={css.chevron}
                  onClick={() =>
                    handleBlur({} as ChangeEvent<HTMLInputElement>)
                  }
                />
              ) : (
                <IconChevronDown
                  className={css.chevron}
                  onClick={() =>
                    handleFocus({} as ChangeEvent<HTMLInputElement>)
                  }
                />
              )}
              <ul
                className={classNames(
                  css.options,
                  open && css.open,
                  dropUp ? css.up : css.down,
                )}
                ref={listRef}
              >
                {filteredOptions.length ? (
                  filteredOptions.map((option: IOption) => (
                    <li
                      key={option.value}
                      className={classNames(
                        css.option,
                        option.disabled && css.disabled,
                        !filterOutSelectedOptions &&
                          internalValue.some(
                            (itemValue: string | number) =>
                              String(itemValue) === String(option.value),
                          ) &&
                          css.selected,
                      )}
                      onMouseDown={() => handleSetValue(option)}
                    >
                      {option.label}
                    </li>
                  ))
                ) : (
                  <li
                    key="no-data"
                    className={classNames(css.option, css.disabled)}
                  >
                    {t('Nothing found')}
                  </li>
                )}
              </ul>
            </div>
            {addonRight &&
              React.cloneElement(addonRight, {
                className: classNames(
                  addonRight.props.className,
                  css['addonRight'],
                ),
              })}
          </div>
          {displayError && error && <p className={css.error}>{error}</p>}
        </div>
      </div>
    );
  },
);
