import React from 'react';
import { SignIn } from '@/__old/components/Auth/SignIn';
import { Footer } from '@/__old/components/Footer';
import { Paper } from '@funfarm/kit';
import css from './authlayout.module.scss';

interface IProps {
  twoFA?: boolean;
}

export const AuthLayout = (props: IProps) => {
  const { twoFA } = props;

  return (
    <div className={css.wrapper}>
      <div className={css.authOneBg}>
        <div className={css.bgOverlay}></div>

        <div className={css.shape}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1440 120"
          >
            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
          </svg>
        </div>
      </div>

      <div className={css.authPageContent}>
        <img src="/img/logo.svg" alt="Funfarm" className={css.logo} />

        <Paper className={css.container}>
          <SignIn twoFA={twoFA} />
        </Paper>

        <Footer className={css.footer} />
      </div>
    </div>
  );
};
