import * as RadixUIPopover from '@radix-ui/react-popover';
import * as Popover from './popover';

// TODO: add only necessary props

interface Props {
  trigger: React.ReactNode;
  children: React.ReactNode;

  align?: RadixUIPopover.PopoverContentProps['align'];
}

export const CompactPopover = (props: Props) => {
  const { trigger, children, align } = props;
  return (
    <Popover.Root>
      <Popover.Trigger asChild>{trigger}</Popover.Trigger>
      <Popover.Portal>
        <Popover.Content align={align}>{children}</Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
