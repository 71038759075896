import React from 'react';

export function assignRef<T>(ref: React.Ref<T>, value: T) {
  if (typeof ref === 'function') ref(value);
  else if (ref != null) (ref as React.MutableRefObject<T | null>).current = value;
}

export function mergeRefs<T = HTMLElement>(refs: Array<React.Ref<T>>): React.RefCallback<T> {
  return (value) => refs.forEach((ref) => assignRef(ref, value));
}
