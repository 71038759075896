import { useEffect, useMemo, useState } from 'react';
import { reorder } from '@/shared/utils/array';
import { TableColumnsContext, TableColumnsContextValue } from './table-columns.context';
import { initConfigurableColumns, TableColumnsStorage, tableConfigurableColumn } from './table-columns.resources';

export const TableColumnsProvider = ({ children }: React.PropsWithChildren) => {
  const [columns, setColumns] = useState(initConfigurableColumns);

  /** save columns to storage */
  useEffect(() => TableColumnsStorage.updateColumns(columns), [columns]);

  const value: TableColumnsContextValue = useMemo(
    () => ({
      configurableColumns: columns,

      toggleColumn: (name: string) =>
        setColumns((oldColumns) =>
          oldColumns.map((column) => {
            if (column.name === name) return { ...column, checked: !column.checked };
            return column;
          }),
        ),

      resizeColumn: (name: string, width: number) =>
        setColumns((oldColumns) =>
          oldColumns.map((column) => {
            if (column.name === name) return { ...column, width };
            return column;
          }),
        ),

      reorderColumns: (activeItemIndex: number, overItemIndex: number) => {
        const reorderedColumns = reorder(columns, activeItemIndex, overItemIndex);
        setColumns(reorderedColumns);
      },
      resetColumns: () => setColumns(tableConfigurableColumn),
    }),
    [columns],
  );

  return <TableColumnsContext.Provider value={value}>{children}</TableColumnsContext.Provider>;
};
