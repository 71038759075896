import { useTranslation } from 'react-i18next';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import FeatureRequest from '@/__old/components/FeatureRequests/FeatureRequest';
import FinanceBalance from '@/__old/components/Finances/Balance/FinanceBalance';
import WithdrawalRequests from '@/__old/components/Finances/Withdrawals/WithdrawalRequests';
import { Info } from '@/__old/components/Info';
import { ErrorLayout, Layout, NavigationLayout } from '@/__old/components/Layout';
import { Player } from '@/__old/components/Player';
import { Profile } from '@/__old/components/Profile';
import { Select } from '@/__old/components/Select';
import { Statistic } from '@/__old/components/Statistic';
import { Tournaments } from '@/__old/components/Tournaments';
import { TournamentsAllNew } from '@/__old/components/Tournaments/TournamentsAll/TournamentsAllNew';
import { TournamentsLimits } from '@/__old/components/Tournaments/TournamentsLimits/TournamentsLimits';
import { TournamentsPermissions } from '@/__old/components/Tournaments/TournamentsPermissions/TournamentsPermissions';
import { TrainerDashboard } from '@/__old/components/Trainings/Dashboard';
import { TrainingReports } from '@/__old/components/Trainings/Reports';
import { preparePath } from '@/__old/helpers';
import { IRoute } from '@/__old/types';
import { EducationLayout } from '@/features/courses/components';
import {
  EducationStep,
  EducationBlock,
  EducationCourse,
  EducationCourses,
  EducationModule,
} from '@/features/courses/pages';
import {
  IconBookmark,
  IconChartLinePresentation,
  IconDiagramCircle,
  IconFinance,
  IconGraduateHat,
  IconStar,
  IconUsers,
} from '@funfarm/kit';
import IconDocumentText from '@funfarm/kit/Icon/icons/DocumentText';

export const useRoutes = () => {
  const { t } = useTranslation();

  const routes: IRoute[] = [
    {
      path: '/',
      Component: Layout,
      ErrorBoundary: ErrorLayout,
      handle: {
        visible: false,
        layout: <NavigationLayout />,
      },
      children: [
        {
          index: true,
          element: <Navigate to="info" replace />,
          handle: {
            visible: false,
          },
        },
        {
          path: 'auth',
          Component: () => <>Auth</>,
          handle: {
            layout: (
              <>
                Авторизация успешна.
                <br />
                Вернитесь на предыдущую страницу.
              </>
            ),
            visible: false,
          },
        },
        {
          path: 'profile',
          Component: Profile,
          handle: {
            name: t('Profile'),
            icon: <IconUsers />,
            crumb: false,
            visible: false,
          },
        },
        {
          path: 'info',
          Component: Info,
          handle: {
            name: t('Info'),
            icon: <IconDocumentText />,
            crumb: false,
          },
        },
        {
          path: 'player',
          Component: Player,
          handle: {
            name: t('Rang changes'),
            icon: <IconChartLinePresentation />,
            crumb: false,
          },
        },
        {
          path: 'statistic',
          Component: Statistic,
          handle: {
            name: t('Statistic'),
            icon: <IconDiagramCircle />,
            crumb: false,
          },
        },
        {
          path: 'select',
          Component: Select,
          handle: {
            name: t('Select'),
            icon: <IconBookmark />,
            crumb: false,
          },
        },
        {
          path: 'tournaments',
          Component: Tournaments,
          handle: {
            name: t('Tournaments'),
            icon: <IconStar />,
            crumb: false,
          },
          children: [
            {
              index: true,
              element: <TournamentsAllNew />,
              handle: {
                visible: false,
              },
            },
            {
              index: false,
              path: 'new',
              element: <TournamentsAllNew />,
              handle: {
                visible: false,
              },
            },
            {
              path: 'permissions',
              element: <TournamentsPermissions />,
              handle: {
                visible: false,
              },
            },
            {
              path: 'limits',
              element: <TournamentsLimits />,
              handle: {
                visible: false,
              },
            },
          ],
        },
        {
          path: 'finance-balance',
          Component: FinanceBalance,
          handle: {
            name: t('Finances'),
            icon: <IconFinance />,
            crumb: false,
          },
        },
        {
          path: 'finance-requests',
          Component: WithdrawalRequests,
          handle: {
            visible: false,
          },
        },
        {
          path: 'trainings',
          Component: TrainingReports,
          handle: {
            visible: false,
          },
        },
        {
          path: 'trainings/dashboard',
          Component: TrainerDashboard,
          handle: {
            visible: false,
          },
        },
        {
          path: 'courses',
          Component: EducationLayout,
          handle: {
            name: t('education.ffEducation'),
            icon: <IconGraduateHat />,
            crumb: false,
          },
          children: [
            {
              index: true,
              element: <EducationCourses />,
              handle: {
                visible: false,
              },
            },
            {
              path: ':courseId',
              element: <EducationCourse />,
              handle: {
                visible: false,
              },
            },
            {
              path: ':courseId/blocks/:blockId',
              element: <EducationBlock />,
              handle: {
                visible: false,
              },
            },
            {
              path: ':courseId/blocks/:blockId/modules/:moduleId',
              element: <EducationModule />,
              handle: {
                visible: false,
              },
            },
            {
              path: ':courseId/blocks/:blockId/modules/:moduleId/steps/:stepId',
              element: <EducationStep />,
              handle: {
                visible: false,
              },
            },
          ],
        },
      ],
    },
    {
      path: 'feature-requests',
      Component: FeatureRequest,
      handle: {
        visible: false,
      },
    },
  ];

  return preparePath(routes);
};

export const Routes = () => {
  const routes: IRoute[] = useRoutes();

  return (
    <RouterProvider
      router={createBrowserRouter([
        // ...getSuperTokensRoutesForReactRouterDom(reactRouterDom, [EmailPasswordPreBuiltUI, EmailVerificationPreBuiltUI]).map((r) => r.props),
        ...routes,
      ])}
    />
  );
};
