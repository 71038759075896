import type { BlockPageParams, ModulePageParams, StepPageParams } from '@/features/courses/types';

export const ROUTES = {
  courses: '/courses',
  blocksByCourseId: (courseId: string) => `/courses/${courseId}`,
  modulesByBlockId: ({ courseId, blockId }: BlockPageParams) => `/courses/${courseId}/blocks/${blockId}`,
  stepsByModuleId: ({ courseId, blockId, moduleId }: ModulePageParams) =>
    `/courses/${courseId}/blocks/${blockId}/modules/${moduleId}`,
  stepById: ({ courseId, blockId, moduleId, stepId }: StepPageParams) =>
    `/courses/${courseId}/blocks/${blockId}/modules/${moduleId}/steps/${stepId}`,
};
