import type { EducationModuleWithSteps, ExtendedEducationStep } from '@/features/courses/models';
import type { StepPageParams } from '@/features/courses/types';
import { ApiRequest } from '@/shared/api';
import { useQuery } from '@tanstack/react-query';

export const useGetEducationStep = ({ courseId, blockId, moduleId, stepId }: StepPageParams) =>
  useQuery({
    queryKey: ['steps', courseId, blockId, moduleId, stepId],
    queryFn: () =>
      new ApiRequest<{ module: EducationModuleWithSteps; step: ExtendedEducationStep }>({
        method: 'GET',
        url: `/api/gamification/way/courses/${courseId}/blocks/${blockId}/modules/${moduleId}/steps/${stepId}`,
      }),
  });
