import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  type ICellRendererParams,
  type ValueGetterParams,
  type ColDef,
  RowClassParams,
  GetRowIdFunc,
} from 'ag-grid-community';
import classNames from 'classnames';
import { differenceInMinutes, format, isAfter, isToday, parseISO } from 'date-fns';
import i18n from 'i18next';
import { localeMap } from '@/__old/helpers/i18n';
import { HStack, Icon, Text } from '@/shared/components';
import { applyCurrency } from '@/shared/utils/currency';
import { TournamentTableColumn, useTableColumns } from '../table-columns';
import { TournamentAction } from './components/tournament-action';
import { TournamentSelector } from './components/tournament-selection';
import styles from './tournaments.module.scss';

const displayTournamentDate = (ISOString: string) => {
  if (!ISOString) return null;
  const date = parseISO(ISOString);
  if (isToday(date)) return format(date, 'HH:mm', { locale: localeMap[i18n.language] });
  return format(date, 'd LLL HH:mm', { locale: localeMap[i18n.language] });
};

export const getRowId: GetRowIdFunc = (params) => String(params.data.id);

export const getPaletteClass = (params: RowClassParams) =>
  classNames(styles.palette, styles[`palette--${params.data.color}`]);

const renderTournamentIconType = (tournamentType: number) => {
  switch (tournamentType) {
    case 1:
      return (
        <>
          <Icon name="knockout" />
        </>
      );

    case 2:
      return <>{/*снежинка*/}</>;

    case 3:
      return (
        <>
          {/*снежинка*/}
          <Icon name="six" />
        </>
      );

    case 4:
      return (
        <>
          <Icon name="knockout" />
          <Icon name="turbo" />
          <Icon name="six" />
        </>
      );
    case 5:
      return (
        <>
          <Icon name="knockout" />
          <Icon name="hyper-turbo" />
          <Icon name="six" />
        </>
      );
    case 6:
      return (
        <>
          <Icon name="knockout" />
          <Icon name="turbo" />
          {/*9*/}
        </>
      );
    case 7:
      return (
        <>
          <Icon name="turbo" />
          {/*снежинка*/}
          <Icon name="six" />
        </>
      );
    case 8:
      return (
        <>
          <Icon name="turbo" />
          {/*снежинка*/}
          {/*9*/}
        </>
      );
    case 9:
      return (
        <>
          <Icon name="hyper-turbo" />
          {/*снежинка*/}
          {/*9*/}
        </>
      );
    case 10:
      return (
        <>
          <Icon name="stairs" />
        </>
      );
    default:
      return null;
  }
};

export const useTournamentColumns = (options: { isSelectedTournaments: boolean }): ColDef[] => {
  const { isSelectedTournaments } = options;
  const { t } = useTranslation();

  const { configurableColumns } = useTableColumns();

  return useMemo(() => {
    const colDefMap: Record<TournamentTableColumn, ColDef> = {
      [TournamentTableColumn.Room]: {
        field: TournamentTableColumn.Room,
        sortable: true,
        comparator: () => 0,
        resizable: true,
        lockPinned: true,
        flex: 2,
        minWidth: 80,
        maxWidth: 200,
        headerName: t('global.room'),
        valueGetter: (p: ValueGetterParams) => p.data.room_abr,
      },

      [TournamentTableColumn.Name]: {
        field: TournamentTableColumn.Name,
        sortable: false,
        resizable: true,
        lockPinned: true,
        flex: 32,
        minWidth: 480,
        maxWidth: 1600,
        headerName: t('global.name'),
        cellStyle: {
          userSelect: 'text',
        },
        cellRenderer: (p: ICellRendererParams) => (
          <HStack $h="100%" $justifyContent="space-between" $gap={1}>
            <Text as="span" $color="inherit" $truncated children={p.data.name} />
            <TournamentAction isSelectedTournament={isSelectedTournaments} tounament={p.data} />
          </HStack>
        ),
      },

      [TournamentTableColumn.StartDate]: {
        field: TournamentTableColumn.StartDate,
        sortable: true,
        comparator: () => 0,
        resizable: true,
        lockPinned: true,
        headerName: t('global.start'),
        headerClass: styles.headerCentered,
        cellStyle: {
          textAlign: 'center',
        },
        flex: 4,
        minWidth: 120,
        maxWidth: 320,
        valueGetter: (params: ValueGetterParams) => displayTournamentDate(params.data.start_date),
      },

      [TournamentTableColumn.Status]: {
        field: TournamentTableColumn.Status,
        sortable: false,
        resizable: true,
        lockPinned: true,
        headerName: t('global.status'),
        headerClass: styles.headerCentered,
        cellStyle: {
          textAlign: 'center',
        },
        flex: 5,
        minWidth: 160,
        maxWidth: 320,
        cellRenderer: (params: ICellRendererParams) => {
          const startDate = new Date(params.data.start_date);
          const endDate = new Date(params.data.end_date);
          const now = new Date();

          // if tournament is finished
          if (isAfter(now, endDate))
            return (
              <Text
                as="span" //
                $color="gray-500"
                children={t('global.finished')}
              />
            );

          const minutesBeforeStart = differenceInMinutes(startDate, now);
          const minutesToFinish = differenceInMinutes(endDate, now);

          // if tournament is starting
          if (minutesBeforeStart > 0 && minutesBeforeStart < 60)
            return (
              <Text as="span" $color="green-400">
                {`${t('time.startIn')} ${t('time.minutes', { count: minutesBeforeStart })}`}
              </Text>
            );
          // if tournament is running
          else if (minutesBeforeStart < 0 && minutesToFinish > 0)
            return (
              <Text as="span" $color="red-400">
                {`${t('time.runTime')} ${minutesBeforeStart < 60 ? '' : t('time.minutes', { count: minutesBeforeStart * -1 })}`}
              </Text>
            );
          else return null;
        },
      },

      [TournamentTableColumn.EndDate]: {
        field: TournamentTableColumn.EndDate,
        sortable: true,
        comparator: () => 0,
        resizable: true,
        lockPinned: true,
        headerName: t('global.finish'),
        headerClass: styles.headerCentered,
        cellStyle: {
          textAlign: 'center',
        },
        flex: 4,
        minWidth: 120,
        maxWidth: 320,
        valueGetter: (params: ValueGetterParams) => displayTournamentDate(params.data.end_date),
      },

      [TournamentTableColumn.BuyIn]: {
        field: TournamentTableColumn.BuyIn,
        sortable: true,
        comparator: () => 0,
        resizable: true,
        lockPinned: true,
        headerName: t('global.buyin'),
        headerClass: styles.headerCentered,
        cellStyle: {
          textAlign: 'center',
        },
        flex: 3,
        minWidth: 120,
        maxWidth: 320,
        valueGetter: (params: ValueGetterParams) =>
          applyCurrency({ amount: params.data.bi, currency: params.data.currency }),
      },

      [TournamentTableColumn.Rank]: {
        field: TournamentTableColumn.Rank,
        sortable: true,
        comparator: () => 0,
        resizable: true,
        lockPinned: true,
        headerName: t('global.rank'),
        headerClass: styles.headerCentered,
        flex: 2,
        minWidth: 80,
        maxWidth: 160,
        cellStyle: {
          textAlign: 'center',
        },
      },

      [TournamentTableColumn.Type]: {
        field: TournamentTableColumn.Type,
        sortable: false,
        resizable: true,
        lockPinned: true,
        headerName: t('global.type'),
        headerClass: styles.headerCentered,
        flex: 3,
        minWidth: 100,
        maxWidth: 320,
        cellStyle: {
          textAlign: 'center',
          fontSize: 20,
        },
        cellRenderer: (params: ICellRendererParams) => (
          <HStack $h="100%" $gap={0.5}>
            {renderTournamentIconType(params.data.type)}
          </HStack>
        ),
      },

      [TournamentTableColumn.Winrate]: {
        field: TournamentTableColumn.Winrate,
        sortable: true,
        comparator: () => 0,
        resizable: true,
        lockPinned: true,
        headerClass: styles.headerCentered,
        headerName: t('global.winrate'),
        cellStyle: {
          textAlign: 'center',
        },
        flex: 3,
        minWidth: 120,
        maxWidth: 240,
      },

      [TournamentTableColumn.ROI]: {
        field: TournamentTableColumn.ROI,
        sortable: true,
        comparator: () => 0,
        resizable: true,
        lockPinned: true,
        headerName: t('global.roi'),
        headerClass: styles.headerCentered,
        cellStyle: {
          textAlign: 'center',
        },
        flex: 3,
        minWidth: 120,
        maxWidth: 240,
        valueGetter: (p: ValueGetterParams) => `${Math.round(Number(p.data.roi) * 100)}%`,
      },

      [TournamentTableColumn.AFS]: {
        field: TournamentTableColumn.AFS,
        sortable: true,
        comparator: () => 0,
        resizable: true,
        lockPinned: true,
        headerName: t('global.afs'),
        headerClass: styles.headerCentered,
        cellStyle: {
          textAlign: 'center',
        },
        flex: 3,
        minWidth: 80,
        maxWidth: 320,
      },

      [TournamentTableColumn.TourneyDollar]: {
        field: TournamentTableColumn.TourneyDollar,
        sortable: true,
        comparator: () => 0,
        resizable: true,
        lockPinned: true,
        headerName: t('$/tour'),
        headerClass: styles.headerCentered,
        flex: 3,
        minWidth: 80,
        maxWidth: 320,
        cellStyle: {
          textAlign: 'center',
        },
        valueGetter: (p: ValueGetterParams) => `$${p.data.tourney_dollar}`,
      },

      [TournamentTableColumn.AboutTournament]: {
        field: TournamentTableColumn.AboutTournament,
        sortable: true,
        comparator: () => 0,
        resizable: true,
        lockPinned: true,
        headerName: t('global.aboutTourney'),
        headerClass: styles.headerCentered,
        flex: 4,
        minWidth: 120,
        maxWidth: 320,
        cellStyle: {
          textAlign: 'center',
        },
      },
    };

    const columDefs = configurableColumns
      .filter((col) => col.checked)
      .map((col) => {
        const colDef = colDefMap[col.name];
        return {
          ...colDef,
          width: col.width,
          flex: col.width ? undefined : colDef.flex,
        };
      });

    const selectorColumnDef: ColDef = {
      field: 'checkbox',
      sortable: false,
      resizable: false,
      suppressMovable: true,
      lockPinned: true,
      headerName: '',
      headerClass: styles.headerCentered,
      minWidth: 48,
      maxWidth: 48,
      cellStyle: {
        textAlign: 'center',
        overflow: 'visible',
      },
      cellRenderer: (params: ICellRendererParams) => (
        <HStack $h="100%" $position="relative" $left={1.5}>
          <TournamentSelector tournament={params.data} isSelectedTournament={isSelectedTournaments} />
        </HStack>
      ),
    };

    return [selectorColumnDef, ...columDefs];
  }, [configurableColumns, isSelectedTournaments, t]);
};
