import React from 'react';
import { ICellRendererParams, SortDirection, ValueGetterParams } from 'ag-grid-community';
import classNames from 'classnames';
import { differenceInMinutes, isAfter, isBefore, parseISO } from 'date-fns';
import { TFunction } from 'i18next';
import TournamentRowActions from '@/__old/components/Tournaments/TournamentsAll/TournamentRowActions';
import css from '@/__old/components/Tournaments/TournamentsAll/table.module.scss';
import { money } from '@/__old/helpers/numbers';
import { displayDate, displayStartDate } from '@/__old/helpers/utils';
import { ITournament } from '@/__old/types/index';
import { IconHyperTurbo, IconKnockout, IconSix, IconStairs, IconTurbo } from '@funfarm/kit';

const getColor = (startDate: string | null, endDate?: string | null) => {
  if (!startDate) return null;

  const now = new Date();
  const start = parseISO(startDate ?? '');
  const end = parseISO(endDate ?? '');

  if (isBefore(now, startDate)) {
    const minutesToStart = differenceInMinutes(start, now);

    if (minutesToStart < 60) return 'green';
  }

  if (isAfter(now, end)) return 'grey';

  if (isAfter(now, start)) return 'red';

  return null;
};

const renderIconType = (tournamentType: number) => {
  switch (tournamentType) {
    case 1:
      return (
        <>
          <IconKnockout />
        </>
      );
    case 2:
      return <>{/*снежинка*/}</>;
    case 3:
      return (
        <>
          {/*снежинка*/}
          <IconSix />
        </>
      );
    case 4:
      return (
        <>
          <IconKnockout />
          <IconTurbo />
          <IconSix />
        </>
      );
    case 5:
      return (
        <>
          <IconKnockout />
          <IconHyperTurbo />
          <IconSix />
        </>
      );
    case 6:
      return (
        <>
          <IconKnockout />
          <IconTurbo />
          {/*9*/}
        </>
      );
    case 7:
      return (
        <>
          <IconTurbo />
          {/*снежинка*/}
          <IconSix />
        </>
      );
    case 8:
      return (
        <>
          <IconTurbo />
          {/*снежинка*/}
          {/*9*/}
        </>
      );
    case 9:
      return (
        <>
          <IconHyperTurbo />
          {/*снежинка*/}
          {/*9*/}
        </>
      );
    case 10:
      return (
        <>
          <IconStairs />
        </>
      );
    default:
      return null;
  }
};

export const defaultTournamentColumns = [
  'checkbox',
  'room',
  'room_abr',
  'name',
  'start_date',
  'bi',
  'bi_usd',
  'end_date',
  'type',
  'actions',
];

const prepareTournamentColumns = (t: TFunction<'translation', undefined>) => [
  {
    field: 'room',
    headerName: t('Room'),
    flex: 1,
    maxWidth: 78,
    valueGetter: (p: ValueGetterParams) => p.data.room_abr,
    cellClass: css.paled,
  },
  {
    field: 'name',
    headerName: t('Name'),
    flex: 5,
    minWidth: 400,
    sortable: false,
    cellStyle: { userSelect: 'text' },
    cellClass: css.paled,
  },
  {
    field: 'start_date',
    headerName: t('Start'),
    flex: 3,
    minWidth: 100,
    maxWidth: 160,
    initialSort: 'asc' as SortDirection,
    cellRenderer: (p: ICellRendererParams) => (
      <div className={css.startDate}>
        <span>{displayDate(p.data.start_date)}</span>
      </div>
    ),
  },
  {
    field: 'ratio',
    headerName: t('Status'),
    flex: 3,
    minWidth: 130,
    sortable: false,
    cellRenderer: (p: ICellRendererParams) => (
      <span className={classNames(css[`date-${getColor(p.data.start_date, p.data.end_date)}`], css.dateDesc)}>
        {displayStartDate(p.data.start_date, p.data.end_date)}
      </span>
    ),
  },
  {
    field: 'bi',
    headerName: t('Buy-in'),
    flex: 1,
    cellClass: css.paled,
    cellRenderer: (p: ICellRendererParams) => p.data?.bi && money(Number(p.data?.bi), p.data?.currency || ''),
  },
  {
    field: 'rank',
    headerName: t('Rank'),
    maxWidth: 72,
    flex: 1,
  },
  {
    field: 'end_date',
    headerName: t('Finish'),
    flex: 3,
    minWidth: 106,
    maxWidth: 150,
    valueGetter: (p: ValueGetterParams<ITournament, number>) => p.data?.end_date && displayDate(p.data.end_date),
  },
  {
    field: 'type',
    headerName: t('Type'),
    maxWidth: 96,
    flex: 1,
    cellRenderer: (p: ICellRendererParams) => <div className={css.typeIcon}>{renderIconType(p.data.type)}</div>,
    sortable: false,
    cellClass: css.paled,
  },
  {
    field: 'vinrate',
    headerName: t('Winrate'),
    minWidth: 102,
    flex: 1,
  },
  {
    field: 'fishes',
    headerName: t('Fishes'),
    flex: 1,
  },
  {
    field: 'afs',
    headerName: t('AFS'),
    maxWidth: 76,
    flex: 1,
  },
  {
    field: 'tourney_dollar',
    headerName: t('$/tour'),
    maxWidth: 76,
    flex: 1,
  },
  {
    field: 'tournament',
    headerName: t('About tourney'),
    minWidth: 118,
    flex: 1,
  },
  {
    field: 'actions',
    headerName: '',
    flex: 1,
    cellRenderer: (p: ICellRendererParams) => <TournamentRowActions p={p} />,
    cellClass: css.noOutline,
  },
];

export default prepareTournamentColumns;
