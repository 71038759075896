import { forwardRef } from 'react';
import { styled } from 'styled-components';
import { generateResponsiveStyles } from '@/theme/resources';
import { Box, BoxStyledProps } from '../box';

const StyledContentHeader = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: theme.spacing[11],
  padding: `${theme.spacing[2]} ${theme.spacing[6]}`,
  marginBottom: theme.spacing[1],
  borderBottom: `1px solid ${theme.color['gray-600']}`,
  color: theme.color['gray-100'],
  ...theme.font.md,
  fontFamily: theme.fontFamily.primary,
  fontWeight: 500,
}));

export const ContentHeader = forwardRef<HTMLDivElement, React.ComponentProps<typeof StyledContentHeader>>(
  (props, ref) => {
    const { children, ...rest } = props;
    return (
      <StyledContentHeader ref={ref} {...rest}>
        {typeof children === 'string' ? <span>{children}</span> : children}
      </StyledContentHeader>
    );
  },
);

export const ContentBody = styled(Box)``;
ContentBody.defaultProps = {
  $px: 6,
};

export const ContentFooter = styled.div<BoxStyledProps>(({ theme, ...rest }) => {
  const responsiveStyles = generateResponsiveStyles(theme, rest);
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: theme.spacing[12],
    padding: `${theme.spacing[2]} ${theme.spacing[6]}`,
    marginTop: theme.spacing[2],
    borderTop: `1px solid ${theme.color['gray-600']}`,
    color: theme.color['gray-100'],
    ...theme.font.md,
    ...responsiveStyles,
  };
});

export const ContentFooterButton = styled.button.attrs({ type: 'button' })<BoxStyledProps>(({ theme }) => ({
  cursor: 'pointer',
  width: '100%',
  height: theme.spacing[12],
  padding: `0 ${theme.spacing[6]}`,
  border: 0,
  borderRadius: `0 0 ${theme.radius.md} ${theme.radius.md}`,
  transition: `all 0.3s ${theme.easing['ease-out-quart']}`,
  backgroundColor: 'transparent',
  color: theme.color['accent-200'],
  fontFamily: theme.fontFamily.primary,
  fontWeight: 500,
  ...theme.font.sm,
  '&:hover': {
    backgroundColor: theme.color['neutral-700'],
  },
}));
