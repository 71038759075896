import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconEdit = (props: IconProps) => {
  return (
    <Icon id="IconEdit" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3734 3.58915C15.0766 2.88589 16.0305 2.4908 17.025 2.4908C18.0196 2.4908 18.9734 2.88589 19.6767 3.58915C20.3799 4.29242 20.775 5.24624 20.775 6.2408C20.775 7.23537 20.3799 8.18919 19.6767 8.89245L8.53975 20.0294C8.3991 20.17 8.20833 20.2491 8.00942 20.2491L3.76678 20.2491C3.35256 20.2491 3.01678 19.9133 3.01678 19.4991L3.01677 15.2564C3.01677 15.0575 3.09579 14.8667 3.23644 14.7261L14.3734 3.58915ZM17.025 3.9908C17.6218 3.9908 18.1941 4.22786 18.616 4.64981C19.038 5.07177 19.275 5.64407 19.275 6.2408C19.275 6.57958 19.1986 6.91049 19.0554 7.21047L16.0554 4.21047C16.3553 4.06721 16.6862 3.9908 17.025 3.9908ZM4.51678 18.7491L4.51677 15.5671L14.9037 5.18015L18.0857 8.36213L7.69876 18.7491L4.51678 18.7491Z"
      />
    </Icon>
  );
};

export default IconEdit;
