import React, { FC } from 'react';
import CrossRatesPanel from '@/__old/components/Finances/Balance/CrossRatesPanel';
import FinanceHeading from '@/__old/components/Finances/FinanceHeading';
import { Column } from '@funfarm/kit/Column';
import FinanceMetrics from '../FinanceMetrics';
import BalancePanel from './BalancePanel';
import css from '../finances.module.scss';

const FinanceBalance: FC = () => {
  return (
    <>
      <FinanceHeading />
      <div className={css.financesGrid}>
        <Column className={css.balancePanel} gap={2}>
          <BalancePanel />
          <CrossRatesPanel />
        </Column>
        <FinanceMetrics />
      </div>
    </>
  );
};

export default FinanceBalance;
