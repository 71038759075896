import { forwardRef } from 'react';
import { styled } from 'styled-components';
import { useInputId } from '@/shared/hooks';
import { filterStyledProps } from '@/theme/utils';
import { BoxStyledProps, HStack } from '../box';
import { truncatedStyles } from '../text/text.styles';
import { Switch, SwitchProps } from './switch';

const StyledFormControl = styled(HStack)(({ theme }) => ({
  cursor: 'pointer',
  userSelect: 'none',
  overflow: 'hidden', // required for text truncating
  '&[data-disabled]': {
    cursor: 'default !important',
    '& > span': {
      color: theme.color['gray-400'],
    },
  },
  '&:not([data-disabled]):hover': {
    '& > span': {
      color: theme.color.white,
    },
  },
}));

const StyledLabelText = styled.span(({ theme }) => ({
  flex: 'auto',
  fontFamily: theme.fontFamily.primary,
  fontWeight: 400,
  color: theme.color['gray-100'],
  ...theme.font.md,
  ...truncatedStyles,
  transition: `color 0.15s ${theme.easing['ease-in-quart']}`,
}));

interface SwitchFormControlProps extends Omit<SwitchProps, 'onChange' | 'checked' | 'value'>, BoxStyledProps {
  label?: string;
  value?: boolean;
  onChange?: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SwitchFormControl = forwardRef<HTMLInputElement, SwitchFormControlProps>((props, ref) => {
  const { label, className, value, onChange, ...rest } = props;

  const id = useInputId(props);

  const boxStyledProps = filterStyledProps(rest);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange?.(!value, event);

  return (
    <StyledFormControl
      as="label"
      htmlFor={id}
      className={className}
      tabIndex={0}
      data-disabled={props.disabled}
      {...boxStyledProps}
    >
      {!!label && <StyledLabelText children={label} />}
      <Switch ref={ref} id={id} onChange={handleChange} checked={value} {...rest} />
    </StyledFormControl>
  );
});
