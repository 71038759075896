import React from 'react';
import { Outlet } from 'react-router-dom';
import { TableColumnsProvider } from '@/features/tournaments/components/table-columns';
import { TourProvider } from './TourProvider';
import { TournamentsHeader } from './TournamentsHeader';

export const Tournaments = () => {
  return (
    <TableColumnsProvider>
      <TourProvider palette={{}} paletteData={[]}>
        <TournamentsHeader />
        <Outlet />
      </TourProvider>
    </TableColumnsProvider>
  );
};
