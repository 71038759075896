export const colorTemperature = (
  num: number,
  avg: number,
  precision: number,
): -1 | 0 | 1 => {
  const deviation = (num / avg - 1) * 100;

  if (Math.abs(deviation) < precision) {
    return 0;
  }

  if (deviation > 0) {
    return 1;
  }

  return -1;
};
