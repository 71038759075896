import { useCallback, useMemo, useState } from 'react';
import { Tournament } from '@/features/tournaments/models';
import { useSynchronizedRef } from '@/shared/hooks';
import { isObjectWithKeys } from '@/shared/utils/object';
import { TournamentSelectionActions } from './tournament-selection-actions';
import {
  TournamentSelectionContext,
  TournamentSelectionContextValue,
  TournamentType,
} from './tournament-selection.context';

type TournamentSelectionState = {
  tournamentType: TournamentType;
  selectedTournamentsMap: Record<string, Tournament>;
};

export const TournamentSelection = ({ children }: React.PropsWithChildren) => {
  const [state, setState] = useState<TournamentSelectionState>({
    tournamentType: 'all',
    selectedTournamentsMap: {},
  });

  const stateRef = useSynchronizedRef(state);

  const toggleTournament = useCallback((tournament: Tournament, type: TournamentType) => {
    const isChecked = !!stateRef.current.selectedTournamentsMap[tournament.id];

    if (isChecked)
      setState(({ tournamentType, selectedTournamentsMap: selectedTournaments }) => {
        const newSelectedTournamentsMap = { ...selectedTournaments };
        delete newSelectedTournamentsMap[tournament.id];
        return {
          tournamentType,
          selectedTournamentsMap: newSelectedTournamentsMap,
        };
      });
    else
      setState(({ tournamentType, selectedTournamentsMap }) => {
        if (tournamentType !== type)
          return {
            tournamentType: type,
            selectedTournamentsMap: {
              [tournament.id]: tournament,
            },
          };
        return {
          tournamentType,
          selectedTournamentsMap: {
            ...selectedTournamentsMap,
            [tournament.id]: tournament,
          },
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectAllTournaments = useCallback((tournaments: Tournament[], type: TournamentType) => {
    setState(() => ({
      tournamentType: type,
      selectedTournamentsMap: tournaments.reduce(
        (acc, tournament) => {
          acc[tournament.id] = tournament;
          return acc;
        },
        {} as Record<string, Tournament>,
      ),
    }));
  }, []);

  const value: TournamentSelectionContextValue = useMemo(
    () => ({
      selectedTournamentType: state.tournamentType,
      selectedTournamentMap: state.selectedTournamentsMap,
      toggleTournament,
      selectAllTournaments,
    }),
    [state, selectAllTournaments, toggleTournament],
  );

  const isOpen = isObjectWithKeys(state.selectedTournamentsMap);

  const resetTournaments = () => setState({ tournamentType: state.tournamentType, selectedTournamentsMap: {} });

  return (
    <TournamentSelectionContext.Provider value={value}>
      {children}
      <TournamentSelectionActions
        isOpen={isOpen}
        resetSelectedTournaments={resetTournaments}
        tournamentType={state.tournamentType}
        selectedTournamentsMap={state.selectedTournamentsMap}
      />
    </TournamentSelectionContext.Provider>
  );
};
