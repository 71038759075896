import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import css from './uploader.module.scss';

interface IProps {
  onFileUpload?: (
    data: { filename: string; file: File; fileContent: string } | null,
  ) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  label?: string;
  showFileName?: boolean;
  disabled?: boolean;
  icon?: ReactElement;
  parseFileContent?: boolean;
  multiple?: boolean;
}

export const Uploader = (props: IProps) => {
  const {
    accept,
    showFileName = true,
    label = 'Choose file',
    disabled,
    icon,
    multiple,
    parseFileContent,
    onChange,
    onFileUpload,
  } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [fileName, setFileName] = useState<string>('');

  useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setFileName('');
    }
  }, [disabled]);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    const file = event.target.files && event.target.files[0];

    if (file) {
      setFileName(file.name);

      if (parseFileContent) {
        if (!onFileUpload) return;

        const reader = new FileReader();

        reader.onload = (e: ProgressEvent<FileReader>) => {
          const fileContent = e.target?.result as string;
          try {
            onFileUpload({ filename: file.name, file, fileContent });
          } catch (error) {
            console.error('Error parsing file:', error);
            onFileUpload(null);
          }
        };

        reader.readAsText(file);
      } else onChange && onChange(event);
    }
  };

  return (
    <div className={css.uploaderContainer}>
      <label
        className={classNames(css.uploaderButton, disabled && css.disabled)}
      >
        {icon &&
          React.cloneElement(icon, {
            className: classNames(icon.props.className, css.iconLeft),
          })}
        {label}
        <input
          className={css.uploaderInput}
          type="file"
          accept={accept}
          multiple={multiple}
          onChange={handleFileUpload}
          disabled={disabled}
          ref={fileInputRef}
        />
      </label>
      {fileName && showFileName && (
        <span className={css.uploadedName}>{fileName}</span>
      )}
    </div>
  );
};
