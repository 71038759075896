import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { createWithdrawalRequest } from '@/__old/actions/finances';
import OperationTypeSelect from '@/__old/components/Finances/TransfersHistory/OperationTypeSelect';
import css from '@/__old/components/Finances/finances.module.scss';
import AccountSelect from '@/__old/components/Select/AccountSelect';
import useBalancesAndBrm from '@/__old/hooks/useBalancesAndBrm';
import useFinanceRoom from '@/__old/hooks/useFinanceRoom';
import { FinanceRoom } from '@/__old/types/finances';
import { Button, Error, IError, Input, Row } from '@funfarm/kit';
import MoneyInput from '@funfarm/kit/Input/MoneyInput';

type AddOperationProps = {
  onClose: () => void;
  onSuccess?: () => void;
};

const AddOperation: FC<AddOperationProps> = ({ onClose, onSuccess }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { refetch } = useBalancesAndBrm();
  const accountId = watch('account');
  const accountDetails = useFinanceRoom(accountId);

  return (
    <form
      onSubmit={handleSubmit((formData) => {
        setError(undefined);
        // const balance = getRoomDetails(Number(formData.account))?.balance_curr;
        // if (balance && formData.operationType !== 1 && Number(formData.amount) > balance) {
        //     setError("Недостаточно средств");
        //     return;
        // }
        setSubmitted(true);
        createWithdrawalRequest(
          formData.account,
          Number(formData.amount),
          formData.operationType,
          formData.comment,
        )
          .then(() => {
            refetch();
            onSuccess && onSuccess();
            onClose();
          })
          .catch((error: IError) => {
            const typedError = error as { detail?: { message: string } };
            if (typedError.detail?.message) {
              setError(typedError.detail.message);
            }
          })
          .finally(() => setSubmitted(false));
      })}
      noValidate
    >
      {error && <Error message={error} view="box" />}
      <AccountSelect
        control={control}
        filterFunction={(room: FinanceRoom) => !!room.allowwithdraw}
      />
      <OperationTypeSelect control={control} />
      <Controller
        control={control}
        name="amount"
        render={({ field }) => (
          <MoneyInput
            {...field}
            label="Сумма"
            placeholder="Введите нужную сумму"
            currency={accountDetails?.currency || ''}
            onInput={() => setError(undefined)}
            error={!!errors.amount}
            allowNegatives
            required
          />
        )}
      />
      <Controller
        control={control}
        name="comment"
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Можете дополнить ваш запрос комментарием"
            // labelPosition="inside"
          />
        )}
      />
      {/*<div className={css.dropzone}>*/}
      {/*    <UploaderArea*/}
      {/*        label="Переместите скриншот или нажмите для выбора"*/}
      {/*    />*/}
      {/*</div>*/}
      <Row className={css.formButtons}>
        <Button
          color="default"
          label="Отмена"
          loading={submitted}
          onClick={onClose}
        />
        <Button
          color="secondary"
          label="Добавить"
          type="submit"
          loading={submitted}
          disabled={!isValid}
        />
      </Row>
    </form>
  );
};

export default AddOperation;
