import {
  type ChangeEvent,
  type ChangeEventHandler,
  type ForwardedRef,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Icon,
  Label,
} from '@/shared/components';
import { IconSpinner } from '@funfarm/kit';
import type { Option } from './multi-select.types';
import { getAppliedOptions } from './multi-select.utils';
import styles from './multi-select.module.scss';

type Props = {
  options: Option[];
  placeholder?: string;
  value?: Option['value'][] | string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  isLoading?: boolean;
  width?: number;
  fullWidth?: boolean;
  label?: string;
  contentClassName?: string;
  asModal?: boolean;
};

export const MultiSelect = forwardRef(
  (
    {
      options,
      placeholder,
      onChange,
      isLoading,
      width,
      fullWidth,
      label,
      contentClassName,
      asModal = false,
      ...props
    }: Props,
    _ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { t } = useTranslation();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [allOptions, setAllOptions] = useState<Option[]>(options || []);

    const value = props.value ? props.value.toString() : '';

    useEffect(() => {
      setAllOptions(
        options.map((option) => ({
          ...option,
          checked: getAppliedOptions(value).includes(option.value.toString()),
        })),
      );
    }, [value, options]);

    const checkOptionHandler = (checked: boolean, option: Option) => {
      setAllOptions(allOptions.map((el) => (el.value === option.value ? { ...el, checked } : el)));
    };

    const handleDropdown = (isOpen: boolean) => {
      if (!isOpen) {
        onChange?.({
          target: {
            value: String(
              allOptions.reduce(
                (res, el) => {
                  if (el.checked) res.push(el.value);
                  return res;
                },
                [] as Option['value'][],
              ),
            ),
          },
        } as ChangeEvent<HTMLInputElement>);
      }
      setIsDropdownOpen(isOpen);
    };

    const optionsTotalCount = options.length;
    const checkedOptionsCount = getAppliedOptions(value).length;

    return (
      <div className={styles.filtersContainer}>
        <Label label={label} />
        <DropdownMenu onOpenChange={handleDropdown} modal={asModal}>
          <DropdownMenuTrigger asChild>
            <div
              className={clsx(styles.trigger, !!checkedOptionsCount && styles.activeTrigger)}
              style={{ minWidth: fullWidth ? '100%' : `${width}px` }}
            >
              {!checkedOptionsCount
                ? placeholder
                : optionsTotalCount === checkedOptionsCount
                  ? `${t('all')} (${optionsTotalCount})`
                  : `${checkedOptionsCount} ${t('selected', { count: checkedOptionsCount })}`}
              {isLoading ? (
                <IconSpinner className={clsx('spin', styles.chevron)} />
              ) : (
                <Icon name={isDropdownOpen ? 'cheveron-up' : 'cheveron-down'} $size={16} $color="gray-400" />
              )}
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className={contentClassName}>
            {allOptions.map((option) => (
              <DropdownMenuCheckboxItem
                key={option.value}
                checked={option.checked}
                onCheckedChange={(e) => checkOptionHandler(e, option)}
                children={option.label}
              />
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  },
);
