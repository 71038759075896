import React, {
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  useCallback,
  useRef,
} from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import i18n from 'i18next';

import { ELabelPosition, IconCalendar, Input, localeMap } from '@funfarm/kit';

import 'react-datepicker/dist/react-datepicker.css';
import './date.scss';

interface IProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  value: [Date | undefined, Date | undefined];
  label?: string;
  labelPosition?: keyof typeof ELabelPosition;
  onChange?: (value: [Date | undefined, Date | undefined]) => void;
  onFocus?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  onInput?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  error?: string | boolean;
  displayError?: boolean;
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  dateFormat?: string;
  maxDate?: Date;
  minDate?: Date;
  shouldCloseOnSelect?: boolean;
}

export const InputDateRange = forwardRef(
  (props: IProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      onChange,
      dateFormat = 'dd.MM.yyyy',
      value,
      minDate,
      maxDate,
      shouldCloseOnSelect = false,
      ...rest
    } = props;

    const lng = i18n.language.slice(0, 2);
    const selectedLocale = localeMap[lng] ?? localeMap['en'];

    registerLocale('locale', selectedLocale);

    const calendarRef = useRef<DatePicker>(null);

    const handleChange = useCallback(
      (input: unknown) => {
        onChange &&
          onChange((input as [Date | undefined, Date | undefined]) ?? null);
      },
      [onChange],
    );

    return (
      <>
        <DatePicker
          selected={value ? value[0] : undefined}
          startDate={value ? value[0] : undefined}
          endDate={value ? value[1] : undefined}
          selectsRange
          ref={calendarRef}
          locale="locale" // from i18n
          required={props.required}
          readOnly={props.readonly}
          closeOnScroll={false}
          {...{ dateFormat, minDate, maxDate }}
          onChange={handleChange}
          shouldCloseOnSelect={shouldCloseOnSelect}
          wrapperClassName="react-datepicker-wrapper"
          calendarClassName="custom-calendar"
          className="custom-datepicker"
          customInput={
            <Input
              ref={ref}
              iconRight={
                <IconCalendar
                  onClick={() =>
                    calendarRef.current?.setOpen(
                      !calendarRef.current.isCalendarOpen(),
                    )
                  }
                />
              }
              // value={value[0]}
              {...rest}
            />
          }
        />
      </>
    );
  },
);
