import { forwardRef, lazy, Suspense } from 'react';
import { type Color, type FontSize, type ResponsiveStyle, styled } from 'styled-components';
import { applyResponsiveFonts } from '@/theme/resources';
import { parsePaletteColor } from '@/theme/utils';

export type IconName =
  | 'briefcase'
  | 'calendar'
  | 'check'
  | 'cheveron-down'
  | 'cheveron-left'
  | 'cheveron-up'
  | 'clipboard-list'
  | 'clock'
  | 'collection'
  | 'copy'
  | 'hyphen'
  | 'knockout'
  | 'lightning-bolt'
  | 'lock'
  | 'mail-open'
  | 'microphone'
  | 'pencil'
  | 'photograph'
  | 'play'
  | 'printer'
  | 'shield'
  | 'three-columns'
  | 'palette'
  | 'question-mark'
  | 'v-dots'
  | 'plus'
  | 'minus'
  | 'spinner'
  | 'six'
  | 'stairs'
  | 'treasure'
  | 'turbo'
  | 'hyper-turbo'
  | 'eye'
  | 'eye-off';

const iconMap: Record<IconName, React.LazyExoticComponent<IconComponent>> = {
  briefcase: lazy(() => import('./icons/briefcase.svg?react')),
  calendar: lazy(() => import('./icons/calendar.svg?react')),
  check: lazy(() => import('./icons/check.svg?react')),
  'cheveron-down': lazy(() => import('./icons/cheveron-down.svg?react')),
  'cheveron-left': lazy(() => import('./icons/cheveron-left.svg?react')),
  'cheveron-up': lazy(() => import('./icons/cheveron-up.svg?react')),
  'clipboard-list': lazy(() => import('./icons/clipboard-list.svg?react')),
  clock: lazy(() => import('./icons/clock.svg?react')),
  collection: lazy(() => import('./icons/collection.svg?react')),
  copy: lazy(() => import('./icons/copy.svg?react')),
  hyphen: lazy(() => import('./icons/hyphen.svg?react')),
  knockout: lazy(() => import('./icons/knockout.svg?react')),
  'lightning-bolt': lazy(() => import('./icons/lightning-bolt.svg?react')),
  lock: lazy(() => import('./icons/lock.svg?react')),
  'mail-open': lazy(() => import('./icons/mail-open.svg?react')),
  microphone: lazy(() => import('./icons/microphone.svg?react')),
  pencil: lazy(() => import('./icons/pencil.svg?react')),
  photograph: lazy(() => import('./icons/photograph.svg?react')),
  play: lazy(() => import('./icons/play.svg?react')),
  printer: lazy(() => import('./icons/printer.svg?react')),
  shield: lazy(() => import('./icons/shield.svg?react')),
  'three-columns': lazy(() => import('./icons/three-columns.svg?react')),
  palette: lazy(() => import('./icons/palette.svg?react')),
  'question-mark': lazy(() => import('./icons/question-mark.svg?react')),
  'v-dots': lazy(() => import('./icons/dots-vertical.svg?react')),
  plus: lazy(() => import('./icons/plus.svg?react')),
  minus: lazy(() => import('./icons/minus.svg?react')),
  spinner: lazy(() => import('./icons/spinner.svg?react')),
  six: lazy(() => import('./icons/six.svg?react')),
  stairs: lazy(() => import('./icons/stairs.svg?react')),
  treasure: lazy(() => import('./icons/treasure.svg?react')),
  turbo: lazy(() => import('./icons/turbo.svg?react')),
  'hyper-turbo': lazy(() => import('./icons/hyper-turbo.svg?react')),
  eye: lazy(() => import('./icons/eye.svg?react')),
  'eye-off': lazy(() => import('./icons/eye-off.svg?react')),
} as const;

const StyledIcon = styled.svg<{
  $size?: ResponsiveStyle<FontSize | string | number>;
  $color?: Color | 'transparent';
}>(({ theme, $size, $color }) => {
  const color = parsePaletteColor($color, theme);
  const styles = {
    color,
    flex: 'none',
    // transition: 'color 0.2s',
  };
  if ($size) applyResponsiveFonts(styles, $size, theme);
  return styles;
});

const StyledIconLoader = styled.span<React.ComponentProps<typeof StyledIcon>>(({ theme, $size }) => {
  const styles = {
    display: 'block',
    width: '1em',
    height: '1em',
    flex: 'none',
  };
  if ($size) applyResponsiveFonts(styles, $size, theme);
  return styles;
});

export interface IconProps extends Omit<React.ComponentProps<typeof StyledIcon>, 'as' | 'ref'> {
  name: keyof typeof iconMap;
}

export const Icon = forwardRef<SVGSVGElement, IconProps>(({ name, ...rest }, ref) => {
  const IconComponent = iconMap[name];
  return (
    <Suspense fallback={<StyledIconLoader {...rest} />}>
      <StyledIcon ref={ref} as={IconComponent} {...rest} />
    </Suspense>
  );
});
