import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface IProps {
  className?: string;
  children: ReactNode;
  gap?: number;
  spaceBetween?: boolean;
  align?: 'center' | 'start' | 'end';
}

export const Column = (props: IProps) => {
  const { gap, className, spaceBetween, align } = props;

  const gapClassName = gap ? `gap${gap}` : '';

  return (
    <div
      className={classNames(
        'column',
        gapClassName,
        className,
        spaceBetween && 'space-between',
        align && `align-${align}`,
      )}
    >
      {props.children}
    </div>
  );
};
