import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconSuccess = (props: IconProps) => {
  return (
    <Icon id="IconSuccess" {...props}>
      <path d="M16.7353 9.93198C17.0282 9.63909 17.0282 9.16421 16.7353 8.87132C16.4424 8.57843 15.9675 8.57843 15.6746 8.87132L10.9017 13.6443L8.78033 11.523C8.48744 11.2301 8.01256 11.2301 7.71967 11.523C7.42678 11.8159 7.42678 12.2907 7.71967 12.5836L10.3713 15.2353C10.6642 15.5282 11.1391 15.5282 11.432 15.2353L16.7353 9.93198Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25C7.44365 20.25 3.75 16.5563 3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12Z"
      />
    </Icon>
  );
};
