import { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { styled } from 'styled-components';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useDndContext,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { DndListContext, DndListContextValue } from './dnd-list-context';
import { DndItemData } from './dnd-list.types';

const StyledDndListWrapper = styled.div({
  /** fix issue with cursor and react portal */
  '& [data-dnd-activator]': {
    cursor: 'grab',
  },
});

const DndListAssembler = ({ children }: React.PropsWithChildren) => {
  const context = useDndContext();
  const { active, dragOverlay } = context;

  const activeElementIndex = (active?.data.current as DndItemData)?.index;

  const value: DndListContextValue = useMemo(
    () => ({ dragOverlayHeight: dragOverlay.rect?.height }),
    [dragOverlay.rect?.height],
  );

  return (
    <DndListContext.Provider value={value}>
      <StyledDndListWrapper>{children}</StyledDndListWrapper>

      {/* portal is used to avoid issues with modals and popovers */}

      {createPortal(
        <DragOverlay zIndex={9999} style={{ cursor: 'grabbing' }}>
          {Array.isArray(children) && activeElementIndex !== undefined && children[activeElementIndex]}
        </DragOverlay>,
        document.body,
      )}
    </DndListContext.Provider>
  );
};

interface Props {
  children: React.ReactElement[];
  onDragEnd?: (event: DragEndEvent) => void;
}

export const DndList = ({ children, onDragEnd }: Props) => {
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  return (
    <DndContext onDragEnd={onDragEnd} sensors={sensors}>
      <DndListAssembler>{children}</DndListAssembler>
    </DndContext>
  );
};
