import React, { ReactElement } from 'react';
import classNames from 'classnames';

import type { IError } from '@funfarm/kit';

import css from './error.module.scss';

interface IProps extends IError {
  layout?: boolean;
  view?: string;
  button?: ReactElement;
}

export const Error = (props: IProps) => {
  const {
    code,
    status,
    statusText,
    message,
    layout,
    view = '',
    button,
  } = props;

  return (
    <div className={classNames(css.container, layout && css.layout, css[view])}>
      <div className={css.error}>
        {status ? (
          <h1>
            {status} {statusText}
          </h1>
        ) : code ? (
          <h1>{code}</h1>
        ) : null}
        <p>{message ?? statusText ?? null}</p>
        {!!button && <div className={css.buttons}>{button}</div>}
      </div>
    </div>
  );
};
