import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from '@/shared/components';
import styles from './EducationCardButtons.module.scss';

type Props = {
  link: string;
  is_available: boolean;
  is_done: boolean;
  tasks_done: number;
  number: number;
  progress: number;
};

export const EducationCardButtons = (props: Props) => {
  const { link, is_available, is_done, tasks_done, number, progress } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const isItemDone = is_available && is_done;
  const isItemReadyToStart = is_available && !is_done && !tasks_done;
  const isItemInProgress = is_available && !is_done && tasks_done;

  const goToLink = () => navigate(link);

  return (
    <div className={styles.itemButtons}>
      {isItemDone && (
        <>
          <Button $variant="outline" $fullWidth onClick={goToLink}>
            {t('education.repeat')}
          </Button>
          <div className={styles.badge}>
            <Icon name="shield" />
            {t('education.passed')}
          </div>
        </>
      )}
      {isItemReadyToStart && (
        <Button $fullWidth onClick={goToLink}>
          {t('education.startLearning')}
        </Button>
      )}
      {!!isItemInProgress && (
        <>
          <Button $variant="outline" $fullWidth onClick={goToLink}>
            {t('education.continue')}
          </Button>
          <div className={styles.badge}>{`${progress}% ${t('education.passed').toLowerCase()}`}</div>
        </>
      )}
      {!is_available && (
        <Button $variant="outline" $fullWidth disabled>
          <Icon name="lock" />
          {t('education.goThroughTheChapter')} {number - 1}
        </Button>
      )}
    </div>
  );
};
