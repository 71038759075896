import React from 'react';
import { getSummary } from '@/__old/actions/index';
import { formatPeriod } from '@/__old/helpers/formatDates';
import { IInfoSummary } from '@/__old/types/index';
import { IError } from '@funfarm/kit';
import { useQuery } from '@tanstack/react-query';
import { Indicators } from './Indicators';
import { Preflop } from './Preflop';
import { Video } from './Video';
import css from './statistic.module.scss';

export const Statistic = () => {
  const { data } = useQuery<IInfoSummary, IError>({
    queryKey: ['info', 'summary'],
    queryFn: getSummary,
  });

  return (
    <>
      {data?.first_hh_date && data?.last_hh_date && (
        <div className={css.statsPeriod}>
          {formatPeriod(data.first_hh_date, data.last_hh_date)}
        </div>
      )}
      <div className={css.pageGrid}>
        <Indicators />

        <Preflop />

        <Preflop />

        <Video />
      </div>
    </>
  );
};
