import { useNavigate } from 'react-router-dom';
import { Icon } from '@/shared/components';
import styles from './TitleWithBackButton.module.scss';

type Props = {
  title: string;
  link?: string;
  withoutButton?: boolean;
};

export const TitleWithBackButton = ({ title, link, withoutButton }: Props) => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      {!withoutButton && (
        <div onClick={() => navigate(link || '#')} className={styles.backButton}>
          <Icon name="cheveron-left" $color="gray-200" />
        </div>
      )}
      <div className={styles.title}>{title}</div>
    </div>
  );
};
