export enum ETableSize {
  total = 'All',
  '2-max' = '2-max',
  '3-max' = '3-max',
  '6-max' = '6 max',
  '9-max' = '9 max',
}

export enum ERooms {
  ALL = 'All',
  GG = 'GG',
  PS = 'PS',
  BMX = 'WMX',
  OTHER = 'Other',
}

export enum EPeriod {
  alltime = 'All',
  current_year = 'Current year',
  prev_year = 'Prev year',
  current_month = 'Current month',
  prev_month = 'Prev month',
  '3m' = '3 months',
  '6m' = '6 months',
}

export enum ENetwork {
  '888Poker' = '888Poker',
  'Chico' = 'Chico',
  'CoinPoker' = 'CoinPoker',
  'GGNetwork' = 'GGNetwork',
  'PPPoker' = 'PPPoker',
  'PokerBros' = 'PokerBros',
  'PokerStars' = 'PokerStars',
  'PokerStars(FR-ES-PT)' = 'PokerStars(FR-ES-PT)',
  'WPN' = 'WPN',
  'Winamax.fr' = 'Winamax.fr',
  'iPoker' = 'iPoker',
}

export enum ETourneySpeed {
  'Hyper' = 'Hyper',
  'Regular' = 'Regular',
  'Turbo' = 'Turbo',
}

export enum ETourneyType {
  'Freeze' = 'Freeze',
  'PKO' = 'PKO',
}

export enum EBlindLevelGroup {
  '1-9',
  '10+',
}

export enum EStackSize {
  '0-15',
  '15-35',
  '35-70',
  '70+',
}
