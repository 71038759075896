import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { EducationStep } from '@/features/courses/models';
import { Button, Icon, type IconName } from '@/shared/components';
import styles from './StepItem.module.scss';

const icons: Record<
  string,
  Extract<
    IconName,
    | 'briefcase'
    | 'check'
    | 'clipboard-list'
    | 'collection'
    | 'knockout'
    | 'lightning-bolt'
    | 'mail-open'
    | 'microphone'
    | 'pencil'
    | 'photograph'
    | 'play'
    | 'printer'
  >
> = {
  image: 'photograph',
  instruction: 'briefcase',
  call: 'microphone',
  message: 'mail-open',
  memo: 'printer',
  checklist: 'check',
  test: 'clipboard-list',
  video: 'play',
  questionnaire: 'collection',
  simulator: 'knockout',
  practice: 'lightning-bolt',
  synopsis: 'pencil',
};

type Props = {
  link: string;
  step: EducationStep;
};

export const StepItem = ({ link, step }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToLink = () => navigate(link);

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.number}>{step.number}</div>
        <div className={styles.title}>{step.title}</div>
        <div className={styles.typeIcon}>
          <Icon name={icons[step.type]} />
        </div>
      </div>
      <div className={styles.description}>{step.description}</div>
      <div className={styles.footer}>
        {step.is_done && (
          <>
            <Button $variant="outline" onClick={goToLink}>
              {t('education.repeat')}
            </Button>
            <div className={styles.badge}>
              <Icon name="shield" />
              {t('education.passed')}
            </div>
          </>
        )}
        {!step.is_done && step.is_available && <Button onClick={goToLink}>{t('education.startTheTask')}</Button>}
        {!step.is_available && (
          <Button $variant="outline" disabled>
            <Icon name="lock" />
            {t('education.goThroughTheStep')} {step.number - 1}
          </Button>
        )}
      </div>
    </div>
  );
};
