import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { env } from 'env';
import type { IError, IErrorData } from '@funfarm/kit';

const ApiInstance = (baseURL?: string, conf?: Partial<AxiosRequestConfig>): AxiosInstance => {
  const _API = axios.create({
    baseURL: baseURL ?? (env.FF_API_URL.endsWith('api') ? env.FF_API_URL : `${env.FF_API_URL}/api`),
    responseType: 'json',
    withCredentials: true,
    timeout: 1000 * 30,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache, no-store, must-revalidate, max-age=40000',
    },
    ...conf,
  });

  _API.interceptors.request.use((request) => {
    // let q = 1;
    // const [current, ...languages] = i18n.languages;
    //
    // request.headers['Accept-Language'] = `${current},${languages.map(lng => {
    //     q = q - 0.1;
    //     return `${lng};q=${q}`;
    // }).join(',')}`;

    return request;
  });

  _API.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error: AxiosError<IErrorData>) => {
      let errorData: IError = {
        code: error.code,
        message: error.message,
      };

      if (['ECONNABORTED', 'ERR_NETWORK'].includes(errorData.code as string))
        errorData.message = 'Something wrong with a network access, try again';

      if (error.response) {
        if ([401, 403].includes(error.response.status)) {
          // window.location.reload();
        }

        errorData.status = error.response.status;
        errorData.statusText = error.response.statusText;

        if (error.response.data) {
          errorData = { ...errorData, ...error.response.data };

          if (error.response.data.detail) {
            if (Array.isArray(error.response.data.detail))
              errorData.message = `${error.response.data.detail[0].loc[1]} ${error.response.data.detail[0].msg}`;
            else errorData.message = error.response.data.detail;
          }
        }
      }

      if (import.meta.env.NODE_ENV === 'development') console.error('API errorData', errorData);

      return Promise.reject<IError>(errorData);
    },
  );

  return _API;
};

const API = ApiInstance();

export { ApiInstance, API };
