import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getFilters } from '@/__old/actions';
import { defaultFilterParams, TourContext } from '@/__old/components/Tournaments/TourProvider';
import type { IFilterTemplate } from '@/__old/types';
import { type IError, Tabs } from '@funfarm/kit';
import { useQuery } from '@tanstack/react-query';
import { TemplateModal } from './TemplateModal';
import styles from './templates.module.scss';

export const FilterTemplates = () => {
  const { t } = useTranslation();
  const { setFilterParams } = useContext(TourContext);

  const { data } = useQuery<any, IError>({
    queryKey: ['filters'],
    queryFn: getFilters,
  });

  const setDefaultTemplate = () => {
    setFilterParams?.(defaultFilterParams);
  };

  return (
    <Tabs className={styles.tabs} tabChecked="all">
      <Tabs.List className={styles.tabsList}>
        <Tabs.Header index="default" onClick={setDefaultTemplate} className={styles.tabHeader}>
          <div onClick={setDefaultTemplate}>{t('All filters')}</div>
        </Tabs.Header>
        {data?.map((template: IFilterTemplate) => (
          <TemplateModal
            key={template.id}
            template={{ ...template, ...template.value }}
            editIconClassName={styles.editIcon}
          />
        ))}
        <TemplateModal />
      </Tabs.List>
    </Tabs>
  );
};
