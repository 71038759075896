import { createContext, useContext } from 'react';
import { TournamentTableColumn } from './table-columns.resources';

export interface ConfigurableColumn {
  name: TournamentTableColumn;
  index: number;
  checked?: boolean;
  required?: boolean;
  width?: number;
}

export interface TableColumnsContextValue {
  configurableColumns: ConfigurableColumn[];
  toggleColumn: (name: string) => void;
  resizeColumn: (name: string, width: number) => void;
  reorderColumns: (activeItemIndex: number, overItemIndex: number) => void;
  resetColumns: () => void;
}

export const TableColumnsContext = createContext<TableColumnsContextValue>({
  configurableColumns: [],
  toggleColumn: () => {},
  resizeColumn: () => {},
  reorderColumns: () => {},
  resetColumns: () => {},
});

export const useTableColumns = () => useContext(TableColumnsContext);
