import { forwardRef } from 'react';
import { Color, Interpolation, PaletteColorScheme, styled } from 'styled-components';
import { hexToRgb } from '@/theme/utils';
import { Icon, IconName } from '../icon';
import { Spinner } from '../spinner';

const StyledSpinnerContainer = styled.span({
  position: 'absolute',
  inset: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'inherit',
});

type ButtonVariant = 'filled' | 'ghost';

type ButtonSize = 'sm' | 'md' | 'lg';

interface StyledProps {
  $variant?: ButtonVariant;
  $size?: ButtonSize;
  $colorScheme?: PaletteColorScheme;
  $cursor?: React.CSSProperties['cursor'];
  $disabled?: boolean; // TODO
}

const StyledButton = styled.button<StyledProps>(
  ({ theme, $variant = 'filled', $size, $colorScheme = 'accent', $cursor = 'pointer' }) => {
    let mainColor = theme.color.white;
    let hoverColor = theme.color[`${$colorScheme}-500`] as Color;

    if ($colorScheme === 'neutral') {
      mainColor = theme.color.white;
      hoverColor = theme.color[`${$colorScheme}-700`] as Color;
    }

    const styles: Interpolation<StyledProps> = {
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 'none',
      aspectRatio: 1,
      padding: 0,
      width: theme.spacing[8],
      height: theme.spacing[8],

      borderRadius: theme.radius.sm,
      border: 'none',
      outline: 'none',
      transition: `all 0.3s ${theme.easing['ease-out-quart']}`,
      cursor: $cursor,

      ...theme.font.md,
      color: mainColor,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: hoverColor,
      },
    };

    if ($size === 'sm')
      Object.assign(styles, {
        width: theme.spacing[7],
        height: theme.spacing[7],
      });

    if ($size === 'lg')
      Object.assign(styles, {
        width: theme.spacing[10],
        height: theme.spacing[10],
      });

    if ($variant === 'filled') {
      const bgColor = theme.color[`${$colorScheme}-500`] as Color;

      Object.assign(styles, {
        backgroundColor: hexToRgb(bgColor, 0.1),
        '&:hover': {
          backgroundColor: bgColor,
        },
      });
    }

    return styles;
  },
);

const sizeMap = {
  sm: 18,
  md: 22,
  lg: 28,
};

interface Props extends React.ComponentProps<typeof StyledButton> {
  $icon: IconName;
  loading?: boolean;
}

export const IconButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { $icon, $size = 'md', loading, disabled, ...rest } = props;

  const iconSize = sizeMap[$size];

  return (
    <StyledButton {...rest} ref={ref} disabled={loading || disabled} $disabled={disabled} $size={$size}>
      {/* TODO: add smooth transition animation */}
      {loading && (
        <StyledSpinnerContainer>
          <Spinner $size={iconSize} />
        </StyledSpinnerContainer>
      )}
      <Icon name={$icon} $size={iconSize} $color={loading ? 'transparent' : undefined} />
    </StyledButton>
  );
});
