import { useTranslation } from 'react-i18next';
import type { EducationStep } from '@/features/courses/models';
import { Button, Dialog } from '@/shared/components';
import styles from './StepSuccessModal.module.scss';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  length_minutes: number;
  nextStep?: EducationStep;
  handleNextLesson: () => void;
};

export const StepSuccessModal = ({ isOpen, setIsOpen, length_minutes, nextStep, handleNextLesson }: Props) => {
  const { t } = useTranslation();

  const goToNextStep = () => {
    setIsOpen(false);
    handleNextLesson();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={setIsOpen}
      title={t('education.taskCompleted')}
      content={
        <div className={styles.content}>
          <div className={styles.subtitle}>{t('education.tasksCompletionInProfile')}</div>
          <div className={styles.infoBlocks}>
            <div className={styles.infoBlockItem}>
              <div className={styles.counter}>+ 1</div>
              <div className={styles.countInfo}>{t('education.successfulTask')}</div>
            </div>
            <div className={styles.infoBlockItem}>
              <div className={styles.counter}>+ {length_minutes}</div>
              <div className={styles.countInfo}>{t('education.minutesOfTraining')}</div>
            </div>
          </div>
          <Button $fullWidth onClick={goToNextStep}>
            {nextStep ? t('education.continueTasks') : t('education.completeChapter')}
          </Button>
        </div>
      }
    />
  );
};
