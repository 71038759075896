import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

import { createModalRoot } from './createModalRoot';

interface Props {
  id: string;
  className?: string;
  children: ReactNode;
}

export const Portal = (props: Props) => {
  const modalRoot = createModalRoot(props);

  return ReactDOM.createPortal(props.children, modalRoot);
};
