import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TournamentsSummary } from '@/features/tournaments/api';
import { Box, HStack, Text } from '@/shared/components';

interface Props {
  summary: TournamentsSummary;
}

export const WillPlaySummary = memo(({ summary }: Props) => {
  const { t } = useTranslation();

  const renderSummaryCell = (text: string) => (
    <Text as="span" $mx={2} $fontWeight={600} $color="gray-200" children={text} />
  );

  const hours = Math.floor(summary.session / 3600);
  const minutes = Math.floor((summary.session - hours * 3600) / 60);

  return (
    <Box $mt={0.5} $bgColor="canvas">
      <HStack $ml={14} $mr={4} $px="px" $h={12} $justifyContent="space-between">
        <Text
          as="span"
          $mx={2}
          $fontWeight={600}
          $color="gray-200"
          $textTransform="uppercase"
          children={t('global.summary')}
        />
        {renderSummaryCell(`${t('global.numberOfTournaments')}: ${summary.mtt_count}`)}
        {renderSummaryCell(`${t('global.load')}: $${summary.load}`)}
        {renderSummaryCell(`${t('global.abi')}: $${summary.abi}`)}
        {renderSummaryCell(`${t('global.ev')}: $${summary.ev}`)}
        {renderSummaryCell(`${t('global.numberOfRooms')}: ${summary.rooms_count}`)}
        {renderSummaryCell(
          `${t('global.sessionDuration')}: ${hours ? t('time.hours', { count: hours }) : ''} ${t('time.minutes', { count: minutes })}`,
        )}
      </HStack>
    </Box>
  );
});
