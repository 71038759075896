import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { IFilteredBy } from '@funfarm/kit/types';
import { Badge, IconCloseCircle, IconFilter, TableContext } from '@funfarm/kit';

import css from './filter.module.scss';

interface IProps {
  visible?: boolean;
  filteredBy?: IFilteredBy;
  filterBy?: (fieldName: string, filterValues?: IFilteredBy) => void;
  standAlone?: boolean;
  clearFilters?: () => void;
}

export const CurrentFilters = (props: IProps) => {
  const {
    visible,
    standAlone,
    filteredBy: externalFilteredBy,
    filterBy: externalFilterBy,
    clearFilters: externalClearFilters,
  } = props;
  const { filterValues } = useContext(TableContext);
  let { filteredBy, filterBy, clearFilters } = useContext(TableContext);
  const { t } = useTranslation();
  const disabled =
    !filteredBy ||
    !Object.keys(filteredBy).length ||
    !Object.keys(filteredBy).filter(
      (filter) => filteredBy && !!filteredBy[filter],
    ).length;

  filterBy = externalFilterBy ?? filterBy;
  filteredBy = externalFilteredBy ?? filteredBy;
  clearFilters = externalClearFilters ?? clearFilters;

  const clearThis = (fieldName: string, _value: string | number) => {
    filterBy && filterBy(fieldName);
  };

  if (disabled && !visible) return null;

  return (
    <div className={classNames(css.current, standAlone && css.alone)}>
      <IconFilter checked={!disabled} title={t('Current filters')} />
      {filterValues && filteredBy && !disabled && (
        <>
          {Object.keys(filteredBy).map((fieldName) => {
            if (!filterValues[fieldName]) return null;

            const badge = filterValues[fieldName].find(
              (option) =>
                filteredBy && option.value.toString() === filteredBy[fieldName],
            );

            if (badge)
              return (
                <Badge
                  key={badge.toString()}
                  label={badge.label}
                  onDelete={() => clearThis(fieldName, badge.value)}
                />
              );
          })}
          {!disabled && (
            <IconCloseCircle
              size="large"
              title="Clear all"
              onClick={clearFilters}
            />
          )}
        </>
      )}
    </div>
  );
};
