import React, {
  ChangeEvent,
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';

import { ELabelPosition } from '@funfarm/kit/types';

import css from './switcher.module.scss';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  label?: string;
  value: string;
  checked?: boolean;
  labelPosition?: keyof typeof ELabelPosition;
  onChange?:
    | ((event: ChangeEvent<HTMLInputElement>) => Promise<boolean | undefined>)
    | ChangeEventHandler<HTMLInputElement>;
  onInput?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  error?: string | boolean;
  dataTestId?: string;
}

export const Switcher = forwardRef(
  (props: IProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      name,
      value,
      label,
      checked,
      labelPosition = ELabelPosition.top,
      error,
      required,
      disabled,
      readonly,
      onChange,
      className,
      style,
      dataTestId,
      ...rest
    } = props;

    const [selected, setSelected] = useState<boolean>(!!checked);

    useEffect(() => {
      setSelected(!!checked);
    }, [checked, setSelected]);

    // if onChange returns false Switcher wouldn't switch back
    const handleChange = async (
      event: ChangeEvent<HTMLInputElement>,
      onChange?: typeof props.onChange,
    ) => {
      if (readonly || disabled) return;

      // change first
      setSelected((selected) => !selected);

      let changeAfter;

      if (typeof onChange === 'function') {
        changeAfter = await onChange(event);
      }

      // change it back
      if (onChange && changeAfter === false)
        setSelected((selected) => !selected);
    };

    return (
      <div
        className={classNames(
          css.wrapper,
          error && css.error,
          disabled && css.disabled,
          readonly && css.readonly,
          css.labelPosition,
          css[labelPosition],
          className,
        )}
        style={style}
        {...rest}
      >
        {label &&
          [ELabelPosition.top, ELabelPosition.left].includes(
            ELabelPosition[labelPosition],
          ) && (
            <label htmlFor={name} className={css.label}>
              {label}
              {required && <span className={css.mark}>*</span>}
            </label>
          )}
        <div className={classNames(css.placeholder, selected && css.selected)}>
          <input
            type="checkbox"
            name={name}
            value={value}
            id={name}
            data-testid={dataTestId}
            checked={selected}
            disabled={disabled}
            readOnly={readonly}
            ref={ref}
            onChange={(event) => handleChange(event, onChange)}
          />
          <div className={css.switcher}></div>
        </div>
        {label &&
          [ELabelPosition.right].includes(ELabelPosition[labelPosition]) && (
            <label htmlFor={name} className={css.label}>
              {required && <span className={css.mark}>*</span>}
              {label}
            </label>
          )}
        {
          // error &&
          // <p className={css.error}>{error}</p>
        }
      </div>
    );
  },
);

export default Switcher;
