import { Paper } from '@funfarm/kit';
import { FilterInputs } from './FilterInputs';
import { FilterTemplates } from './FilterTemplates';
import styles from '../tournaments.module.scss';

export const TournamentsFilters = () => (
  <Paper className={styles.filter}>
    <FilterTemplates />
    <FilterInputs />
  </Paper>
);
