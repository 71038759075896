import { useContext, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { TourContext } from '@/__old/components/Tournaments/TourProvider';
import {
  useHideTournaments,
  useShowTournaments,
  useWillPlayTournaments,
  useWontPlayTournaments,
} from '@/features/tournaments/api';
import { Tournament } from '@/features/tournaments/models';
import { Button } from '@/shared/components';
import { useSynchronizedRef } from '@/shared/hooks';
import { TournamentType } from './tournament-selection.context';
import { StyledContainer } from './tournament-selection.styles';

type Props = {
  isOpen: boolean;
  resetSelectedTournaments: () => void;
  tournamentType: TournamentType;
  selectedTournamentsMap: Record<string, Tournament>;
};

export const TournamentSelectionActions = (props: Props) => {
  const { isOpen, resetSelectedTournaments, tournamentType, selectedTournamentsMap } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  const { filterParams } = useContext(TourContext); // TODO: remove this
  const isHidden = filterParams?.show_hidden;

  /** auto reset selected tournaments on filter change */
  const resetRef = useSynchronizedRef(resetSelectedTournaments);
  useLayoutEffect(() => {
    resetRef.current();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHidden]);

  const { mutateAsync: willPlay, isPending: isWillPlayPending } = useWillPlayTournaments();
  const { mutateAsync: wontPlay, isPending: isWontPlayPending } = useWontPlayTournaments();

  const handleWillPlay = async () => {
    const tournaments = Object.values(selectedTournamentsMap);
    if (!tournaments.length) return;
    await willPlay(tournaments);
    resetSelectedTournaments();
  };

  const handleWontPlay = async () => {
    const tournaments = Object.values(selectedTournamentsMap);
    if (!tournaments.length) return;
    await wontPlay(tournaments.map((tournament) => tournament.id));
    resetSelectedTournaments();
  };

  const { mutateAsync: showTournaments, isPending: isShowTournamentsPending } = useShowTournaments();
  const { mutateAsync: hideTournaments, isPending: isHideTournamentsPending } = useHideTournaments();

  const handleShow = async () => {
    const tournaments = Object.values(selectedTournamentsMap);
    if (!tournaments.length) return;
    await showTournaments(tournaments.map((tournament) => tournament.id));
    resetSelectedTournaments();
  };

  const handleHide = async () => {
    const tournaments = Object.values(selectedTournamentsMap);
    if (!tournaments.length) return;
    await hideTournaments(tournaments.map((tournament) => tournament.id));
    resetSelectedTournaments();
  };

  const isAllTournaments = tournamentType === 'all';

  return (
    <StyledContainer $isVisible={isOpen} $width={isAllTournaments ? theme.spacing[96] : theme.spacing[56]}>
      {isAllTournaments ? (
        <Button
          $size="lg"
          $icon="plus"
          children={t('tournaments.willPlay')}
          onClick={handleWillPlay}
          disabled={isHidden}
          loading={isWillPlayPending}
        />
      ) : (
        <Button
          $size="lg"
          $icon="minus"
          children={t('tournaments.willNotPlay')}
          disabled={isHidden}
          onClick={handleWontPlay}
          loading={isWontPlayPending}
        />
      )}

      {isAllTournaments &&
        (isHidden ? (
          <Button
            $size="lg"
            $icon="eye"
            children={t('global.show')}
            onClick={handleShow}
            loading={isShowTournamentsPending}
          />
        ) : (
          <Button
            $size="lg"
            $icon="eye-off"
            children={t('global.hide')}
            onClick={handleHide}
            loading={isHideTournamentsPending}
          />
        ))}
    </StyledContainer>
  );
};
