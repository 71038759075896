import { ApiRequest } from '@/shared/api';
import { Toast } from '@funfarm/kit';
// TODO: create new toast component
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Tournament } from '../models';

export interface TournamentTableFilters {
  date_start?: number;
  date_end?: number;
  bi_start?: string;
  rooms_id?: (string | number)[] | string;
  tourney_type?: (string | number)[] | string;
  show_hidden?: boolean;
  show_passed?: boolean;
  is_series?: string;
}

export type TournamentsSummary = {
  abi: number;
  ev: number;
  load: number;
  mtt_count: number;
  session: number;
  rooms_count: number;
};

export type GetTournamentsParams = {
  isSelectedTournamets: boolean;
  filters: TournamentTableFilters;
  orderedBy?: Record<string, 'asc' | 'desc'>;
};

const initQueryParams = (params: GetTournamentsParams) => {
  const { isSelectedTournamets, filters, orderedBy } = params;

  const queryParams = {
    selected: isSelectedTournamets,

    ...filters,

    bi_start: filters.bi_start || 0,
    is_series: (filters.is_series?.split(',').length === 1 && filters.is_series) || undefined,

    rooms_id: Array.isArray(filters.rooms_id) ? filters.rooms_id : filters.rooms_id?.split(',').filter(Boolean),
    tourney_type: Array.isArray(filters.tourney_type)
      ? filters.tourney_type
      : filters.tourney_type?.split(',').filter(Boolean),

    skip: 0,
    take: 100,

    order_by: 'start_date',
    order_dir: 'asc',
  };

  if (queryParams.rooms_id?.length === 0) delete queryParams.rooms_id;
  if (queryParams.tourney_type?.length === 0) delete queryParams.tourney_type;

  if (orderedBy) {
    const orderKey = Object.keys(orderedBy)[0];
    queryParams.order_by = orderKey;
    queryParams.order_dir = orderedBy[orderKey];
  }

  return queryParams;
};

export const useGetWillPlayTournaments = (params: GetTournamentsParams) => {
  const queryParams = initQueryParams(params);
  return useQuery({
    refetchInterval: 5 * 60 * 1000, // 5 minutes
    queryKey: ['tourneys', 'will-play', ...Object.values(queryParams)],
    queryFn: () =>
      new ApiRequest<{
        total: number;
        tourneys: Tournament[];
        summary: TournamentsSummary;
      }>({
        url: '/api/check/tourneys/schedule',
        method: 'GET',
        params: queryParams,
      }),
  });
};

export const useGetAllTournaments = (params: GetTournamentsParams) => {
  const initialQueryParams = initQueryParams(params);

  return useInfiniteQuery({
    refetchInterval: 5 * 60 * 1000,
    queryKey: ['tourneys', 'all-tourneys', ...Object.values(initialQueryParams)],
    initialPageParam: { skip: 0, take: 100 },
    queryFn: ({ pageParam }) => {
      return new ApiRequest<{
        hidden: number;
        total: number;
        tourneys: Tournament[];
        summary: TournamentsSummary;
      }>({
        url: '/api/check/tourneys/schedule',
        method: 'GET',
        params: { ...initialQueryParams, ...pageParam },
      });
    },
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      const { skip, take } = lastPageParam;
      if (lastPage.total < skip + take) return undefined;
      return { skip: skip + take, take };
    },
  });
};

export const useWillPlayTournaments = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (tournaments: Tournament[]) =>
      new ApiRequest({
        url: '/api/check/tourneys/select',
        method: 'POST',
        data: { tourney_ids: tournaments },
      }),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['schedule'] });
      queryClient.invalidateQueries({ queryKey: ['tourneys'] });
    },

    onError: (error) => {
      Toast.error({ message: error.message });
    },
  });
};

export const useWontPlayTournaments = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (tournamentIds: EntityId[]) =>
      new ApiRequest({
        url: '/api/check/tourneys/unselect',
        method: 'POST',
        data: { tourney_ids: tournamentIds },
      }),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['schedule'] });
      queryClient.invalidateQueries({ queryKey: ['tourneys'] });
    },

    onError: (error) => {
      Toast.error({ message: error.message });
    },
  });
};

export const useShowTournaments = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (tournamentIds: EntityId[]) =>
      new ApiRequest({
        url: '/api/check/tourneys/unhide',
        method: 'POST',
        data: { tourney_ids: tournamentIds },
      }),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['schedule'] });
      queryClient.invalidateQueries({ queryKey: ['tourneys'] });
    },

    onError: (error) => {
      Toast.error({ message: error.message });
    },
  });
};

export const useHideTournaments = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (tournamentIds: EntityId[]) =>
      new ApiRequest({
        url: '/api/check/tourneys/hide',
        method: 'POST',
        data: { tourney_ids: tournamentIds },
      }),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['schedule'] });
      queryClient.invalidateQueries({ queryKey: ['tourneys'] });
    },

    onError: (error) => {
      Toast.error({ message: error.message });
    },
  });
};
