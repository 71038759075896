import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadFile } from '@/__old/actions';
import { Button, Paper, ProgressBar, Uploader } from '@funfarm/kit';

export const FileUploader = () => {
  const { t } = useTranslation();

  const [file, setFile] = useState<File>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [progressState, setProgressState] = useState<
    'run' | 'success' | 'fail'
  >();
  const [message, setMessage] = useState<string>();
  const [downloadUrl, setDownloadUrl] = useState<string>();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setUploadProgress(0);
      setMessage('');
      setProgressState(undefined);
    }
  };

  const uploadFileInChunks = async () => {
    if (!file) {
      setMessage('Пожалуйста, выберите файл для загрузки.');
      return;
    }

    setProgressState('run');
    try {
      const url = await uploadFile(file, (e) => {
        setUploadProgress(Math.round(Number(e.progress) * 100));
      });
      setDownloadUrl(url);
      setProgressState('success');
    } catch (e) {
      setProgressState('fail');
    }
  };

  return (
    <div>
      <Paper
        header={t('Загрузка файла по частям')}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div className="row gap2 align-center">
          <Uploader onChange={handleFileChange} label={t('Выберите файл')} />
          <div style={{ minWidth: '12rem' }}>
            {progressState && (
              <ProgressBar
                progress={uploadProgress}
                progressState={progressState}
                title={message || t('Прогресс загрузки...')}
              />
            )}
          </div>
        </div>
        <div>
          <Button
            onClick={uploadFileInChunks}
            loading={progressState === 'run'}
          >
            {t('Загрузить')}
          </Button>
        </div>
        {downloadUrl ? (
          <div>
            Файл загружен:
            <br />
            <a href={downloadUrl} target={'_blank'}>
              {downloadUrl}
            </a>
          </div>
        ) : (
          ''
        )}
      </Paper>
    </div>
  );
};
