import { useEffect, useState } from 'react';

const useQueryParams = () => {
  const [queryParams, setQueryParams] = useState<Record<string, string>>({});

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const queryObject: Record<string, string> = {};
    for (const [key, value] of params.entries()) {
      queryObject[key] = value;
    }
    setQueryParams(queryObject);
  }, []);

  return queryParams;
};

export default useQueryParams;
