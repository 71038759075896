import { useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { AgGridReact } from 'ag-grid-react';
import { TourContext } from '@/__old/components/Tournaments/TourProvider';
import { useGetAllTournaments } from '@/features/tournaments/api';
import { AlertCard, HStack, Spinner } from '@/shared/components';
import { TableContext } from '@funfarm/kit';
import { useTableFilters } from '../../../table-filters';
import { useTableTheme } from '../../tournaments-table-theme';
import { getPaletteClass, getRowId, useTournamentColumns } from '../../tournaments.resoures';
import { AllTournamentsHeader } from './all-tournaments-header';

interface Props {
  gridRef: React.Ref<AgGridReact>;
}

export const AllTournamentsTable = ({ gridRef }: Props) => {
  const { orderedBy } = useContext(TableContext);
  const { filterParams } = useContext(TourContext);

  const { ref: loaderRef, inView } = useInView();

  const { setHiddenTournamentsCount } = useTableFilters();

  const { data, isLoading, isError, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetAllTournaments({
      isSelectedTournamets: false,
      filters: filterParams,
      orderedBy,
    });

  /** update hidden tournaments count */
  useLayoutEffect(() => {
    const pageLength = data?.pages.length;
    if (!pageLength) return;
    setHiddenTournamentsCount(data.pages[pageLength - 1].hidden);
  }, [data, setHiddenTournamentsCount]);

  /** load more on inView */
  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, hasNextPage, fetchNextPage]);

  const tourneys = useMemo(() => data?.pages.flatMap((page) => page.tourneys), [data]);

  const columnDefs = useTournamentColumns({ isSelectedTournaments: false });

  const tableTheme = useTableTheme();

  if (isError) return <AlertCard $mt={1} message="Ошибка при загрузке всех турниров" />;

  return (
    <>
      {!isLoading && <AllTournamentsHeader />}

      <AgGridReact
        ref={gridRef}
        rowData={tourneys}
        getRowClass={getPaletteClass}
        getRowId={getRowId}
        columnDefs={columnDefs}
        /** behaviour */
        suppressRowHoverHighlight
        suppressCellFocus
        /** appearance */
        className="all-tournaments-table"
        loading={isFetching && !isFetchingNextPage}
        theme={tableTheme}
        domLayout="autoHeight"
        headerHeight={0}
      />

      {!isLoading && (
        <HStack ref={loaderRef} $py={6}>
          {isFetchingNextPage && <Spinner $size={40} />}
        </HStack>
      )}
    </>
  );
};
