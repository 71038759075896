import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconChartLinePresentation = (props: IconProps) => {
  return (
    <Icon id="IconChartLinePresentation" {...props}>
      <path
        d="M4 4V3.25H3.25V4H4ZM20 4H20.75V3.25H20V4ZM6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303C6.76256 12.8232 7.23744 12.8232 7.53033 12.5303L6.46967 11.4697ZM10 9L10.5303 8.46967C10.2374 8.17678 9.76256 8.17678 9.46967 8.46967L10 9ZM13 12L12.4697 12.5303C12.7626 12.8232 13.2374 12.8232 13.5303 12.5303L13 12ZM17.5303 8.53033C17.8232 8.23744 17.8232 7.76256 17.5303 7.46967C17.2374 7.17678 16.7626 7.17678 16.4697 7.46967L17.5303 8.53033ZM7.46967 20.4697C7.17678 20.7626 7.17678 21.2374 7.46967 21.5303C7.76256 21.8232 8.23744 21.8232 8.53033 21.5303L7.46967 20.4697ZM12 17L12.5303 16.4697C12.2374 16.1768 11.7626 16.1768 11.4697 16.4697L12 17ZM15.4697 21.5303C15.7626 21.8232 16.2374 21.8232 16.5303 21.5303C16.8232 21.2374 16.8232 20.7626 16.5303 20.4697L15.4697 21.5303ZM3 3.25C2.58579 3.25 2.25 3.58579 2.25 4C2.25 4.41421 2.58579 4.75 3 4.75V3.25ZM21 4.75C21.4142 4.75 21.75 4.41421 21.75 4C21.75 3.58579 21.4142 3.25 21 3.25V4.75ZM4 4.75H20V3.25H4V4.75ZM19.25 4V16H20.75V4H19.25ZM19 16.25H5V17.75H19V16.25ZM4.75 16V4H3.25V16H4.75ZM5 16.25C4.86193 16.25 4.75 16.1381 4.75 16H3.25C3.25 16.9665 4.0335 17.75 5 17.75V16.25ZM19.25 16C19.25 16.1381 19.1381 16.25 19 16.25V17.75C19.9665 17.75 20.75 16.9665 20.75 16H19.25ZM7.53033 12.5303L10.5303 9.53033L9.46967 8.46967L6.46967 11.4697L7.53033 12.5303ZM9.46967 9.53033L12.4697 12.5303L13.5303 11.4697L10.5303 8.46967L9.46967 9.53033ZM13.5303 12.5303L17.5303 8.53033L16.4697 7.46967L12.4697 11.4697L13.5303 12.5303ZM8.53033 21.5303L12.5303 17.5303L11.4697 16.4697L7.46967 20.4697L8.53033 21.5303ZM11.4697 17.5303L15.4697 21.5303L16.5303 20.4697L12.5303 16.4697L11.4697 17.5303ZM3 4.75H21V3.25H3V4.75Z"
        stroke="#ACAFB5"
        strokeWidth="0.1"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#ACAFB5"
      />
    </Icon>
  );
};
