import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import { Routes } from '@/__old/components/Routes';
import { AuthProvider } from '@/__old/helpers';
import i18n from '@/__old/helpers/i18n';
import { styledTheme } from '@/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 30, // 30 sec
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={styledTheme}>
        <SuperTokensWrapper>
          <AuthProvider>
            <QueryClientProvider client={queryClient}>
              <Routes />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </AuthProvider>
        </SuperTokensWrapper>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          rtl={false}
          draggable={false}
          icon={false}
          pauseOnHover
        />
      </ThemeProvider>
    </I18nextProvider>
  );
};
