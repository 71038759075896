import { forwardRef, useContext, useState, createContext } from 'react';
import { styled } from 'styled-components';
import { useDebounce } from 'use-debounce';
import { useIsMounted } from '@/shared/hooks';
import * as RadixUIPopover from '@radix-ui/react-popover';

export * from './popover.elements';

interface PopoverAnimationContext {
  isOpen: boolean;
  isOpening: boolean;
  isClosed: boolean;
  isClosing: boolean;
}

export const PopoverContext = createContext<PopoverAnimationContext>({
  isOpen: false,
  isOpening: false,
  isClosed: false,
  isClosing: false,
});

const animationDuration = 200;

export const Root = (props: RadixUIPopover.PopoverProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDebounced] = useDebounce(isOpen, animationDuration);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    props.onOpenChange?.(open);
  };

  const contextValue: PopoverAnimationContext = {
    isOpen,
    isOpening: isOpen && !isOpenDebounced,
    isClosed: !isOpen,
    isClosing: !isOpen && isOpenDebounced,
  };

  const open = isOpen || contextValue.isClosing;

  return (
    <PopoverContext.Provider value={contextValue}>
      <RadixUIPopover.Root onOpenChange={handleOpenChange} {...props} open={open} />
    </PopoverContext.Provider>
  );
};

export const Trigger = styled(RadixUIPopover.Trigger)``;

Trigger.defaultProps = {
  asChild: true,
};

export const Portal = RadixUIPopover.Portal;

const StyledContent = styled(RadixUIPopover.Content)<{ $isVisible: boolean }>(({ theme, $isVisible }) => ({
  zIndex: theme.zIndex.popover,
  borderRadius: theme.radius.md,
  backgroundColor: theme.color.canvas,
  boxShadow: theme.shadow.lg,

  opacity: $isVisible ? '1' : '0',
  transform: $isVisible ? 'scale(1) translateY(0)' : 'scale(0.98) translateY(-0.75rem)',
  transformOrigin: 'top',
  transitionProperty: 'opacity, transform',
  transitionDuration: `${animationDuration}ms`,
  transitionTimingFunction: $isVisible ? theme.easing['ease-out-quint'] : theme.easing['ease-out-expo'],
}));

export const Content = forwardRef<HTMLDivElement, Omit<React.ComponentProps<typeof StyledContent>, '$isVisible'>>(
  (props, ref) => {
    const { isClosing } = useContext(PopoverContext);

    const isMounted = useIsMounted();

    const isVisible = isMounted ? (isClosing ? false : true) : false;

    return <StyledContent ref={ref} sideOffset={8} {...props} $isVisible={isVisible} />;
  },
);
