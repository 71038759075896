import { forwardRef } from 'react';
import { mergeRefs } from '@/shared/utils/refs';
import { type DraggableAttributes, useDraggable, useDroppable } from '@dnd-kit/core';
import { type SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { CSS, Transform } from '@dnd-kit/utilities';
import { useDndList } from './dnd-list-context';
import { DndItemData } from './dnd-list.types';

interface Props {
  item: (options: {
    listeners: SyntheticListenerMap | undefined;
    attributes: DraggableAttributes & { 'data-dnd-activator': '' };
    setActivatorNodeRef: (node: HTMLElement | null) => void;
    isDragging: boolean;
  }) => React.ReactNode;
  itemId: string;
  itemIndex: number;
  itemData: DndItemData;
  listGap?: number;
  isDisabled?: boolean;
}

const DndListItem = forwardRef<HTMLDivElement, Props>(function DndElementListItem(props, ref) {
  const { item, itemId, itemIndex, listGap = 16, isDisabled, itemData } = props;

  const draggableId = `draggable-${itemId}`;
  const draggable = useDraggable({ id: draggableId, data: itemData, disabled: isDisabled });

  const {
    active,
    activeNodeRect,
    attributes,
    setNodeRef: setDraggableNodeRef,
    listeners,
    isDragging,
    // https://docs.dndkit.com/api-documentation/draggable/usedraggable#activator
    setActivatorNodeRef,
    over,
  } = draggable;

  const droppableId = `droppable-${itemId}`;
  const droppable = useDroppable({ id: droppableId, data: itemData, disabled: isDisabled });
  const { isOver, setNodeRef: setDroppableNodeRef } = droppable;

  const { dragOverlayHeight } = useDndList();

  const isActiveItem = active?.id === draggableId;
  const isDraggingAbove = active?.data.current?.index < over?.data.current?.index;
  const isItemAboveDraggable = itemIndex < active?.data.current?.index;

  const isSwappableItem =
    isOver ||
    (isDraggingAbove
      ? !isItemAboveDraggable && itemIndex < over?.data.current?.index
      : isItemAboveDraggable && itemIndex > over?.data.current?.index);

  const transform: Transform | null =
    isActiveItem || !activeNodeRect || !isSwappableItem
      ? null
      : {
          x: 0,
          y: ((dragOverlayHeight || activeNodeRect.height) + listGap) * (isItemAboveDraggable ? 1 : -1),
          scaleX: 1,
          scaleY: 1,
        };

  return (
    <div
      ref={mergeRefs([ref, setDraggableNodeRef, setDroppableNodeRef])}
      style={{
        position: 'relative',
        transform: CSS.Translate.toString(transform),
        transition: active ? 'transform 150ms ease-out' : undefined /** fix issue with twitching */,
        opacity: isDragging ? 0 : undefined,
      }}
    >
      {item({
        listeners,
        attributes: { ...attributes, 'data-dnd-activator': '' },
        setActivatorNodeRef,
        isDragging,
      })}
    </div>
  );
});

export default DndListItem;
