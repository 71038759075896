import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetEducationSteps } from '@/features/courses/api';
import { Breadcrumbs } from '@/features/courses/components';
import { styles } from '@/features/courses/styles';
import type { ModulePageParams } from '@/features/courses/types';
import { ROUTES } from '@/routes';
import { Error, Skeleton } from '@funfarm/kit';
import { ModuleHeader } from './ModuleHeader/ModuleHeader';
import { StepItem } from './StepsList/StepItem';

export const EducationModule = () => {
  const { t } = useTranslation();

  const { courseId, blockId, moduleId } = useParams() as ModulePageParams;

  const { data: module, isLoading, isError } = useGetEducationSteps({ courseId, blockId, moduleId });

  if (isLoading || !module) return <Skeleton />;
  if (isError) return <Error message={t('fetchingError')} />;

  const backLink = ROUTES.modulesByBlockId({ courseId, blockId });

  return (
    <>
      <Breadcrumbs
        links={[
          { href: ROUTES.courses, title: t('education.courses') },
          { href: ROUTES.blocksByCourseId(courseId), title: module.course_title },
          { href: backLink, title: module.block_title },
          { title: module.title },
        ]}
      />
      <ModuleHeader module={module} link={backLink} />
      <div className={styles.container}>
        {module.steps.map((step) => (
          <StepItem
            key={step.id}
            link={ROUTES.stepById({ courseId, blockId, moduleId, stepId: step.id })}
            step={step}
          />
        ))}
      </div>
    </>
  );
};
