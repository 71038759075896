import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  matchRoutes,
  NavLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import classNames from 'classnames';
import { useRoutes } from '@/__old/components/Routes';
// import Popover from '@funfarm/kit';
import type { IRoute } from '@/__old/types';
import { IconArrowLeft, IconChevronDown } from '@funfarm/kit';
import css from './navigation.module.scss';

interface IProps {
  expanded?: boolean;
  setExpanded?: Dispatch<SetStateAction<boolean>>;
}

export const Navigation = React.memo((props: IProps) => {
  const { expanded } = props;

  const routes: IRoute[] = useRoutes();
  const navigate = useNavigate();
  const location = useLocation();
  const routesStack = matchRoutes(routes, location)?.reverse();

  // get available children
  const getChildren = useCallback((children: IRoute[]) => {
    return (
      children
        // .filter((route: IRoute) => !route.index)
        .filter(
          (route: IRoute) => !(route.handle && route.handle.visible === false),
        )
        .filter(
          (route: IRoute) =>
            !route.handle ||
            (route.handle &&
              (route.handle.permission ||
                route.handle.permission === undefined)),
        )
    );
  }, []);

  let currentRoute = routes[0];
  if (routesStack) {
    currentRoute = routesStack[0].route;

    // проверяем уровень вложенности
    // если в route.path есть (:slug) останавливаемся на этом уровне
    for (const { route } of routesStack) {
      currentRoute = route;

      const children = route.children && getChildren(route.children).length;

      if (
        (route.path && route.path.match(/^:/) && children) ||
        (!route.element && !route.Component)
      ) {
        break;
      }
    }
  }

  // составляем массив активных ссылок ['platform', ':slug']
  const activeRoutes = useMemo(() => {
    if (routesStack)
      return routesStack
        .filter(({ route }) => !!route.path)
        .map(({ route }) => route.path);

    return [];
  }, [routesStack]);

  // собственно текущий уровень навигации
  const navigation = useMemo(() => {
    // если не нашли entity не показываем меню
    // if(currentRoute.handle && !!currentRoute.handle.selector && currentRoute.handle.selector.error)
    //     return [];

    if (currentRoute.children) return getChildren(currentRoute.children);

    return [];
  }, [currentRoute, getChildren]);

  const [sections, setSections] =
    useState<(string | undefined)[]>(activeRoutes);

  const toggleSection = useCallback(
    (path?: string) => {
      const sectionsSet = new Set(sections);

      sectionsSet.has(path) ? sectionsSet.delete(path) : sectionsSet.add(path);

      setSections([...sectionsSet]);
    },
    [sections, setSections],
  );

  const renderLevel = useCallback(
    (navigation: IRoute[]) => {
      return navigation.map((route: IRoute, key: number) => {
        // смотрим наличие children у элемента
        const children = route.children && getChildren(route.children);

        return (
          <Fragment key={route.path ?? key}>
            {children && children.length ? (
              <div
                className={classNames(
                  css.section,
                  activeRoutes.includes(route.path) && css.active,
                )}
              >
                <div
                  className={classNames(
                    css.parent,
                    activeRoutes.includes(route.path) && css.active,
                  )}
                  onClick={() => toggleSection(route.path)}
                >
                  {/*<NavLink end className={({ isActive }) => classNames(css.parent, (isActive || activeRoutes.includes(route.path)) && css.active)} to={route.handle.path}>*/}
                  {route.handle.icon &&
                    React.cloneElement(route.handle.icon, {
                      size: expanded ? 'medium' : 'xlarge',
                    })}
                  {expanded && (
                    <span className={css.name}>{route.handle.name}</span>
                  )}
                  {expanded && (
                    // active.includes(route.path) ?
                    //     <IconChevronUp className={css.toggle} /> :
                    <IconChevronDown className={css.toggle} size="small" />
                  )}
                </div>
                {expanded &&
                  (activeRoutes.includes(route.path) ||
                    sections.includes(route.path)) &&
                  children.map((route: IRoute) => (
                    // проверка наличия вложенных children
                    // route.children ?
                    //     renderLevel([route]) :
                    <NavLink
                      key={route.handle.path}
                      end
                      className={({ isActive }) =>
                        classNames(
                          css.child,
                          (isActive || activeRoutes.includes(route.path)) &&
                            css.active,
                        )
                      }
                      to={route.handle.path}
                    >
                      {route.handle.icon}
                    </NavLink>
                  ))}
              </div>
            ) : (
              <NavLink
                className={({ isActive }) =>
                  classNames(
                    css.nav_item,
                    (isActive || activeRoutes.includes(route.path)) &&
                      css.active,
                  )
                }
                to={route.handle.path}
              >
                <span className={css.nav_item_name}>{route.handle.name}</span>
                {route.handle.icon &&
                  React.cloneElement(route.handle.icon, {
                    className: classNames(css.nav_item_icon),
                    size: expanded ? 'medium' : 'xlarge',
                  })}
              </NavLink>
            )}
          </Fragment>
        );
      });
    },
    [activeRoutes, expanded, getChildren, sections, toggleSection],
  );

  const getUpPath = useMemo(() => {
    let endIndex = 0;

    if (currentRoute.handle?.selector) {
      endIndex = currentRoute.handle?.selector.data?.name
        ? location.pathname.indexOf(
            `/${currentRoute.handle?.selector.data?.name}`,
          )
        : 0;

      if (endIndex <= 0) {
        endIndex = currentRoute.handle?.selector.data?.id
          ? location.pathname.indexOf(
              `/${currentRoute.handle?.selector.data?.id}`,
            )
          : 0;
      }
    }

    return location.pathname.substring(0, endIndex);
  }, [currentRoute, location]);

  return (
    <nav className={css.nav}>
      {currentRoute.handle && !!currentRoute.handle.selector && (
        <div className={css.current}>
          <IconArrowLeft
            className={css.navBack}
            onClick={() => navigate(getUpPath)}
            size={expanded ? 'medium' : 'xlarge'}
          />
          {expanded && (
            <h1 className={css.title}>
              {currentRoute.handle.selector.data?.name}
            </h1>
          )}
        </div>
      )}

      {navigation && navigation.length
        ? renderLevel(navigation)
        : currentRoute.handle?.fallBack}
    </nav>
  );
});
