import { colorSchemeDark, themeQuartz } from 'ag-grid-community';

export const darkTheme = themeQuartz.withPart(colorSchemeDark).withParams({
  backgroundColor: '#1C1E23',
  headerBackgroundColor: '#1C1E23',
  foregroundColor: '#7A7F89',
  headerTextColor: '#ACAFB5',

  fontSize: '1rem',
  fontFamily: 'inherit',
  headerFontWeight: 400,

  spacing: '0.5625rem',
  headerHeight: '2.75rem',

  wrapperBorder: false,
  columnBorder: {
    style: 'solid',
    width: 1,
    color: '#121315',
  },
  borderColor: '#26282A',
  headerColumnResizeHandleHeight: '100%',
  headerColumnResizeHandleWidth: '1px',
  headerRowBorder: {
    style: 'solid',
    width: 2,
    color: '#121315',
  },
  rowBorder: {
    style: 'solid',
    width: 2,
    color: '#121315',
  },
});
