import { useCallback, useState } from 'react';

export const useBoolean = (initialState?: boolean): UseBooleanReturn => {
  const [isTrue, setIsTrue] = useState<boolean>(initialState || false);

  const toggleFlag = useCallback(() => setIsTrue((isTrue) => !isTrue), []);
  const setTrue = useCallback(() => setIsTrue(true), []);
  const setFalse = useCallback(() => setIsTrue(false), []);

  return [isTrue, setTrue, setFalse, toggleFlag];
};

type UseBooleanReturn = [isTrue: boolean, setTrue: () => void, setFalse: () => void, toggleFlag: () => void];
