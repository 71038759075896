import { createContext, useContext } from 'react';
import { Tournament } from '@/features/tournaments/models';

export type TournamentType = 'all' | 'will-play';

export interface TournamentSelectionContextValue {
  selectedTournamentType: TournamentType;
  selectedTournamentMap: Record<string, Tournament>;
  toggleTournament: (tournament: Tournament, type: TournamentType) => void;
  selectAllTournaments: (tournaments: Tournament[], type: TournamentType) => void;
}

export const TournamentSelectionContext = createContext<TournamentSelectionContextValue>({
  selectedTournamentType: 'all',
  selectedTournamentMap: {},
  toggleTournament: () => {},
  selectAllTournaments: () => {},
});

export const useTournamentSelection = () => useContext(TournamentSelectionContext);
