import { createContext, useContext } from 'react';

export interface TableFiltersContextValue {
  hiddenTournamentsCount?: number;
  setHiddenTournamentsCount: (count: number) => void;
}

export const TableFiltersContext = createContext<TableFiltersContextValue>({
  hiddenTournamentsCount: undefined,
  setHiddenTournamentsCount: () => {},
});

export const useTableFilters = () => useContext(TableFiltersContext);
