import React, { ChangeEvent, useCallback, useContext } from 'react';

import { Checkbox, IEventTarget, TableContext } from '@funfarm/kit';

interface IProps {
  rowId: string | number;
  rowData?: Record<string, any>;
  disabled?: boolean;
}

export const ActionCheckbox = (props: IProps) => {
  const { rowId, rowData, disabled } = props;
  const { rowsChecked, setRowsChecked } = useContext(TableContext);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement> | IEventTarget) => {
      if (!setRowsChecked || !rowsChecked || !rowData) return;

      const value = event.target.value;
      if (rowsChecked?.find((r) => r.id === Number(value))) {
        setRowsChecked(rowsChecked.filter((r) => r.id !== Number(value)));
      } else {
        setRowsChecked([...rowsChecked, rowData]);
      }
    },
    [rowData, rowsChecked, setRowsChecked],
  );

  return (
    <Checkbox
      onChange={handleChange}
      name={String(rowId)}
      value={rowId}
      checked={!!rowsChecked?.find((r) => Number(r.id) === Number(rowId))}
      disabled={disabled}
    />
  );
};
