import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';
import type { EducationStep } from '@/features/courses/models';
import { Icon } from '@/shared/components';
import styles from './StepHeader.module.scss';

type Props = {
  link: string;
  steps: EducationStep[];
  currentStepId: string;
  goToStep: (stepId: string) => void;
};

export const StepHeader = ({ link, steps, currentStepId, goToStep }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const activeElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    activeElementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }, []);

  return (
    <div className={styles.container}>
      <div onClick={() => navigate(link)} className={styles.backButton}>
        <Icon name="cheveron-left" $color="gray-200" />
      </div>
      <div className={styles.divider} />
      <div className={clsx(styles.stepsList, 'scrollBox', 'scrollBoxY')}>
        {steps.map((step) => {
          const isCurrentStep = currentStepId === step.id;

          const handleStepChange = () => {
            if (step.is_available) goToStep(step.id);
          };

          return (
            <div
              key={step.id}
              className={clsx(
                styles.step,
                step.is_done && styles.done,
                !step.is_available && styles.notAllowed,
                isCurrentStep && styles.current,
              )}
              ref={isCurrentStep ? activeElementRef : undefined}
              onClick={handleStepChange}
            >
              {step.is_done && <Icon name="check" $color={isCurrentStep ? 'gray-50' : 'gray-200'} />}
              {`${t('education.task')} ${step.number}`}
            </div>
          );
        })}
      </div>
    </div>
  );
};
