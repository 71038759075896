import axios, { type AxiosRequestConfig } from 'axios';
import { env } from 'env';

export const queryClient = axios.create({
  baseURL: env.FF_API_URL,
});

type RequestConfig = Omit<AxiosRequestConfig, 'signal'> & Required<Pick<AxiosRequestConfig, 'method' | 'url'>>;

type ApiResponse<R> = Promise<R> & {
  cancel: () => void;
};

export const ApiRequest = class<R = void> {
  constructor(options: RequestConfig) {
    const controller = new AbortController();

    const config: AxiosRequestConfig = {
      signal: controller.signal,
      ...options,
    };

    const promise = queryClient.request<R>(config).then((res) => res.data) as ApiResponse<R>;

    // TODO: throw custom errors
    // promise.catch(error => { })

    promise.cancel = controller.abort.bind(controller, 'ApiRequestCancelled');

    return promise;
  }
} as { new <R = void>(options: RequestConfig): ApiResponse<R> };
