import { styled, Interpolation } from 'styled-components';

interface StyledProps {
  // TODO
  $size?: 'sm' | 'md';
  // $colorScheme?: PaletteColorScheme;
}

const StyledBadge = styled.span<StyledProps>(({ theme, $size, children }) => {
  const styles: Interpolation<StyledProps> = {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.color.black,
    fontFamily: theme.fontFamily.primary,
    fontWeight: 600,
    ...theme.font.md,
    paddingInline: String(children).length > 1 ? theme.spacing[1.5] : 0,
    backgroundColor: theme.color['gray-200'],
    borderRadius: theme.radius.full,
    minWidth: theme.spacing[5],
    height: theme.spacing[5],
  };

  // TODO:
  if ($size === 'sm')
    Object.assign(styles, {
      ...theme.font.sm,
      minWidth: theme.spacing[5],
      height: theme.spacing[5],
    });

  return styles;
});

StyledBadge.defaultProps = {
  children: 0,
};

export const Badge = StyledBadge;
