export const getEnumValueByKey = <T extends object>(
  en: T,
  key: keyof typeof en,
) => {
  const index = Object.keys(en).indexOf(key as string);

  return Object.values(en)[index];
};

export const getEnumKeyByValue = <T extends object, U extends string>(
  en: T,
  value: U,
) => {
  const index = Object.values(en).indexOf(value);

  return Object.keys(en)[index] as keyof T;
};

export interface IError {
  code?: string;
  status?: number | string;
  statusText?: string;
  message?: string;
}

interface IFastAPIError {
  type: string;
  loc: [string, string];
  msg: string;
}

export interface IErrorData {
  detail?: string | IFastAPIError[];
}

export enum EColors {
  default = 'default',
  secondary = 'secondary',
  success = 'success',
  dark = 'dark',
  white = 'white',
  blue = 'blue',
  red = 'red',
  green = 'green',
  yellow = 'yellow',
  orange = 'orange',
}

export enum EVariants {
  text = 'text', // only hover
  outlined = 'outlined', // border
  contained = 'contained', // default
  light = 'light', // combination of text and default
}

export enum ESizes {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
  xxlarge = 'xxlarge',
  xxxlarge = 'xxxlarge',
}

export enum ETableView {
  normal = 'normal',
  condensed = 'condensed',
}

export enum ETypes {
  info = 'info',
  error = 'error',
  warning = 'warning',
  success = 'success',
}

export enum EPositions {
  top = 'top',
  topright = 'topright',
  topleft = 'topleft',
  bottom = 'bottom',
  bottomright = 'bottom-right',
  bottomleft = 'bottom-left',
}

export enum ELabelPosition {
  top = 'top',
  left = 'left',
  right = 'right',
  bottom = 'bottom',
  formleft = 'form-left', // checkbox
  inside = 'inside',
}

export enum EOrderDirection {
  asc = 'asc',
  desc = 'desc',
}

export interface IOrderedBy {
  [key: string]: keyof typeof EOrderDirection;
}

export interface IFilteredBy {
  [key: string]: string | number | undefined;
}

export interface IPaginationParams {
  take?: number;
  skip: number;
}

export enum DrawerFooterJustify {
  between = 'between',
  end = 'end',
}

export interface IOption<L = string, V = number | string> {
  label: L;
  value: V;
  disabled?: boolean;
  group?: boolean;
  checked?: boolean;
}

export interface IEventTarget {
  target: {
    name: string;
    value: string;
  };
}
