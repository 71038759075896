import React from 'react';
import css from '@/__old/components/Tournaments/TournamentsAll/table.module.scss';
import { Table, TableCell } from '@funfarm/kit';
import TableHeader from '@funfarm/kit/Table/TableHeader';

export const PermissionTableHeader = () => {
  return (
    <TableHeader className={css.tournamentsHeader}>
      <TableCell id="room">
        Рум
        <Table.ActionSort fieldName="room" />
      </TableCell>
      <TableCell colspan={4} id="name">
        Название
        <Table.ActionSort fieldName="name" />
      </TableCell>
      <TableCell colspan={2} id="date_tourney">
        Дата начала
        <Table.ActionSort fieldName="date_tourney" />
      </TableCell>
      <TableCell colspan={2} id="bi">
        Buy-in
        <Table.ActionSort fieldName="bi" />
      </TableCell>
      <TableCell id="answer">
        Ответ
        <Table.ActionSort fieldName="answer" />
      </TableCell>
      <TableCell colspan={3} />
    </TableHeader>
  );
};
