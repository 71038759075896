import { useTranslation } from 'react-i18next';
import { Badge, Box, Checkbox, HStack, Text } from '@/shared/components';
import { useTournamentSelection } from '../../components/tournament-selection';

export const WillPlayTotal = (params: any) => {
  const { t } = useTranslation();

  const { selectedTournamentType, selectedTournamentMap, selectAllTournaments } = useTournamentSelection();

  const isChecked =
    selectedTournamentType === 'will-play' && Object.keys(selectedTournamentMap).length === params.data.total;

  const handleChange = () => selectAllTournaments(isChecked ? [] : params.data.tournaments, 'will-play');

  return (
    <HStack $justifyContent="start" $h="100%" $pl="px">
      <Box $pl={5} $pr={3.5} $flex="none">
        <Checkbox clickAreaSize={{ w: 16, h: 12 }} checked={isChecked} onChange={handleChange} />
      </Box>

      <Text as="span" $fontWeight={600} $color="gray-200" children={t('tournaments.willPlay')} />
      <Badge children={params.data?.total} />
    </HStack>
  );
};
