import type { BreakpointStyles, Color, DefaultTheme, Radius, ResponsiveStyle } from 'styled-components';

export const rem = (px: number) => `${px / 16}rem`;

export const parseCssUnit = (value: string = '') => Number(value.replace(/\D+/g, ''));

export const hexToRgb = (hex: string, opacity?: number) => {
  const rgb = hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16));

  if (!rgb) return;

  if (opacity === undefined) return `rgb(${rgb.join(',')})`;
  else return `rgba(${rgb.join(',')},${opacity})`;
};

export const isBreakpointStyles = <T>(style: ResponsiveStyle<T>): style is BreakpointStyles => {
  return typeof style === 'object';
};

export const filterStyledProps = <T extends object>(props: T) => {
  return Object.fromEntries(Object.entries(props).filter(([key]) => key.startsWith('$')));
};

export const getResponsiveStyleKey = (variant: keyof DefaultTheme['breakpoint'], theme: DefaultTheme) =>
  `@media screen and (min-width: ${theme.breakpoint[variant]})`;

export const parseNumberToRem = (value: number | string | undefined) =>
  typeof value === 'number' ? rem(value) : value;

export const parseSpacingValue = (value: number | string | undefined, theme: DefaultTheme) => {
  if (value === undefined) return;
  return (
    theme.spacing[String(value) as keyof DefaultTheme['spacing']] ||
    (typeof value === 'string' ? value : rem(value))
  );
};

export const parsePaletteColor = (value: string | undefined, theme: DefaultTheme) => {
  if (value === undefined) return;
  return theme.color[value as Color] || value;
};

export const parseRadiusValue = (value: Radius | string | undefined, theme: DefaultTheme) => {
  return theme.radius[value as Radius] || parseSpacingValue(value, theme);
};
