import { forwardRef, useRef } from 'react';
import { styled } from 'styled-components';
import { mergeRefs } from '@/shared/utils/refs';
import * as RadixUISwitch from '@radix-ui/react-switch';
import { Box } from '../box';

const StyledRoot = styled(RadixUISwitch.Root)(({ theme }) => ({
  flex: 'none',
  border: 0,
  padding: 0,
  outline: 'none',
  cursor: 'pointer',
  position: 'relative',
  display: 'block',

  '--thumb-size': theme.spacing['4'],
  '--thumb-offset': '2px',
  '--track-space': theme.spacing['3.5'],

  width: 'calc(var(--thumb-size) + var(--thumb-offset) * 2 + var(--track-space))',
  height: 'calc(var(--thumb-size) + var(--thumb-offset) * 2)',

  backgroundColor: theme.color['gray-600'],
  borderRadius: theme.radius['full'],
  transition: 'background-color 0.4s ease-out',
  willChange: 'background-color',

  '&[data-state="checked"]': {
    backgroundColor: theme.color['accent-500'],
  },

  '&[disabled]': {
    cursor: 'default',
    opacity: 0.64,
  },
}));

const StyledThumb = styled(RadixUISwitch.Thumb)(({ theme }) => ({
  display: 'block',

  width: 'var(--thumb-size)',
  height: 'var(--thumb-size)',

  borderRadius: theme.radius['full'],
  backgroundColor: theme.color['gray-50'],
  transition: `transform .15s ${theme.easing['ease-out-quad']}`,
  transform: 'translateX(var(--thumb-offset))',
  willChange: 'transform',

  '&[data-state="checked"]': {
    transform: 'translateX(calc(var(--track-space) + var(--thumb-offset)))',
  },
}));

export interface SwitchProps extends Omit<RadixUISwitch.SwitchProps, 'children' | 'onChange'> {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>((props, ref) => {
  const { onChange, name, checked, ...rest } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onClick?.(event);
    if (props.onChange) {
      const changeEvent: React.ChangeEvent<HTMLInputElement> = Object.create(event, {
        target: { value: inputRef.current },
      });
      onChange?.(changeEvent);
    }
  };

  return (
    <StyledRoot {...rest} checked={checked} onClick={handleClick}>
      <Box
        as="input"
        type="checkbox"
        ref={mergeRefs([ref, inputRef])}
        name={name}
        checked={checked}
        onChange={onChange} // required to prevent console error
        $display="none"
      />
      <StyledThumb />
    </StyledRoot>
  );
});
