import { useTranslation } from 'react-i18next';
import { useGetEducationCourses } from '@/features/courses/api';
import { EducationCard, TitleWithBackButton } from '@/features/courses/components';
import { styles } from '@/features/courses/styles';
import { ROUTES } from '@/routes';
import { Error, Skeleton } from '@funfarm/kit';

export const EducationCourses = () => {
  const { t } = useTranslation();

  const { data: courses, isLoading, isError } = useGetEducationCourses();

  if (isLoading || !courses) return <Skeleton />;
  if (isError) return <Error message={t('fetchingError')} />;

  return (
    <>
      <TitleWithBackButton title={t('education.courseSelection')} withoutButton />
      <div className={styles.container}>
        {courses?.map((course) => (
          <EducationCard key={course.id} {...course} link={ROUTES.blocksByCourseId(course.id)} />
        ))}
      </div>
    </>
  );
};
