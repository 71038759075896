import type { EducationModule } from '@/features/courses/models';
import type { BlockPageParams } from '@/features/courses/types';
import { ApiRequest } from '@/shared/api';
import { useQuery } from '@tanstack/react-query';

export const useGetEducationModules = ({ courseId, blockId }: BlockPageParams) =>
  useQuery({
    queryKey: ['modules', courseId, blockId],
    queryFn: () =>
      new ApiRequest<EducationModule[]>({
        method: 'GET',
        url: `/api/gamification/way/courses/${courseId}/blocks/${blockId}/modules`,
      }),
  });
