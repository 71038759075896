import React, { CSSProperties, ReactNode, useRef } from 'react';
import classNames from 'classnames';

import { TabBody } from './TabBody';
import { TabHeader } from './TabHeader';
import { TabList } from './TabList';
import { IProviderProps, TabsProvider } from './TabsProvider';

import css from './tabs.module.scss';

interface IProps extends Omit<IProviderProps, 'tabsRef'> {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

const Tabs = (props: IProps) => {
  const { className, style, ...providerProps } = props;
  const tabsRef = useRef(null);

  return (
    <TabsProvider {...{ tabsRef, ...providerProps }}>
      <div
        className={classNames(css.tabs, className)}
        style={style}
        ref={tabsRef}
      >
        {props.children}
      </div>
    </TabsProvider>
  );
};

Tabs.List = TabList;
Tabs.Header = TabHeader;
Tabs.Body = TabBody;

export { Tabs };
