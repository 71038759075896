import React, { HTMLAttributes, MouseEventHandler, ReactElement } from 'react';
import classNames from 'classnames';

import { IconClose } from '@funfarm/kit';
import { EColors, ESizes } from '@funfarm/kit/types';

import css from './badge.module.scss';

interface IProps extends HTMLAttributes<HTMLElement> {
  label?: string;
  color?: keyof typeof EColors;
  size?: keyof typeof ESizes;
  className?: string;
  onDelete?: MouseEventHandler<HTMLElement | SVGElement>;
  disabled?: boolean;
  icon?: ReactElement;
  dataTestId?: string;
}

export const Badge = (props: IProps) => {
  const {
    color = EColors.default,
    size = ESizes.medium,
    label,
    icon,
    onDelete,
    disabled,
    className,
    style,
    onClick,
    ...rest
  } = props;

  return (
    <div
      className={classNames(
        css.badge,
        css[size],
        css[color],
        disabled && css.disabled,
        onClick && css.button,
        className,
        onDelete && !disabled && css.withAction,
      )}
      style={style}
      onClick={onClick}
      {...rest}
    >
      {icon && React.cloneElement(icon, { className: css.icon })}
      <div className={css.message}>{label || props.children}</div>
      {onDelete && !disabled && (
        <IconClose className={css.action} onClick={onDelete} size="xsmall" />
      )}
    </div>
  );
};
