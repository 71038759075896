import { memo } from 'react';
import { Tournament } from '@/features/tournaments/models';
import { Checkbox } from '@/shared/components';
import { TournamentType, useTournamentSelection } from './tournament-selection.context';

interface TournamentCheckboxProps {
  checked: boolean;
  tournament: Tournament;
  type: TournamentType;
  onToggle: (tournament: Tournament, type: TournamentType) => void;
}

const TournamentCheckbox = memo(({ checked, tournament, type, onToggle }: TournamentCheckboxProps) => {
  const handleChange = () => onToggle(tournament, type);
  return <Checkbox clickAreaSize={{ w: 16, h: 12 }} checked={checked} onChange={handleChange} />;
});

interface Props {
  tournament: Tournament;
  isSelectedTournament: boolean;
}

export const TournamentSelector = ({ tournament, isSelectedTournament }: Props) => {
  const { selectedTournamentMap, toggleTournament } = useTournamentSelection();

  const isChecked = !!selectedTournamentMap[tournament.id];

  return (
    <TournamentCheckbox
      tournament={tournament}
      checked={isChecked}
      type={isSelectedTournament ? 'will-play' : 'all'}
      onToggle={toggleTournament}
    />
  );
};
