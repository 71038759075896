export const formatNumber = (
  number: number | string = 0,
  units?: string,
): number | string => {
  if (!number) return '-';

  return `${roundDecimal(Number(number))
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}${units ?? ''}`;
};

export const roundDecimal = (
  number: number | undefined | null = undefined,
  length: number = 1,
): number | string => {
  if (typeof number === 'undefined') {
    return '-';
  }

  if (number === null) {
    return '-';
  }

  if (number < 10) {
    length = 10;
  }

  const rounded = Math.round(number * length) / length;
  if (rounded < 10 && rounded.toString().length === 1) {
    return `${rounded}.0`;
  }
  return rounded;
};

export const formatMonths = (num: number) => {
  const years = Math.floor(num / 12);
  const months = num - years * 12;

  return { years, months };
};
