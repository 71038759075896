import { clsx } from 'clsx';
import styles from './label.module.scss';

type Props = {
  label?: string;
  className?: string;
  required?: boolean;
  htmlFor?: string;
  isError?: boolean;
};

export const Label = ({ label, className, required, htmlFor, isError }: Props) => {
  return label ? (
    <label htmlFor={htmlFor} className={clsx(styles.label, isError && styles.error, className)}>
      {label} {required && <span className={styles.mark}>*</span>}
    </label>
  ) : null;
};
