import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconGrowDown = (props: IconProps) => {
  return (
    <Icon id="IconGrowDown" viewBox="0 0 512 512" {...props}>
      <polygon points="256.5,448.5 448.5,256.5 336.5,256.5 336.5,120 176.5,120 176.5,256.5 64.5,256.5 " />
    </Icon>
  );
};

export default IconGrowDown;
