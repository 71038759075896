import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconClose = (props: IconProps) => {
  return (
    <Icon id="IconClose" {...props}>
      <path d="M4.5755 4.57538C4.28261 4.86827 4.28261 5.34315 4.5755 5.63604L10.9395 12L4.5755 18.364C4.28261 18.6569 4.28261 19.1317 4.5755 19.4246C4.86839 19.7175 5.34327 19.7175 5.63616 19.4246L12.0001 13.0607L18.3641 19.4246C18.657 19.7175 19.1318 19.7175 19.4247 19.4246C19.7176 19.1317 19.7176 18.6569 19.4247 18.364L13.0608 12L19.4247 5.63604C19.7176 5.34315 19.7176 4.86827 19.4247 4.57538C19.1318 4.28248 18.657 4.28248 18.3641 4.57538L12.0001 10.9393L5.63616 4.57538C5.34327 4.28249 4.86839 4.28248 4.5755 4.57538Z" />
    </Icon>
  );
};

export default IconClose;
