import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconSortDown = (props: IconProps) => {
  return (
    <Icon id="IconSortDown" {...props}>
      <path
        d="M8.25 3.75V20.25M8.25 20.25L3.75 15.75M8.25 20.25L12.75 15.75M12.75 11.25H20.25M12.75 8.25H18.25M12.75 5.25H16.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default IconSortDown;
