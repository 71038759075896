import type { Breakpoint, DefaultTheme, FontSize, ResponsiveStyle } from 'styled-components';
import { getResponsiveStyleKey, isBreakpointStyles, parseCssUnit, rem } from '../utils';

const applyFontStyles = (styles: Record<string, unknown>, font: { fontSize: string; lineHeight: string }) => {
  if (!styles.fontSize) styles.fontSize = font.fontSize;
  if (!styles.lineHeight) styles.lineHeight = font.lineHeight;
};

const calculateLineHeight = (size: number) => {
  if (size >= 20) return size + 8;
  if (size >= 14) return size + 4;
  return size + 2;
};

const calculateCustomFont = (size: number | string) => {
  if (typeof size === 'number') return { fontSize: rem(size), lineHeight: rem(calculateLineHeight(size)) };
  else
    return {
      fontSize: size,
      lineHeight: rem(calculateLineHeight(parseCssUnit(size))),
    };
};

const findFont = (value: number | string, theme: DefaultTheme) => {
  return typeof value === 'number'
    ? calculateCustomFont(value)
    : theme.font[value as FontSize] || calculateCustomFont(value);
};

export const applyResponsiveFonts = (
  responsiveStyles: Record<string, unknown>,
  $size: ResponsiveStyle<FontSize | number | string>,
  theme: DefaultTheme,
) => {
  if (isBreakpointStyles($size))
    for (const breakpoint in $size) {
      let styles: Record<string, unknown>;

      if (breakpoint === 'base') styles = responsiveStyles;
      else {
        const key = getResponsiveStyleKey(breakpoint as keyof DefaultTheme['breakpoint'], theme);
        styles = (responsiveStyles[key] ||= {}) as Record<string, unknown>;
      }

      const value = $size[breakpoint as Breakpoint]!;

      const font = findFont(value, theme);
      applyFontStyles(styles as Record<string, unknown>, font);
    }
  else applyFontStyles(responsiveStyles, findFont($size, theme));

  return responsiveStyles;
};
