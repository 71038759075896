import { isObjectWithKeys } from '@/shared/utils/object';
import { ConfigurableColumn } from './table-columns.context';

export enum TournamentTableColumn {
  Room = 'room',
  Name = 'name',
  StartDate = 'start_date',
  Status = 'ratio',
  EndDate = 'end_date',
  BuyIn = 'bi',
  Rank = 'rank',
  Type = 'type',
  Winrate = 'vinrate',
  ROI = 'roi',
  AFS = 'afs',
  TourneyDollar = 'tourney_dollar',
  AboutTournament = 'tournament',
}

export const tableConfigurableColumn: ConfigurableColumn[] = [
  {
    name: TournamentTableColumn.Room,
    index: 0,
    checked: true,
    required: true,
  },
  {
    name: TournamentTableColumn.Name,
    index: 1,
    checked: true,
    required: true,
  },
  {
    name: TournamentTableColumn.StartDate,
    index: 2,
    checked: true,
    required: true,
  },
  {
    name: TournamentTableColumn.BuyIn,
    index: 3,
    checked: true,
    required: true,
  },
  {
    name: TournamentTableColumn.Status,
    index: 4,
    checked: true,
  },
  {
    name: TournamentTableColumn.EndDate,
    index: 5,
    checked: true,
  },
  {
    name: TournamentTableColumn.Rank,
    index: 6,
  },
  {
    name: TournamentTableColumn.Type,
    index: 7,
  },
  {
    name: TournamentTableColumn.Winrate,
    index: 8,
  },
  {
    name: TournamentTableColumn.ROI,
    index: 9,
  },
  {
    name: TournamentTableColumn.AFS,
    index: 10,
  },
  {
    name: TournamentTableColumn.TourneyDollar,
    index: 11,
  },
  {
    name: TournamentTableColumn.AboutTournament,
    index: 12,
  },
];

export const TableColumnsStorage = {
  storageKey: 'tournament:table-settings',

  getColumns: <T extends Record<TournamentTableColumn, ConfigurableColumn>>(): Partial<T> => {
    const data = localStorage.getItem(TableColumnsStorage.storageKey);
    if (!data) return {};
    try {
      return JSON.parse(data) as T;
    } catch (error) {
      console.error('Error parsing configurable columns:', error);
      return {};
    }
  },

  updateColumns: (columns: ConfigurableColumn[]) => {
    const columnObj = columns.reduce(
      (acc, column, index) => {
        acc[column.name] = column;
        acc[column.name].index = index;
        return acc;
      },
      {} as Record<TournamentTableColumn, ConfigurableColumn>,
    );

    const data = JSON.stringify(columnObj);

    localStorage.setItem(TableColumnsStorage.storageKey, data);
  },
};

export const initConfigurableColumns = (): ConfigurableColumn[] => {
  const savedColumns = TableColumnsStorage.getColumns();

  if (!isObjectWithKeys(savedColumns)) return tableConfigurableColumn;

  const columns = tableConfigurableColumn.map((col) => {
    const savedColumn = savedColumns[col.name];
    return {
      ...col,
      ...savedColumn,
      /** we must to be sure that the required columns are always checked */
      checked:
        savedColumn?.checked ||
        tableConfigurableColumn.some((defaultCol) => col.name === defaultCol.name && defaultCol.required),
    };
  });

  columns.sort((column1, column2) => column1.index - column2.index);

  return columns;
};
