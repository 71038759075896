import { ChangeEvent, useMemo, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { format } from "date-fns";
import i18n from "i18next";
import moment from "moment";
import {
  getPlayerStatisticsPostflop,
  getPlayerStatisticsPreflop
} from "@/__old/actions";
import { Helper } from "@/__old/components/Helper";
import {
  ENetwork,
  ETableSize,
  ETourneySpeed,
  IPlayerStatistics,
  IPlayerStatisticsFilters
} from "@/__old/types";
import {
  formatNumber,
  IError,
  IEventTarget,
  localeMap,
  Paper,
  roundDecimal,
  Select,
  Skeleton,
  Table
} from "@funfarm/kit";
import { useQuery } from "@tanstack/react-query";
import css from "./statistic.module.scss";

export const Preflop = () => {
  const { t } = useTranslation();
  const lng = i18n.language.slice(0, 2);
  const selectedLocale = localeMap[lng] ?? localeMap["en"];
  const calendarRef = useRef<DatePicker>(null);

  registerLocale("locale", selectedLocale);

  const [filters, setFilters] = useState<IPlayerStatisticsFilters>({});

  const { isLoading: isLoadingPalyerStatsPre, data: playerStatsPre } = useQuery<
    IPlayerStatistics,
    IError
  >({
    queryKey: ["player", "statistics", "preflop", filters],
    queryFn: () => getPlayerStatisticsPreflop(filters)
  });

  const { isLoading: isLoadingPalyerStatsPost, data: playerStatsPost } =
    useQuery<IPlayerStatistics, IError>({
      queryKey: ["player", "statistics", "postflop", filters],
      queryFn: () => getPlayerStatisticsPostflop(filters)
    });

  const dateOptions = useMemo(() => {
    return [
      { value: "", label: "ALL" },
      { value: "date", label: t("By date") },
      { value: "apr", label: "Апрель" },
      { value: "mar", label: "Март" },
      { value: "feb", label: "Февраль" },
      { value: "jan", label: "Январь" },
      { value: "dec", label: "Декабрь" },
      { value: "nov", label: "Ноябрь" },
      { value: "oct", label: "Октябрь" },
      { value: "sep", label: "Сентябрь" },
      { value: "aug", label: "Август" },
      { value: "jul", label: "Июль" },
      { value: "jun", label: "Июнь" },
      { value: "feb+mar", label: "Февраль + Март" },
      { value: "dec+jan", label: "Декабрь + Январь" },
      { value: "oct+nov", label: "Октябрь + Ноябрь" },
      { value: "sep+aug", label: "Сентябрь + август" },
      { value: "jul+jun", label: "Июнь + июль" }
    ];
  }, [t]);
  const networkOptions = useMemo(() => {
    const rooms = Object.keys(ENetwork).map((room) => ({
      value: room,
      label: t(ENetwork[room as keyof typeof ENetwork])
    }));
    return [{ value: "", label: "ALL" }, ...rooms];
  }, [t]);
  const tableSizeOptions = useMemo(() => {
    const tables = Object.keys(ETableSize)
      .filter((s) => s !== "total")
      .map((size) => ({
        value: size,
        label: t(ETableSize[size as keyof typeof ETableSize])
      }));
    return [{ value: "", label: "ALL" }, ...tables];
  }, [t]);
  const tourneySpeedOptions = useMemo(() => {
    const speeds = Object.keys(ETourneySpeed).map((speed) => ({
      value: speed,
      label: t(ETourneySpeed[speed as keyof typeof ETourneySpeed])
    }));
    return [{ value: "", label: "ALL" }, ...speeds];
  }, [t]);

  const onChangeFilters = (
    event: ChangeEvent<HTMLInputElement> | IEventTarget
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [event.target.name]: event.target.value
    }));
  };

  const onSelectDatePeriod = (
    event: ChangeEvent<HTMLInputElement> | IEventTarget
  ) => {
    const period = event.target.value;

    //setSelectedPeriod(period);

    if (period === "date") {
      calendarRef.current?.setOpen(!calendarRef.current.isCalendarOpen());

      return;
    }

    if (!period) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        month_start_date: undefined,
        month_end_date: undefined
      }));
      return;
    }
    let endDate = moment().endOf("month");
    let startDate = moment().startOf("month");

    switch (period) {
      case "apr":
        startDate = moment("2025-04-01");
        endDate = moment("2025-04-01").endOf("month");
        break;
      case "mar":
        startDate = moment("2025-03-01");
        endDate = moment("2025-03-01").endOf("month");
        break;
      case "feb":
        startDate = moment("2025-02-01");
        endDate = moment("2025-02-01").endOf("month");
        break;
      case "jan":
        startDate = moment("2025-01-01");
        endDate = moment("2025-01-01").endOf("month");
        break;
      case "dec":
        startDate = moment("2024-12-01");
        endDate = moment("2024-12-01").endOf("month");
        break;
      case "nov":
        startDate = moment("2024-11-01");
        endDate = moment("2024-11-01").endOf("month");
        break;
      case "oct":
        startDate = moment("2024-10-01");
        endDate = moment("2024-10-01").endOf("month");
        break;
      case "sep":
        startDate = moment("2024-09-01");
        endDate = moment("2024-09-01").endOf("month");
        break;
      case "aug":
        startDate = moment("2024-08-01");
        endDate = moment("2024-08-01").endOf("month");
        break;
      case "jul":
        startDate = moment("2024-07-01");
        endDate = moment("2024-07-01").endOf("month");
        break;
      case "jun":
        startDate = moment("2024-06-01");
        endDate = moment("2024-06-01").endOf("month");
        break;
      case "jul+jun":
        startDate = moment("2024-06-01");
        endDate = moment("2024-07-01").endOf("month");
        break;
      case "sep+aug":
        startDate = moment("2024-08-01");
        endDate = moment("2024-09-01").endOf("month");
        break;
      case "oct+nov":
        startDate = moment("2024-10-01");
        endDate = moment("2024-11-01").endOf("month");
        break;
      case "dec+jan":
        startDate = moment("2024-12-01");
        endDate = moment("2025-01-01").endOf("month");
        break;
      case "feb+mar":
        startDate = moment("2025-02-01");
        endDate = moment("2025-03-01").endOf("month");
        break;

    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      month_start_date: startDate.format("YYYY-MM-DD"),
      month_end_date: endDate.format("YYYY-MM-DD")
    }));
  };

  const handleChangeDate = (date: Date | null) => {
    if (!date) return;

    setFilters((prevFilters) => ({
      ...prevFilters,
      month_start_date: format(date, "yyyy-MM-dd"),
      month_end_date: format(date, "yyyy-MM-dd")
    }));
  };

  return (
    <Paper className={css.preflop}>
      <div className={css.helper}>
        <Helper path="stats" />
      </div>
      <div className={css.filters}>
        <Select
          name="month_start_date"
          onChange={onSelectDatePeriod}
          options={dateOptions}
          label={t("Date")}
          value={
            filters.month_start_date &&
            format(new Date(filters.month_start_date), "LLLL yy", {
              locale: localeMap[i18n.language]
            })
          }
        />
        <DatePicker
          ref={calendarRef}
          locale="locale"
          selected={new Date(filters.month_start_date ?? Date.now())}
          closeOnScroll={true}
          dateFormat="yyyy-MM-dd"
          showMonthYearPicker
          maxDate={new Date()}
          onChange={handleChangeDate}
          customInput={<></>}
        />
        <Select
          name="network"
          onChange={onChangeFilters}
          options={networkOptions}
          label={t("Room")}
          value={filters["network"]}
        />
        <Select
          name="table_size"
          onChange={onChangeFilters}
          options={tableSizeOptions}
          label={t("Type")}
          value={filters["table_size"]}
        />
        <Select
          name="tourney_speed"
          onChange={onChangeFilters}
          options={tourneySpeedOptions}
          label={t("Speed")}
          value={filters["tourney_speed"]}
        />

        <div className={css.rankLabel}>
          <div className={css.rankLabelTitle}>
            <h1>Hero</h1>
            {/*<IconGrowUp size="medium" className="color-green" />*/}
          </div>
          <div className={css.rankLabelCaption}>
            {formatNumber(playerStatsPre?.hands_count)}
            &nbsp;
            {t("hand", { count: playerStatsPre?.hands_count })}
          </div>
        </div>
      </div>
      <h3 className={classNames(css.header, css.red)}>{t("Preflop")}</h3>
      <Table cells={9} fontSize="small" view="condensed">
        <Table.Row>
          <Table.Cell colspan={2}>&nbsp;</Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            TOT
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            EP
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            MP
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            HJ
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            CO
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            BU
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            SB
          </Table.Cell>
        </Table.Row>
        {isLoadingPalyerStatsPre && (
          <Table.Row>
            <Table.Cell colspan={2} className="table-first">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
          </Table.Row>
        )}
        {playerStatsPre && (
          <>
            <Table.Row>
              <Table.Cell colspan={2} className="table-first">
                RFI TOT
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_total)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_ep)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_mp)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_hj)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_co)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_btn)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_sb)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colspan={2} className="table-first">
                Fvs3B
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.fold_3bet_total)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.fold_3bet_ep)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.fold_3bet_mp)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.fold_3bet_hj)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.fold_3bet_co)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.fold_3bet_btn)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.fold_3bet_sb)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colspan={2} className="table-first">
                4Bet
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre["4bet_total"])}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre["4bet_ep"])}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre["4bet_mp"])}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre["4bet_hj"])}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre["4bet_co"])}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre["4bet_btn"])}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre["4bet_sb"])}
              </Table.Cell>
            </Table.Row>
          </>
        )}
      </Table>

      <div>&nbsp;</div>

      <Table cells={9} fontSize="small" view="condensed">
        <Table.Row>
          <Table.Cell colspan={2}>&nbsp;</Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            TOT
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            MP
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            HJ
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            CO
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            BU
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            SB
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            BB
          </Table.Cell>
        </Table.Row>
        {isLoadingPalyerStatsPre && (
          <Table.Row>
            <Table.Cell colspan={2}>
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
          </Table.Row>
        )}
        {playerStatsPre && (
          <>
            <Table.Row>
              <Table.Cell colspan={2} className="table-first">
                3Bet
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre["3bet_total"])}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre["3bet_mp_without_hj"])}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre["3bet_hj"])}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre["3bet_co"])}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre["3bet_btn"])}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre["3bet_sb"])}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre["3bet_bb"])}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colspan={2} className="table-first">
                CC
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.cc_total)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.cc_mp)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.cc_hj)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.cc_co)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.cc_btn)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.cc_sb)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.cc_bb)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colspan={2} className="table-first">
                VPIP
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.vpip_total)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.vpip_mp)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.vpip_hj)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.vpip_co)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.vpip_btn)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.vpip_sb)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.vpip_bb)}
              </Table.Cell>
            </Table.Row>
          </>
        )}
      </Table>

      <div>&nbsp;</div>

      <Table cells={9} fontSize="small" view="condensed">
        <Table.Row>
          <Table.Cell colspan={2}>&nbsp;</Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            EP
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            MP
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            HJ
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            CO
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            BU
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            SB
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            BB
          </Table.Cell>
        </Table.Row>
        {isLoadingPalyerStatsPre && (
          <Table.Row>
            <Table.Cell colspan={2}>
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
          </Table.Row>
        )}
        {
          // TODO так и не понял какой тут префикс у переменных
          playerStatsPre && (
            <>
              <Table.Row>
                <Table.Cell colspan={2} className="table-first">
                  Def BB 2.0
                </Table.Cell>
                <Table.Cell align="center">
                  {roundDecimal(playerStatsPre["bb_fold_vs_raiser_2bb_ep"])}
                </Table.Cell>
                <Table.Cell align="center">
                  {roundDecimal(playerStatsPre["bb_fold_vs_raiser_2bb_mp"])}
                </Table.Cell>
                <Table.Cell align="center">
                  {roundDecimal(playerStatsPre["bb_fold_vs_raiser_2bb_hj"])}
                </Table.Cell>
                <Table.Cell align="center">
                  {roundDecimal(playerStatsPre["bb_fold_vs_raiser_2bb_co"])}
                </Table.Cell>
                <Table.Cell align="center">
                  {roundDecimal(playerStatsPre["bb_fold_vs_raiser_2bb_btn"])}
                </Table.Cell>
                <Table.Cell align="center">
                  {roundDecimal(playerStatsPre["bb_fold_vs_raiser_2bb_sb"])}
                </Table.Cell>
                <Table.Cell align="center">n/a</Table.Cell>
              </Table.Row>
            </>
          )
        }
      </Table>

      <div>&nbsp;</div>

      <Table cells={9} fontSize="small" view="condensed">
        <Table.Row>
          <Table.Cell colspan={2}>&nbsp;</Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            TOT
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            EP
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            MP
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            HJ
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            CO
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            BU
          </Table.Cell>
          <Table.Cell align="center" className="table-color-red">
            SB
          </Table.Cell>
        </Table.Row>
        {isLoadingPalyerStatsPre && (
          <Table.Row>
            <Table.Cell colspan={2}>
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
            <Table.Cell align="center">
              <Skeleton />
            </Table.Cell>
          </Table.Row>
        )}
        {playerStatsPre && (
          <>
            <Table.Row>
              <Table.Cell colspan={2} className="table-first">
                RFI 15-
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_total_0_15)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_ep_0_15)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_mp_0_15)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_hj_0_15)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_co_0_15)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_btn_0_15)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_sb_0_15)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colspan={2} className="table-first">
                RFI 15-35
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_total_15_35)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_ep_15_35)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_mp_15_35)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_hj_15_35)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_co_15_35)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_btn_15_35)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_sb_15_35)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colspan={2} className="table-first">
                RFI 35-70
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_total_35_70)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_ep_35_70)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_mp_35_70)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_hj_35_70)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_co_35_70)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_btn_35_70)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_sb_35_70)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colspan={2} className="table-first">
                RFI 70+
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_total_70plus)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_ep_70plus)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_mp_70plus)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_hj_70plus)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_co_70plus)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_btn_70plus)}
              </Table.Cell>
              <Table.Cell align="center">
                {roundDecimal(playerStatsPre.rfi_sb_70plus)}
              </Table.Cell>
            </Table.Row>
          </>
        )}
      </Table>

      <h3 className={classNames(css.header, css.blue)}>{t("Postflop")}</h3>

      <div className="row gap1">
        <div className="c1of2">
          <Table cells={5} fontSize="small" view="condensed">
            <Table.Row>
              <Table.Cell colspan={2}>&nbsp;</Table.Cell>
              <Table.Cell colspan={3} className="table-color-blue">
                RvBB
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colspan={2}>&nbsp;</Table.Cell>
              <Table.Cell align="center" className="table-color-red">
                F
              </Table.Cell>
              <Table.Cell align="center" className="table-color-red">
                T
              </Table.Cell>
              <Table.Cell align="center" className="table-color-red">
                R
              </Table.Cell>
            </Table.Row>
            {isLoadingPalyerStatsPost && (
              <Table.Row>
                <Table.Cell colspan={2}>
                  <Skeleton />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton />
                </Table.Cell>
              </Table.Row>
            )}
            {
              // TODO тут тоже не разобрался
              playerStatsPost && (
                <>
                  <Table.Row>
                    <Table.Cell colspan={2} className="table-first">
                      Cbet
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(playerStatsPost.cbet_flop_rfi_vs_bb)}
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(playerStatsPost.cbet_turn_rfi_vs_bb)}
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(playerStatsPost.cbet_river_rfi_vs_bb)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colspan={2} className="table-first">
                      CB-fold
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(playerStatsPost.cbet_flop_fold_rfi_vs_bb)}
                    </Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colspan={2} className="table-first">
                      FvsProbe
                    </Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(
                        playerStatsPost.fold_vs_probe_turn_rfi_vs_bb
                      )}
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(
                        playerStatsPost.fold_vs_probe_river_rfi_vs_bb
                      )}
                    </Table.Cell>
                  </Table.Row>
                </>
              )
            }
          </Table>
        </div>
        <div className="c1of2">
          <Table cells={5} fontSize="small" view="condensed">
            <Table.Row>
              <Table.Cell colspan={2}>&nbsp;</Table.Cell>
              <Table.Cell colspan={3} className="table-color-blue">
                RvCC
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colspan={2}>&nbsp;</Table.Cell>
              <Table.Cell align="center" className="table-color-red">
                F
              </Table.Cell>
              <Table.Cell align="center" className="table-color-red">
                T
              </Table.Cell>
              <Table.Cell align="center" className="table-color-red">
                R
              </Table.Cell>
            </Table.Row>
            {isLoadingPalyerStatsPost && (
              <Table.Row>
                <Table.Cell colspan={2}>
                  <Skeleton />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton />
                </Table.Cell>
              </Table.Row>
            )}
            {
              // TODO расставить переменные
              playerStatsPost && (
                <>
                  <Table.Row>
                    <Table.Cell colspan={2} className="table-first">
                      Cbet
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(playerStatsPost.cbet_flop_rfi_vs_cc)}
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(playerStatsPost.cbet_turn_rfi_vs_cc)}
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(playerStatsPost.cbet_river_rfi_vs_cc)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colspan={2} className="table-first">
                      XF
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(playerStatsPost.check_fold_flop_rfi_vs_cc)}
                    </Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colspan={2} className="table-first">
                      XR
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(playerStatsPost.check_raise_flop_rfi_vs_cc)}
                    </Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colspan={2} className="table-first">
                      Delay
                    </Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(playerStatsPost.delay_turn_rfi_vs_cc)}
                    </Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                  </Table.Row>
                </>
              )
            }
          </Table>
        </div>
      </div>

      <div>&nbsp;</div>

      <div className="row gap1">
        <div className="c1of2">
          <Table cells={5} fontSize="small" view="condensed">
            <Table.Row>
              <Table.Cell colspan={2}>&nbsp;</Table.Cell>
              <Table.Cell colspan={3} className="table-color-blue">
                BBvR
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colspan={2}>&nbsp;</Table.Cell>
              <Table.Cell align="center" className="table-color-red">
                F
              </Table.Cell>
              <Table.Cell align="center" className="table-color-red">
                T
              </Table.Cell>
              <Table.Cell align="center" className="table-color-red">
                R
              </Table.Cell>
            </Table.Row>
            {isLoadingPalyerStatsPost && (
              <Table.Row>
                <Table.Cell colspan={2}>
                  <Skeleton />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton />
                </Table.Cell>
              </Table.Row>
            )}
            {
              // TODO расставить переменные
              playerStatsPost && (
                <>
                  <Table.Row>
                    <Table.Cell colspan={2} className="table-first">
                      XF
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(
                        playerStatsPost.bb_vs_raiser_check_fold_flop
                      )}
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(
                        playerStatsPost.bb_vs_raiser_check_fold_turn
                      )}
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(
                        playerStatsPost.bb_vs_raiser_check_fold_river
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colspan={2} className="table-first">
                      Donk
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(playerStatsPost.bb_vs_raiser_donk_flop)}
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(playerStatsPost.bb_vs_raiser_donk_turn)}
                    </Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colspan={2} className="table-first">
                      XR
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(
                        playerStatsPost.bb_vs_raiser_check_raise_flop
                      )}
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(
                        playerStatsPost.bb_vs_raiser_check_raise_turn
                      )}
                    </Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colspan={2} className="table-first">
                      Probe
                    </Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(playerStatsPost.bb_vs_raiser_probe_turn)}
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(playerStatsPost.bb_vs_raiser_probe_river)}
                    </Table.Cell>
                  </Table.Row>
                </>
              )
            }
          </Table>
        </div>
        <div className="c1of2">
          <Table cells={5} fontSize="small" view="condensed">
            <Table.Row>
              <Table.Cell colspan={2}>&nbsp;</Table.Cell>
              <Table.Cell colspan={3} className="table-color-blue">
                CCvR
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colspan={2}>&nbsp;</Table.Cell>
              <Table.Cell align="center" className="table-color-red">
                F
              </Table.Cell>
              <Table.Cell align="center" className="table-color-red">
                T
              </Table.Cell>
              <Table.Cell align="center" className="table-color-red">
                R
              </Table.Cell>
            </Table.Row>
            {isLoadingPalyerStatsPost && (
              <Table.Row>
                <Table.Cell colspan={2}>
                  <Skeleton />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton />
                </Table.Cell>
              </Table.Row>
            )}
            {
              // TODO расставить переменные
              playerStatsPost && (
                <>
                  <Table.Row>
                    <Table.Cell colspan={2} className="table-first">
                      XF
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(
                        playerStatsPost.cc_vs_raiser_fold_cbet_flop
                      )}
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(
                        playerStatsPost.cc_vs_raiser_fold_cbet_turn
                      )}
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(
                        playerStatsPost.cc_vs_raiser_fold_cbet_river
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colspan={2} className="table-first">
                      Donk
                    </Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colspan={2} className="table-first">
                      Raise CB
                    </Table.Cell>
                    <Table.Cell align="center">
                      {playerStatsPost.stat_cc_vs_raiser_raise_cbet_flop}
                    </Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colspan={2} className="table-first">
                      B v MCB
                    </Table.Cell>
                    <Table.Cell align="center">
                      {roundDecimal(
                        playerStatsPost.stat_cc_vs_raiser_bet_missed_cbet_flop
                      )}
                    </Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                    <Table.Cell align="center"></Table.Cell>
                  </Table.Row>
                </>
              )
            }
          </Table>
        </div>
      </div>
    </Paper>
  );
};
