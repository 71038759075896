import React, {
  ChangeEvent,
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';

import { IconCheckbox, IconRadio } from '@funfarm/kit';
import { ELabelPosition } from '@funfarm/kit/types';

import css from './checkbox.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  label?: string;
  value?: string | number;
  type?: 'checkbox' | 'radio';
  checked?: boolean;
  labelPosition?: keyof typeof ELabelPosition;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onInput?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  error?: string | boolean;
  dataTestId?: string;
}

export const Checkbox = forwardRef(
  (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      name,
      value,
      label,
      checked = false,
      type = 'checkbox',
      labelPosition = ELabelPosition.top,
      error,
      required,
      disabled,
      readonly,
      onChange,
      className,
      style,
      dataTestId,
      ...rest
    } = props;

    const [selected, setSelected] = useState<boolean>(checked);

    useEffect(() => {
      setSelected(checked);
    }, [checked]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (readonly || disabled) return;

      setSelected((prevSel) => !prevSel);

      if (onChange) onChange(event);
    };

    return (
      <div
        className={classNames(
          css.wrapper,
          error && css.error,
          disabled && css.disabled,
          readonly && css.readonly,
          css.labelPosition,
          css[labelPosition],
          className,
        )}
        style={style}
        {...rest}
      >
        {label &&
          [
            ELabelPosition.top,
            ELabelPosition.left,
            ELabelPosition.formleft,
          ].includes(ELabelPosition[labelPosition]) && (
            <label htmlFor={name} className={css.label}>
              {label}
              {required && <span className={css.mark}>*</span>}
            </label>
          )}
        <div className={css.placeholder}>
          <input
            type={type}
            name={name}
            value={value}
            id={name}
            data-testid={dataTestId}
            checked={selected}
            disabled={disabled}
            readOnly={readonly}
            ref={ref}
            onChange={handleChange}
          />
          {type === 'checkbox' ? (
            <IconCheckbox selected={selected} className={css.icon} />
          ) : (
            <IconRadio selected={selected} className={css.icon} />
          )}
        </div>
        {label &&
          [ELabelPosition.right].includes(ELabelPosition[labelPosition]) && (
            <label htmlFor={name} className={css.label}>
              {required && <span className={css.mark}>*</span>}
              {label}
            </label>
          )}
        {
          // error &&
          // <p className={css.error}>{error}</p>
        }
      </div>
    );
  },
);

export default Checkbox;
