import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconPokerDom = (props: IconProps) => {
  return (
    <Icon id="IconPokerDom" {...props} viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.32 5C4.14313 5 4 5.14355 4 5.32V20.5833C4 25.2052 7.78195 28.9872 12.4042 28.9872C16.6382 28.9872 20.1647 25.8136 20.7278 21.728C24.8132 21.1649 27.9865 17.6379 27.9865 13.4043C27.9865 8.782 24.2049 5 19.5827 5H4.32ZM8.63746 20.5833V9.79753C8.63746 9.7091 8.70903 9.63753 8.79746 9.63753H19.5827C21.5228 9.63753 23.3494 11.1565 23.3494 13.4043C23.3494 15.3144 21.9226 17.1337 19.5913 17.1337L16.4695 17.1518C16.2943 17.1526 16.1524 17.2945 16.1512 17.4697L16.1335 20.5916C16.1335 22.9233 14.3143 24.3497 12.4042 24.3497C10.1568 24.3497 8.63746 22.5235 8.63746 20.5833ZM22.47 26.2352C22.47 27.762 23.7077 29 25.2348 29C26.7616 29 27.9996 27.762 27.9996 26.2352C27.9996 24.708 26.7616 23.4703 25.2348 23.4703C23.7077 23.4703 22.47 24.708 22.47 26.2352Z"
        fill="#22B183"
      />
    </Icon>
  );
};
