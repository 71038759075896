import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconSortUp = (props: IconProps) => {
  return (
    <Icon id="IconSortUp" {...props}>
      <path
        d="M8.25 3.75V20.25M8.25 3.75L3.75 8.25M8.25 3.75L12.75 8.25M12.75 12.7511H20.25M12.75 15.75H18.25M12.75 18.7473H16.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default IconSortUp;
