import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconBookmark = (props: IconProps) => {
  return (
    <Icon id="IconBookmark" {...props}>
      <path
        d="M5 5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V21L12 17.5L5 21V5Z"
        stroke="#ACAFB5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
