import React, { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import {
  ArcElement,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  PointElement,
  Title,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getNetwork } from '@/__old/actions';
import { defaultChartOptions } from '@/__old/helpers';
import { EPeriod, INetworkData } from '@/__old/types';
import { Error, IError, Loader, roundDecimal } from '@funfarm/kit';
import { useQuery } from '@tanstack/react-query';

ChartJS.register(
  PointElement,
  Title,
  Legend,
  Filler,
  ArcElement,
  ChartDataLabels,
);

interface IProps {
  period: keyof typeof EPeriod;
}

export const NetworkChart = (props: IProps) => {
  const { period } = props;
  const { t } = useTranslation();

  const { isLoading, data, isError } = useQuery<INetworkData, IError>({
    queryKey: ['select', 'network', period],
    queryFn: () => getNetwork(period),
  });

  const chartOptions: ChartOptions<'doughnut'> = useMemo(
    () => ({
      ...defaultChartOptions,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        datalabels: {
          display: true,
          color: '#FFFFFF',
          formatter: (value, context) => {
            const label = context?.chart?.data?.labels?.[context?.dataIndex];

            return `${label} ${roundDecimal(value, 10)}%`;
          },
        },
      },
      scales: {
        y: {
          border: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          border: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    }),
    [],
  );

  const chartData = useMemo(() => {
    const visibleData = data?.networks?.filter(
      (network) => network.value > 0.5,
    );
    return {
      labels: visibleData?.map((network) => network.title),
      datasets: [
        {
          pointRadius: 0,
          lineTension: 0.4,
          data: visibleData?.map((network) => network.value),
          fill: true,
          borderColor: [
            '#D39B3B',
            '#FFD04C',
            '#BF730E',
            '#9A5030',
            '#6F412D',
            '#594227',
            '#BF5E0E',
          ],
          backgroundColor: [
            '#D39B3B',
            '#FFD04C',
            '#BF730E',
            '#9A5030',
            '#6F412D',
            '#594227',
            '#BF5E0E',
          ],
        },
      ],
    };
  }, [data]);

  if (isLoading)
    return <Loader layout size="small" style={{ color: '#FFDC5D' }} />;

  if (isError) return <Error message={t('No data provided')} />;

  return <Doughnut options={chartOptions} data={chartData} />;
};
