import { useWillPlayTournaments, useWontPlayTournaments } from '@/features/tournaments/api';
import { Tournament } from '@/features/tournaments/models';
import { IconButton } from '@/shared/components';

interface Props {
  isSelectedTournament: boolean;
  tounament: Tournament;
}

export const TournamentAction = ({ isSelectedTournament, tounament }: Props) => {
  const { mutate: willPlay, isPending: isWillPlayPending } = useWillPlayTournaments();
  const { mutate: wontPlay, isPending: isWontPlayPending } = useWontPlayTournaments();

  return isSelectedTournament ? (
    <IconButton
      $variant="filled"
      $icon="minus"
      $colorScheme="red"
      onClick={() => wontPlay([tounament.id])}
      loading={isWontPlayPending}
    />
  ) : (
    <IconButton $variant="filled" $icon="plus" onClick={() => willPlay([tounament])} loading={isWillPlayPending} />
  );
};
