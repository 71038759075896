import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconChevronUp = (props: IconProps) => {
  return (
    <Icon id="IconChevronUp" {...props}>
      <path d="M3.51472 15.7959C3.80761 16.0888 4.28248 16.0888 4.57538 15.7959L12 8.37132L19.4246 15.7959C19.7175 16.0888 20.1924 16.0888 20.4853 15.7959C20.7782 15.503 20.7782 15.0282 20.4853 14.7353L12.5303 6.78033C12.2374 6.48743 11.7626 6.48743 11.4697 6.78032L3.51472 14.7353C3.22182 15.0282 3.22183 15.503 3.51472 15.7959Z" />
    </Icon>
  );
};

export default IconChevronUp;
