import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconFinance = (props: IconProps) => {
  return (
    <Icon id="IconFinance" {...props}>
      <path
        d="M5.14286 12V7.49999H14.2857M14.2857 7.49999V12M14.2857 7.49999H15.4286V3H6.28572V7.49999M5.14286 16.5V21H15.4286M15.4286 12H4V16.5H10.8572M18.6611 13.318C20.4463 15.0754 20.4463 17.9246 18.6611 19.682C16.8758 21.4393 13.9813 21.4393 12.1961 19.682C10.4108 17.9246 10.4108 15.0754 12.1961 13.318C13.9813 11.5607 16.8758 11.5607 18.6611 13.318Z"
        stroke="#ACAFB5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
