import { styled } from 'styled-components';
import { Text, textStyleFunction, type TextStyledProps } from '../text';

const StyledHeading = styled('h4')<TextStyledProps>(textStyleFunction);

StyledHeading.defaultProps = {
  as: 'h4',
  $size: 'xl',
  $fontWeight: 600,
  $color: 'gray-50',
};

export const Heading = StyledHeading;
