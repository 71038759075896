import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconDocumentText = (props: IconProps) => {
  return (
    <Icon id="IconDocumentText" {...props}>
      <path
        d="M9 12H15M9 16H15M17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H12.5858C12.851 3 13.1054 3.10536 13.2929 3.29289L18.7071 8.70711C18.8946 8.89464 19 9.149 19 9.41421V19C19 20.1046 18.1046 21 17 21Z"
        stroke="#ACAFB5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};

export default IconDocumentText;
