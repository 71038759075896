import { forwardRef } from 'react';
import { styled } from 'styled-components';
import { BoxStyledProps, HStack } from '@/shared/components';
import { useInputId } from '@/shared/hooks';
import { filterStyledProps } from '@/theme/utils';
import { truncatedStyles } from '../text/text.styles';
import { Checkbox, CheckboxProps } from './checkbox';

const StyledFormControl = styled(HStack)(({ theme }) => ({
  cursor: 'pointer',
  userSelect: 'none',
  overflow: 'hidden', // required for text truncating
  '&[data-disabled]': {
    cursor: 'default !important',
    '& > span': {
      color: theme.color['gray-400'],
    },
  },

  '&:not([data-disabled]):hover': {
    '& > span': {
      color: theme.color.white,
    },
  },
}));

const StyledLabelText = styled.span(({ theme }) => ({
  flex: 'auto',
  fontFamily: theme.fontFamily.primary,
  fontWeight: 400,
  color: theme.color['gray-100'],
  ...theme.font.md,
  lineHeight: theme.spacing[6],
  ...truncatedStyles,
  transition: `color 0.15s ${theme.easing['ease-in-quart']}`,
}));

interface Props extends Omit<CheckboxProps, 'onChange' | 'checked' | 'value'>, BoxStyledProps {
  label?: string;
  value?: boolean;
  onChange?: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxFormControl = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { label, className, value, onChange, ...rest } = props;

  const id = useInputId(props);

  const boxStyledProps = filterStyledProps(rest);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange?.(!value, event);

  return (
    <StyledFormControl
      as="label"
      htmlFor={id}
      className={className}
      tabIndex={0}
      data-disabled={props.disabled}
      {...boxStyledProps}
    >
      <Checkbox ref={ref} id={id} onChange={handleChange} checked={value} {...rest} />
      {!!label && <StyledLabelText children={label} />}
    </StyledFormControl>
  );
});
