import { type MouseEventHandler, useCallback, useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { AxiosPromise } from 'axios';
import { clsx } from 'clsx';
import { deleteFilters, getRooms, getTourneyTypes, postFilters, putFilters } from '@/__old/actions';
import { TourContext } from '@/__old/components/Tournaments/TourProvider';
import { useOptions } from '@/__old/helpers';
import type { IFilterTemplate } from '@/__old/types';
import { Button, Dialog, Icon, Input, MultiSelect } from '@/shared/components';
import { useBoolean } from '@/shared/hooks';
import { IconClose, type IError, Tabs } from '@funfarm/kit';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import tabsStyles from '../templates.module.scss';
import styles from './TemplateModal.module.scss';

export type Props = {
  template?: IFilterTemplate;
  editIconClassName?: string;
};

export const TemplateModal = ({ template, editIconClassName }: Props) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const [isSubmitted, turnOnSubmitting, turnOffSubmitting] = useBoolean();
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => setIsOpen(false);

  const { setFilterParams } = useContext(TourContext);

  const { data: roomsData, isLoading: isRoomsLoading } = useQuery<any, IError>({
    queryKey: ['rooms'],
    queryFn: getRooms,
  });
  const roomsOptions = useOptions(roomsData, 'id', 'title');

  const { data: tourneyTypesData, isLoading: isTourneyTypesLoading } = useQuery<any, IError>({
    queryKey: ['tourney-types'],
    queryFn: getTourneyTypes,
  });
  const tourneyTypesOptions = useOptions(tourneyTypesData, 'value', 'label');

  const defaultValues: IFilterTemplate = template ?? { name: '', bi_start: '', rooms_id: '', tourney_type: '' };

  const { control, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const handlePromise = (promise: AxiosPromise) =>
    promise
      .then(() => {
        closeModal();
        queryClient.invalidateQueries({ queryKey: ['filters'] });
      })
      .catch((error) => {
        console.error(error);
        return false;
      })
      .finally(turnOffSubmitting);

  const onSubmit = (formData: IFilterTemplate) => {
    turnOnSubmitting();
    return handlePromise(template ? putFilters(formData) : postFilters(formData));
  };

  const handleDelete = () => {
    turnOnSubmitting();
    if (!template) return;
    return handlePromise(deleteFilters(template));
  };

  const handleClear = () => {
    reset(defaultValues);
    closeModal();
  };

  const setTemplate = useCallback(() => {
    const now = new Date().getTime();
    setFilterParams!({
      ...template?.value,
      date_start: now,
      date_end: now + 12 * 3600 * 1000,
    });
  }, [setFilterParams, template?.value]);

  const openEditModal: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  return (
    <>
      {!!template && (
        <Tabs.Header index={template.name} className={tabsStyles.tabHeader}>
          <div onClick={setTemplate} className={tabsStyles.name}>
            {template.name}
          </div>
          <div className={editIconClassName} onClick={openEditModal}>
            <Icon name="pencil" $color="gray-50" />
          </div>
        </Tabs.Header>
      )}
      <Dialog
        open={isOpen}
        onOpenChange={setIsOpen}
        contentClassName={styles.content}
        titleClassName={styles.title}
        trigger={
          template ? undefined : (
            <div className={styles.createBox}>
              <Icon name="plus" $color="gray-50" />
              <div>{t('Create template')}</div>
            </div>
          )
        }
        closeTrigger={<IconClose size="small" fill="#ACAFB5" className={styles.icon} />}
        title={t(template ? 'Edit template' : 'Create template')}
        content={
          <form onSubmit={handleSubmit(onSubmit)} noValidate className={styles.form}>
            <Controller
              control={control}
              name="name"
              rules={{ required: t('tournaments.enterTemplateName') }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('Template name')}
                  placeholder={t('Template name')}
                  error={formState.errors.name?.message}
                  style={{ minWidth: '100%' }}
                  required
                />
              )}
            />
            <div className={styles.description}>{t('tournaments.setValuesForTemplate')}</div>
            <Controller
              control={control}
              name="rooms_id"
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  options={roomsOptions}
                  label={t('Rooms')}
                  placeholder={t('Rooms')}
                  isLoading={isRoomsLoading}
                  contentClassName={clsx(styles.multiSelectContent, 'scrollBox')}
                  fullWidth
                  asModal
                />
              )}
            />
            <Controller
              control={control}
              name="bi_start"
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('Min Buy-in')}
                  placeholder={t('Min Buy-in')}
                  style={{ minWidth: '100%' }}
                />
              )}
            />
            <Controller
              control={control}
              name="tourney_type"
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  options={tourneyTypesOptions.map((item) => ({
                    ...item,
                    label: t('Tourney ' + item.label),
                  }))}
                  label={t('Tourney type')}
                  placeholder={t('Tourney type')}
                  isLoading={isTourneyTypesLoading}
                  contentClassName={clsx(styles.multiSelectContent, 'scrollBox')}
                  fullWidth
                  asModal
                />
              )}
            />
            <div className={styles.buttonBlock}>
              <div>
                {template && (
                  <Button disabled={isSubmitted} onClick={handleDelete} className={styles.delete}>
                    {t('Delete')}
                  </Button>
                )}
              </div>
              <div className={styles.rightButtons}>
                <Button onClick={handleClear} disabled={isSubmitted} className={styles.cancel}>
                  {t('Cancel')}
                </Button>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  type="submit"
                  disabled={isSubmitted || !formState.isValid}
                >
                  {t(template ? 'Save template' : 'Create template')}
                </Button>
              </div>
            </div>
          </form>
        }
      />
    </>
  );
};
