import { Link } from 'react-router-dom';
import { clsx } from 'clsx';
import { Icon } from '@/shared/components';
import styles from './Breadcrumbs.module.scss';

type Props = {
  links: { href?: string; title: string }[];
};

export const Breadcrumbs = ({ links }: Props) => (
  <div className={styles.root}>
    {links.map(({ href, title }, i) => {
      const className = clsx(styles.link, i === links.length - 1 && styles.active);

      return (
        <div key={title} className={styles.item}>
          {i !== 0 && <Icon name="hyphen" $color="gray-400" />}
          {href ? (
            <Link to={href} className={className}>
              {title}
            </Link>
          ) : (
            <div className={className}>{title}</div>
          )}
        </div>
      );
    })}
  </div>
);
