import type { Breakpoint, DefaultTheme, ResponsiveStyle } from 'styled-components';
import { isObjectWithKeys } from '@/shared/utils/object';
import { getResponsiveStyleKey, isBreakpointStyles } from '../utils';
import { sharedStyledPropertyMap } from './shared-properties';

export type MarginStyleProps = Partial<
  Record<'$m' | '$mt' | '$mb' | '$ml' | '$mr' | '$mx' | '$my', ResponsiveStyle<string | number>>
>;

export type PaddingStyleProps = Partial<
  Record<'$p' | '$pt' | '$pb' | '$pl' | '$pr' | '$px' | '$py', ResponsiveStyle<string | number>>
>;

const returnValue = <T>(value: T) => value;

/** Generate responsive style map (it is an object with breakpoint as keys and css styles as values) */
const getResponsiveStyleMap = <
  T,
  S extends Record<string, ResponsiveStyle<T>>,
  R extends Record<Breakpoint, React.CSSProperties>,
>(
  theme: DefaultTheme,
  styles: S,
): R => {
  const responsiveStyleMap = {
    base: {},
    xs: {},
    sm: {},
    md: {},
    lg: {},
    xl: {},
  } as R;

  for (const key in styles) {
    /** ignore other properties (only properties that starts from $ allowed) */
    if (key[0] !== '$') continue;

    const value = styles[key] as ResponsiveStyle<T>;

    const { property = key, parser = returnValue } = sharedStyledPropertyMap[key] || {};

    if (isBreakpointStyles(value))
      for (const breakpoint in value)
        (responsiveStyleMap[breakpoint as Breakpoint] as Record<string, unknown>)[property] = parser(
          value[breakpoint as Breakpoint] as Parameters<typeof parser>[0],
          theme,
        );
    else
      (responsiveStyleMap.base as Record<string, unknown>)[property] = parser(
        value as Parameters<typeof parser>[0],
        theme,
      );
  }

  return responsiveStyleMap;
};

/** Generate responsive styled-components styles */
const getResponsiveStyles = <M extends Record<Breakpoint, React.CSSProperties>>(
  theme: DefaultTheme,
  responsiveStyleMap: M,
): Record<string, unknown> => {
  const responsiveStyles: Record<string, unknown> = {};

  for (const breakpoint in responsiveStyleMap) {
    const styles = responsiveStyleMap[breakpoint as Breakpoint];

    const hasStyles = isObjectWithKeys(styles);
    if (!hasStyles) continue;

    let stylesRoot = responsiveStyles;
    if ((breakpoint as Breakpoint) !== 'base') {
      const key = getResponsiveStyleKey(breakpoint as keyof DefaultTheme['breakpoint'], theme);
      stylesRoot = responsiveStyles[key] = {};
    }

    Object.assign(stylesRoot, styles);
  }

  return responsiveStyles;
};

export const generateResponsiveStyles = <T, S extends Record<string, ResponsiveStyle<T>>>(
  theme: DefaultTheme,
  styles: S,
): Record<string, unknown> => {
  const responsiveStyleMap = getResponsiveStyleMap(theme, styles);
  return getResponsiveStyles(theme, responsiveStyleMap);
};
