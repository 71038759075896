import React, {
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  useCallback,
  useRef,
} from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import i18n from 'i18next';
import { Icon, Input } from '@/shared/components';
import { localeMap } from '@funfarm/kit';
import './date-picker.scss';

interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  value?: Date | number;
  label?: string;
  onChange?: (date: Date | null | number) => void;
  onFocus?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  onInput?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  error?: string;
  displayError?: boolean;
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  dateFormat?: string;
  maxDate?: Date;
  minDate?: Date;
  showTimeInput?: boolean;
  timeInputLabel?: string;
  shouldCloseOnSelect?: boolean;
}

export const DatePicker = forwardRef((props: IProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    onChange,
    dateFormat = 'dd.MM.yyyy',
    minDate,
    maxDate,
    showTimeInput,
    timeInputLabel,
    shouldCloseOnSelect = false,
    value: _,
    ...rest
  } = props;
  const value = props.value ? (typeof props.value === 'number' ? new Date(props.value) : new Date()) : new Date();

  const lng = i18n.language.slice(0, 2);
  const selectedLocale = localeMap[lng] ?? localeMap['en'];

  registerLocale('locale', selectedLocale);

  const calendarRef = useRef<ReactDatePicker>(null);

  const handleChange = useCallback(
    (date: Date | null) => {
      onChange && onChange(date?.getTime() ?? null);
    },
    [onChange],
  );

  return (
    <ReactDatePicker
      ref={calendarRef}
      locale="locale" // from i18n
      required={props.required}
      readOnly={props.readonly}
      selected={value}
      closeOnScroll
      {...{ dateFormat, minDate, maxDate, showTimeInput, timeInputLabel }}
      onChange={handleChange}
      shouldCloseOnSelect={shouldCloseOnSelect}
      wrapperClassName="datepicker-wrapper"
      calendarClassName="datepicker-calendar"
      customInput={
        <Input
          className="datepicker-input"
          ref={ref}
          leftIcon={
            <Icon
              name="calendar"
              $size={20}
              onClick={() => calendarRef.current?.setOpen(!calendarRef.current.isCalendarOpen())}
            />
          }
          value={value ? format(value, dateFormat) : ''}
          {...rest}
        />
      }
    />
  );
});
