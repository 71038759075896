import React, { HTMLAttributes, useContext } from 'react';
import classNames from 'classnames';

import { TabsContext } from '@funfarm/kit/Tabs/TabsProvider';

import css from './tabs.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  index: number | string;
}

export const TabBody = (props: Props) => {
  const { index, className, style, ...rest } = props;
  const { tabChecked } = useContext(TabsContext);

  if (index !== tabChecked) return null;

  return (
    <div
      {...rest}
      className={classNames(
        css.tabBody,
        tabChecked === index && css.active,
        className,
      )}
      style={style}
    >
      {props.children}
    </div>
  );
};

export default TabBody;
