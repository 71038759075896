import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { PageHeading } from './PageHeading/PageHeading';

export const EducationLayout = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeading title={t('education.ffEducation')} tag="h4" />
      <Outlet />
    </>
  );
};
