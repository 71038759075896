import { forwardRef } from 'react';
import { keyframes, styled } from 'styled-components';
import { Icon, IconProps } from '../icon';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledWrapper = styled.span`
  display: inline-block;
  & > svg {
    display: block;
    animation: ${spin} 0.64s linear infinite;
  }
`;

interface Props extends Omit<IconProps, 'name'> {}

export const Spinner = forwardRef<SVGSVGElement, Props>((props, ref) => {
  return (
    <StyledWrapper>
      <Icon ref={ref} {...props} name="spinner" />
    </StyledWrapper>
  );
});
