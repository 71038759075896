import { NavLink } from 'react-router-dom';
import css from './PageHeading.module.scss';

type Props = {
  title?: string;
  tabs?: {
    to: string;
    title: string;
  }[];
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export const PageHeading = ({ title, tabs, tag }: Props) => {
  const Tag = tag || 'h1';

  return (
    <section className={css.header}>
      {!!title && <Tag>{title}</Tag>}

      {tabs && (
        <div className={css.tabs}>
          {tabs.map((tab, index) => (
            <NavLink
              key={index}
              to={tab.to}
              end
              className={({ isActive }) => `${css.tab} ${isActive ? css.active : ''}`}
            >
              {tab.title}
            </NavLink>
          ))}
        </div>
      )}
    </section>
  );
};
