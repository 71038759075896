import { useTranslation } from 'react-i18next';
import type { EducationModule } from '@/features/courses/models';
import styles from './PageHeader.module.scss';

type Props = {
  modules: EducationModule[];
};

export const PageHeader = ({ modules }: Props) => {
  const { t } = useTranslation();

  let doneModulesCount = 0;
  modules.some((module) => {
    const isCurrentModule = module.is_available && !module.is_done;
    if (isCurrentModule) doneModulesCount = module.number - 1;
    return isCurrentModule;
  });

  const totalModulesCount = modules.at(-1)?.number || 1;

  return (
    <div className={styles.header}>
      <div className={styles.progressBlock}>
        <div>{`${doneModulesCount} ${t('education.modules', { count: doneModulesCount })} ${t('education.from')} ${totalModulesCount}`}</div>
        <div className={styles.progressTrack}>
          <div
            className={styles.progressThumb}
            style={{
              width: `${Math.round((100 / totalModulesCount) * doneModulesCount)}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
};
