import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import { IOption } from '@funfarm/kit/types';
import { IconFilter, TableContext, useOnClickOutside } from '@funfarm/kit';

import { ByValues } from './ByValues';

import css from './filter.module.scss';

export interface IFilterProps {
  fieldName: string;
  options: IOption[];
  children?: ReactNode;
}

const ActionFilter = (props: IFilterProps) => {
  const { fieldName, options } = props;
  const { filteredBy, addFilterValues } = useContext(TableContext);
  const containerRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    addFilterValues && addFilterValues(fieldName, options);
  }, [fieldName, options, addFilterValues]);

  useEffect(() => {
    setOpen(false);
  }, [filteredBy]);

  useOnClickOutside(containerRef, async () => setOpen(false));

  return (
    <div className={css.container} ref={containerRef}>
      <IconFilter
        className={classNames(
          css.filter,
          filteredBy && !!filteredBy[fieldName] && css.active,
        )}
        onClick={() => setOpen((open) => !open)}
      />
      {
        open && <div className={css.actionContainer}>{props.children}</div>

        // <div className={css.inputContainer}>
        //     <Input
        //         value={value}
        //         onChange={(e: ChangeEvent<HTMLInputElement>) => filterBy(fieldName, e.target.value)}
        //         iconRight={<IconClose size="small" onClick={() => filterBy(fieldName, '')} className={css.clearFilter} />}
        //         className={css.input} />
        // </div>
      }
    </div>
  );
};

ActionFilter.ByValues = ByValues;

export { ActionFilter };
