import { FC } from 'react';
import classNames from 'classnames';
import { PaletteName } from '@/__old/components/Tournaments/TourProvider';
import {
  paletteColor,
  PaletteColorType,
} from '@/__old/components/Tournaments/TourProvider';
import { Row } from '@funfarm/kit';
import css from '../settings.module.scss';

type ColorRowProps = {
  paletteName: PaletteName;
};

const paletteColorTypes: PaletteColorType[] = Object.keys(
  paletteColor,
) as PaletteColorType[];

const ColorRow: FC<ColorRowProps> = ({ paletteName }) => {
  return (
    <Row className={css.paletteColor_row}>
      {paletteColorTypes.map((colorType, index) => {
        return (
          <Row key={index} className={css.paletteColor_item}>
            <div
              className={classNames(
                css.paletteColor_color,
                css[`color-${paletteColor[colorType]}-${paletteName}`],
              )}
            />
            <div className={classNames(css.paletteColor_title)}>
              {colorType.charAt(0).toUpperCase() + colorType.slice(1)}
            </div>
          </Row>
        );
      })}
    </Row>
  );
};

export default ColorRow;
