import { forwardRef, PropsWithChildren, Ref } from 'react';

import { Backdrop } from '../Backdrop';
import { Button } from '../Button';
import { IconClose } from '../Icon';

import css from './drawer.module.scss';

type DrawerProps = PropsWithChildren & {
  open?: boolean;
  onClose?: () => void;
  position?: 'left' | 'right';
  // Ref type should be updated to match the DOM element you want to reference
  ref?: Ref<HTMLDivElement>;
};

// Use React.forwardRef to forward the ref to the div element inside the Drawer component
const Drawer = forwardRef<HTMLDivElement, DrawerProps>(
  ({ open, onClose, position = 'left', children }, ref) => {
    const drawerClass = `${css.drawer} ${open ? css.open : ''} ${
      position === 'right' ? css.drawerRight : ''
    }`;

    return (
      <>
        <Backdrop open={open} animation className={css.backdrop} />
        <div className={drawerClass} ref={ref}>
          <Button
            view="outlined"
            color="secondary"
            size="xlarge"
            className={css.drawerClose}
            icon={<IconClose fill="white" size="xlarge" />}
            onClick={onClose}
          />
          <div className={css.drawerContent}>{children}</div>
        </div>
      </>
    );
  },
);

export default Drawer;
