import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

import css from './paper.module.scss';

interface IProps {
  children: ReactNode;
  header?: string | ReactElement;
  className?: string;
  style?: CSSProperties;
  helper?: ReactElement;
}

export const Paper = (props: IProps) => {
  const { className, header, helper, style } = props;

  return (
    <div className={classNames(css.paper, className)} style={style}>
      {header && (
        <h3 className={css.header}>
          {header}
          {helper && React.cloneElement(helper, { className: css.helper })}
        </h3>
      )}
      {props.children}
    </div>
  );
};
