import { type Color, type FontSize, type ResponsiveStyle, styled, StyleFunction } from 'styled-components';
import { applyResponsiveFonts, generateResponsiveStyles, type MarginStyleProps } from '@/theme/resources';
import { getLineClampStyles, truncatedStyles } from './text.styles';

export interface TextStyledProps extends MarginStyleProps {
  $size?: ResponsiveStyle<FontSize>;
  $plain?: boolean;
  $truncated?: boolean;
  $lineClamp?: number;

  $fontSize?: ResponsiveStyle<React.CSSProperties['fontSize']>;
  $lineHeight?: ResponsiveStyle<React.CSSProperties['lineHeight']>;
  $color?: ResponsiveStyle<Color | 'transparent' | 'inherit'>;
  $fontWeight?: ResponsiveStyle<React.CSSProperties['fontWeight']>;
  $textAlign?: ResponsiveStyle<React.CSSProperties['textAlign']>;
  $whiteSpace?: ResponsiveStyle<React.CSSProperties['whiteSpace']>;
  $textTransform?: ResponsiveStyle<React.CSSProperties['textTransform']>;
}

export const textStyleFunction: StyleFunction<TextStyledProps> = ({
  theme,
  $size = 'md',
  $plain,
  $truncated,
  $lineClamp,
  ...rest
}) => {
  const responsiveStyles = generateResponsiveStyles(theme, rest);
  if (!$plain) applyResponsiveFonts(responsiveStyles, $size, theme);
  return {
    fontFamily: $plain ? undefined : theme.fontFamily.primary,
    color: $plain ? undefined : theme.color['gray-200'],
    ...($truncated && truncatedStyles),
    ...($lineClamp && getLineClampStyles($lineClamp)),
    ...responsiveStyles,
  };
};

const StyledText = styled.p<TextStyledProps>(textStyleFunction);

export const Text = StyledText;
