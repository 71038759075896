import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconSpinner = (props: IconProps) => {
  return (
    <Icon id="IconSpinner" {...props}>
      <circle
        r="10"
        cx="12"
        cy="12"
        fill="none"
        strokeWidth="2"
        stroke="currentColor"
        strokeDasharray="30 12"
      />
    </Icon>
  );
};

export default IconSpinner;
