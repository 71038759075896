import { styled } from 'styled-components';
import { BoxStyledProps, boxStyleFunction } from '../box';

export const Paper = styled.div<BoxStyledProps>(boxStyleFunction);

Paper.defaultProps = {
  $bgColor: 'canvas',
  $borderRadius: 'md',
  $p: 8,
};
