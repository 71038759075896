import { useMemo } from 'react';
import { themeQuartz } from 'ag-grid-community';
import { useTheme } from 'styled-components';
import { rem } from '@/theme/utils';

export const useTableTheme = () => {
  const theme = useTheme();

  return useMemo(
    () =>
      themeQuartz.withParams({
        accentColor: theme.color['accent-500'],
        wrapperBorder: false,
        spacing: rem(8),

        backgroundColor: theme.color.canvas,
        foregroundColor: theme.color['gray-400'],
        fontSize: theme.font.md.fontSize,
        fontFamily: theme.fontFamily.primary,
        rowBorder: { style: 'solid', width: 2, color: theme.color['canvas-500'] },
        rowHeight: theme.spacing[12],

        borderColor: theme.color['gray-600'],
        headerBackgroundColor: theme.color.canvas,
        headerTextColor: theme.color['gray-400'],
        headerFontSize: theme.font.sm.fontSize,
        headerFontWeight: 500,
        // headerColumnBorder // don't work because of thin column resize button
        headerRowBorder: { style: 'solid', width: 2, color: theme.color['canvas-500'] },
        headerColumnResizeHandleHeight: 36,
        headerColumnResizeHandleWidth: 2,
        headerColumnResizeHandleColor: theme.color['gray-600'],
        headerHeight: theme.spacing[11],
      }),
    [theme],
  );
};
