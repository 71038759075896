import { IOption } from '@funfarm/kit/types';

type IOptionValue = IOption['value'];
type InputValue = string | IOptionValue[] | undefined;

export const convertInputValue: (value: InputValue) => IOptionValue[] = (
  value: InputValue,
): IOptionValue[] => {
  if (value) {
    if (typeof value === 'string') return value.split(',');

    return value;
  }

  return [];
};
