import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPreflopSummary } from '@/__old/actions';
import { Helper } from '@/__old/components/Helper';
import { IPreflopSummary } from '@/__old/types';
import {
  Bar,
  EColors,
  IError,
  Paper,
  roundDecimal,
  Row,
  Skeleton,
} from '@funfarm/kit';
import { useQuery } from '@tanstack/react-query';
import css from './info.module.scss';

export const EVChart = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery<IPreflopSummary, IError>({
    queryKey: ['preflop', 'summary'],
    queryFn: getPreflopSummary,
  });

  return (
    <Paper
      helper={<Helper path={`infoEV`} />}
      header={t('EVs')}
      className={css.evChart}
    >
      <div className={css.bars}>
        <h4>{t('EV weighted')}</h4>
        <Row gap={1} align="center" className={css.bar}>
          <span>{t('Hero')}</span>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Bar
              view="solid"
              value={roundDecimal(data?.wr_ev_100k) as number}
              amount={10}
              showValues
            />
          )}
        </Row>
        <Row gap={1} align="center" className={css.bar}>
          <span>{t('Rang')}</span>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Bar
              view="solid"
              color={EColors.secondary}
              value={roundDecimal(data?.rang_avg_wr_ev_3m) as number}
              amount={10}
              showValues
            />
          )}
        </Row>

        <h4>{t('EV hard')}</h4>
        <Row gap={1} align="center" className={css.bar}>
          <span>{t('Hero')}</span>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Bar
              view="solid"
              value={roundDecimal(data?.ev_hard_100k) as number}
              amount={10}
              showValues
            />
          )}
        </Row>
        <Row gap={1} align="center" className={css.bar}>
          <span>{t('Rang')}</span>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Bar
              view="solid"
              color={EColors.secondary}
              value={roundDecimal(data?.rang_avg_ev_hard_3m) as number}
              amount={10}
              showValues
            />
          )}
        </Row>

        <h4>{t('EV medium')}</h4>
        <Row gap={1} align="center" className={css.bar}>
          <span>{t('Hero')}</span>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Bar
              view="solid"
              value={roundDecimal(data?.ev_medium_100k) as number}
              amount={10}
              showValues
            />
          )}
        </Row>
        <Row gap={1} align="center" className={css.bar}>
          <span>{t('Rang')}</span>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Bar
              view="solid"
              color={EColors.secondary}
              value={roundDecimal(data?.rang_avg_ev_medium_3m) as number}
              amount={10}
              showValues
            />
          )}
        </Row>

        <h4>{t('EV easy')}</h4>
        <Row gap={1} align="center" className={css.bar}>
          <span>{t('Hero')}</span>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Bar
              view="solid"
              value={data?.ev_easy_100k as number}
              amount={10}
              showValues
            />
          )}
        </Row>
        <Row gap={1} align="center" className={css.bar}>
          <span>{t('Rang')}</span>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Bar
              view="solid"
              color={EColors.secondary}
              value={data?.rang_avg_ev_easy_3m as number}
              amount={10}
              showValues
            />
          )}
        </Row>

        <h4>{t('EV 10lvl+')}</h4>
        <Row gap={1} align="center" className={css.bar}>
          <span>{t('Hero')}</span>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Bar
              view="solid"
              value={roundDecimal(data?.ev_late_100k) as number}
              amount={10}
              showValues
            />
          )}
        </Row>
        <Row gap={1} align="center" className={css.bar}>
          <span>{t('Rang')}</span>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Bar
              view="solid"
              color={EColors.secondary}
              value={roundDecimal(data?.rang_avg_ev_late_3m) as number}
              amount={10}
              showValues
            />
          )}
        </Row>
      </div>
    </Paper>
  );
};
