interface ILocalStorage {
  _storageKey: string;
  setItem(value: string): void;
  getItem(): string | null;
  removeItem(): void;
}

type TStorage = {
  readonly storageKey: string;
};

export class LocalStorage implements ILocalStorage {
  _storageKey: string;

  public constructor({ storageKey }: TStorage) {
    this._storageKey = storageKey;
  }

  public setItem(value: string) {
    localStorage.setItem(this._storageKey, value);
  }

  public getItem() {
    return localStorage.getItem(this._storageKey);
  }

  public removeItem() {
    localStorage.removeItem(this._storageKey);
  }
}

export const navigationStorage = new LocalStorage({
  storageKey: 'navigationState',
});
export const tableStorage = new LocalStorage({
  storageKey: 'tableSettings-v4',
});
export const tourneysColumnStateStorage = new LocalStorage({
  storageKey: 'tourneysColumnState-v4',
});
