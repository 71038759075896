import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconFilter = (props: IconProps) => {
  return (
    <Icon id="IconFilter" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3.50006C1 2.94778 1.44772 2.50006 2 2.50006H22C22.5523 2.50006 23 2.94778 23 3.50006C23 4.05235 22.5523 4.50006 22 4.50006H2C1.44772 4.50006 1 4.05235 1 3.50006ZM10.5 12.7501C10.5 9.85057 12.8505 7.50005 15.75 7.50005C18.6495 7.50005 21 9.85057 21 12.7501C21 13.9681 20.5852 15.0892 19.8892 15.9799L22.7096 18.8207C23.0988 19.2127 23.0965 19.8458 22.7046 20.2349C22.3126 20.6241 21.6795 20.6218 21.2904 20.2299L18.3777 17.2962C17.6049 17.7438 16.7073 18.0001 15.75 18.0001C12.8505 18.0001 10.5 15.6495 10.5 12.7501ZM15.75 9.50005C13.9551 9.50005 12.5 10.9551 12.5 12.7501C12.5 14.545 13.9551 16.0001 15.75 16.0001C17.5449 16.0001 19 14.545 19 12.7501C19 10.9551 17.5449 9.50005 15.75 9.50005ZM1 11.5001C1 10.9478 1.44772 10.5001 2 10.5001H7.5C8.05228 10.5001 8.5 10.9478 8.5 11.5001C8.5 12.0523 8.05228 12.5001 7.5 12.5001H2C1.44772 12.5001 1 12.0523 1 11.5001ZM1 19.5C1 18.9478 1.44772 18.5001 2 18.5001H7.5C8.05228 18.5001 8.5 18.9478 8.5 19.5C8.5 20.0523 8.05228 20.5 7.5 20.5H2C1.44772 20.5 1 20.0523 1 19.5Z"
      />
    </Icon>
  );
};

export default IconFilter;
