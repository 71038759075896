import React, { useState } from 'react';
import classNames from 'classnames';
import { getLimits } from '@/__old/actions/tournaments';
import { LimitsTableHeader } from '@/__old/components/Tournaments/TournamentsLimits/LimitsTableHeader';
import { LimitsTableRow } from '@/__old/components/Tournaments/TournamentsLimits/LimitsTableRow';
import { ILimitsParams } from '@/__old/types/index';
import {
  Error,
  IconPokerDom,
  IconViewGrid,
  IError,
  Loader,
  Table,
  Tooltip,
} from '@funfarm/kit';
import IconDeviceMobile from '@funfarm/kit/Icon/icons/DeviceMobile';
import { useQuery } from '@tanstack/react-query';
import tournamentsCss from '../tournaments.module.scss';
import css from './limits.module.scss';

interface GroupedRoom {
  room: string;
  data: ILimitsParams[];
}

export const mapTypeToString = (type: string): JSX.Element => {
  switch (type) {
    case 'ko':
      return <div>KO</div>;
    case 'freeze_out':
      return <div>Фризаут</div>;
    case 'ko_rebuy':
      return (
        <div>
          KO-Ребайник
          <Tooltip
            className={css.tooltip}
            description={
              'Ребайник - это турнир, в котором сразу же после начала можно докупить дополнительные фишки. Плюс есть аддон – возможность купить фишки в перерыве по улучшенному курсу.  Другие турниры, например, где можно вылететь и сделать реентри - это турниры с реентри и к ребайникам отношения не имеют.'
            }
          />
        </div>
      );
    case 'freeze_out_rebuy':
      return (
        <div>
          Фризаут-Ребайник
          <Tooltip
            className={css.tooltip}
            description={
              'Ребайник - это турнир, в котором сразу же после начала можно докупить дополнительные фишки. Плюс есть аддон – возможность купить фишки в перерыве по улучшенному курсу.  Другие турниры, например, где можно вылететь и сделать реентри - это турниры с реентри и к ребайникам отношения не имеют.'
            }
          />
        </div>
      );
    default:
      return <div>{type}</div>;
  }
};

export const TournamentsLimits = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // Функция для группировки данных по румам
  const groupByRoom = (data: ILimitsParams[]): GroupedRoom[] => {
    const pdRoom: ILimitsParams[] = [];
    const mobileAppsRoom: ILimitsParams[] = [];
    const otherRoom: ILimitsParams[] = [];

    data.forEach((item) => {
      if (item.room === 'PD ₽') {
        pdRoom.push(item);
      } else if (item.room === 'Mobile Apps') {
        mobileAppsRoom.push(item);
      } else if (item.room === 'Другое') {
        otherRoom.push(item);
      }
    });

    return [
      { room: 'PD ₽', data: pdRoom },
      { room: 'Mobile Apps', data: mobileAppsRoom },
      { room: 'Другое', data: otherRoom },
    ];
  };

  const { isLoading, isError, error, data, isFetching } = useQuery<
    ILimitsParams[],
    IError
  >({
    queryKey: ['limits'],
    queryFn: getLimits,
  });

  if (isError) return <Error layout {...error} />;
  if (isLoading)
    return (
      <Loader
        size="small"
        caption="Fetching table data..."
        className={css.loader}
      />
    );

  // Проверка на undefined, чтобы избежать ошибок при вызове groupByRoom
  const groupedRooms = groupByRoom(data ?? []);

  return (
    <section className={tournamentsCss.tournaments_section}>
      <h2>Лимиты для румов, которых нет в основной таблице</h2>
      <div className={isFetching && css.fetching}>
        {groupedRooms.map((group, i) => {
          const isActive = activeIndex === i;
          return (
            <div key={i} className={css.limitsAccordion}>
              <div onClick={() => handleClick(i)} className={css.limitsHeader}>
                {group.room === 'PD ₽' && <IconPokerDom size="xxlarge" />}
                {group.room === 'Mobile Apps' && (
                  <IconDeviceMobile size="xxlarge" />
                )}
                {group.room === 'Другое' && <IconViewGrid size="xxlarge" />}
                <h3
                  className={classNames(
                    css.limitsTitle,
                    isActive && css.limitsTitleOpen,
                  )}
                >
                  {group.room}
                </h3>
              </div>
              {isActive && (
                <div className={css.limitsContent}>
                  <Table cells={14} className={css.limitsTable}>
                    <LimitsTableHeader />
                    {group.data.map((limit, j) => (
                      <LimitsTableRow key={j} limit={limit} />
                    ))}
                  </Table>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
};
