export const money = (
  amount: number | string,
  currency: string,
  maximumFractionDigits: number = 2,
) => {
  try {
    return Intl.NumberFormat('en', {
      currency,
      style: 'currency',
      minimumFractionDigits: 0,
      maximumFractionDigits,
    }).format(Number(amount));
  } catch (e) {
    return String(amount);
  }
};

export const symbolToCurrencyCode = (symbol: string): string => {
  const symbolMap: Record<string, string> = {
    $: 'USD',
    '€': 'EUR',
    '£': 'GBP',
    '¥': 'JPY',
    '₹': 'INR',
    '₽': 'RUB',
    '₩': 'KRW',
    '₫': 'VND',
    '₪': 'ILS',
    '₴': 'UAH',
    '₺': 'TRY',
    '฿': 'THB',
    '₦': 'NGN',
    R$: 'BRL',
    C$: 'CAD',
    A$: 'AUD',
    NZ$: 'NZD',
    HK$: 'HKD',
    S$: 'SGD',
    CHF: 'CHF', // Swiss Franc
    ZAR: 'ZAR', // South African Rand
  };

  return symbolMap[symbol] || 'USD';
};
