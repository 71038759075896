import { useEffect, useMemo } from 'react';
import { getFinanceRooms } from '@/__old/actions/finances';
import { useQuery } from '@tanstack/react-query';

const useFinanceRoom = (roomId: number) => {
  const { isLoading, data } = useQuery({
    queryKey: ['rooms', 'finances'],
    queryFn: () => getFinanceRooms(),
  });

  const roomsMap = useMemo(
    () =>
      isLoading || !data
        ? {}
        : Object.fromEntries(data.map((item) => [item.id, item])),
    [isLoading, data],
  );

  useEffect(() => console.log('test', roomsMap), [roomsMap]);

  return isLoading ? undefined : roomsMap[roomId];
};

export default useFinanceRoom;
