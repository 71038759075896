import { forwardRef, useRef } from 'react';
import { styled, Interpolation } from 'styled-components';
import { Box, Icon } from '@/shared/components';
import { mergeRefs } from '@/shared/utils/refs';
import { parseSpacingValue } from '@/theme/utils';
import * as RadixCheckbox from '@radix-ui/react-checkbox';

type ClickAreaSize =
  | number
  | string
  | {
      h: number | string;
      w: number | string;
    };

const StyledRoot = styled(RadixCheckbox.Root)<RadixCheckbox.CheckboxProps & { $clickAreaSize?: ClickAreaSize }>(({
  theme,
  $clickAreaSize,
}) => {
  const styles: Interpolation<RadixCheckbox.CheckboxProps> = {
    all: 'unset',
    display: 'block',
    position: 'relative',
    cursor: 'pointer',
    flex: 'none',
    width: theme.spacing[4.5],
    height: theme.spacing[4.5],
    borderRadius: theme.radius.xs,
    backgroundColor: theme.color.canvas,
    border: `1px solid ${theme.color['gray-600']}`,
    transition: `all 0.15s ${theme.easing['ease-out-cubic']}`,
    '&[data-state="checked"]': {
      backgroundColor: theme.color['accent-500'],
      borderColor: theme.color['accent-500'],
    },
  };

  if ($clickAreaSize) {
    const width = typeof $clickAreaSize === 'object' ? $clickAreaSize.w : $clickAreaSize;
    const height = typeof $clickAreaSize === 'object' ? $clickAreaSize.h : $clickAreaSize;
    Object.assign(styles, {
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'block',
        width: parseSpacingValue(width, theme),
        height: parseSpacingValue(height, theme),
        zIndex: 1,
      },
    });
  }

  return styles;
});

const StyledIndicator = styled(RadixCheckbox.Indicator)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0,
  transition: 'inherit',
  '&[data-state="checked"]': {
    opacity: 1,
  },
});

export interface CheckboxProps extends Omit<RadixCheckbox.CheckboxProps, 'children' | 'onChange' | 'checked'> {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  clickAreaSize?: ClickAreaSize;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { onChange, name, checked, clickAreaSize, ...rest } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onClick?.(event);
    if (props.onChange) {
      const changeEvent: React.ChangeEvent<HTMLInputElement> = Object.create(event, {
        target: { value: inputRef.current },
      });
      onChange?.(changeEvent);
    }
  };

  return (
    <StyledRoot {...rest} checked={checked} onClick={handleClick} $clickAreaSize={clickAreaSize}>
      <StyledIndicator forceMount>
        <Icon name="check" $color="white" $size={18} />
      </StyledIndicator>
      <Box
        as="input"
        type="checkbox"
        ref={mergeRefs([ref, inputRef])}
        name={name}
        checked={checked}
        onChange={onChange} // required to prevent console error
        $display="none"
      />
    </StyledRoot>
  );
});
