import { forwardRef } from 'react';
import { Color, Interpolation, PaletteColorScheme, styled } from 'styled-components';
import { Icon, IconName } from '../icon';
import { Spinner } from '../spinner';

export type ButtonVariant = 'solid' | 'outline' | 'filled' | 'ghost' | 'plain';
export type ButtonSize = 'sm' | 'md' | 'lg';

interface StyledProps {
  $variant?: ButtonVariant;
  $size?: ButtonSize;
  $colorScheme?: PaletteColorScheme;
  $fullWidth?: boolean;
  $disabled?: boolean;
}

const StyledButton = styled.button.attrs({
  type: 'button',
})<StyledProps>(({ theme, $variant = 'solid', $size = 'md', $colorScheme = 'accent', $fullWidth, $disabled }) => {
  const styles: Interpolation<StyledProps> = {
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing[1],
    padding: `0 ${theme.spacing[4]}`,
    width: $fullWidth ? '100%' : undefined,
    height: theme.spacing[9],

    borderRadius: theme.radius.sm,
    border: 'none',
    outline: 'none',
    userSelect: 'none',
    transition: `all 0.3s ${theme.easing['ease-out-quart']}`,
    cursor: 'pointer',
    fontFamily: theme.fontFamily.primary,
    fontWeight: 500,
    ...theme.font['sm'],
  };

  if ($disabled) {
    Object.assign(styles, {
      '&:disabled': {
        opacity: 0.5,
        pointerEvents: 'none',
      },
    });
  }

  if ($size === 'lg') {
    Object.assign(styles, {
      gap: theme.spacing[1.5],
      borderRadius: theme.radius.md,
      height: theme.spacing[12],
      ...theme.font.md,
    });
  }

  // solid variant
  if ($variant === 'solid') {
    let mainColor = `${$colorScheme}-500` as Color;
    let hoverColor = `${$colorScheme}-400` as Color;

    if ($colorScheme === 'neutral') {
      mainColor = 'neutral-800';
      hoverColor = 'neutral-700';
    }

    Object.assign(styles, {
      color: theme.color.white,
      backgroundColor: theme.color[mainColor],
      '&:hover': {
        backgroundColor: theme.color[hoverColor],
      },
    });
  }

  // ghost variant
  else if ($variant === 'ghost') {
    const mainColor = `${$colorScheme}-200` as Color;

    Object.assign(styles, {
      color: theme.color[mainColor],
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: theme.color['neutral-700'],
      },
    });
  }

  // outline variant
  else if ($variant === 'outline') {
    Object.assign(styles, {
      border: `1px solid ${theme.color['gray-200']}`,
      backgroundColor: 'transparent',
      color: theme.color['gray-200'],
      '&:hover': {
        backgroundColor: theme.color['neutral-700'],
      },
    });
  }

  return styles;
});

const StyledSpinnerContainer = styled.span({
  position: 'absolute',
  inset: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'inherit',
});

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement>, Omit<StyledProps, '$disabled'> {
  $icon?: IconName; // with a $ prefix for a possible future icon as a ReactNode property
  loading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { children, $icon, $size, loading, disabled, ...rest } = props;

  const iconSize = $size === 'md' || $size === 'lg' || !$size ? 24 : 18;

  return (
    <StyledButton {...rest} ref={ref} $size={$size} disabled={loading || disabled} $disabled={disabled}>
      {$icon && <Icon name={$icon} $size={iconSize} />}

      {loading && (
        <StyledSpinnerContainer>
          <Spinner $size={iconSize} />
        </StyledSpinnerContainer>
      )}

      {typeof children === 'string' ? <span>{children}</span> : children}
    </StyledButton>
  );
});
