import type { CompletedEducationStep } from '@/features/courses/models';
import type { StepPageParams } from '@/features/courses/types';
import { ApiRequest } from '@/shared/api';
import { Toast } from '@funfarm/kit';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useCompleteEducationStep = (openModal: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ courseId, blockId, moduleId, stepId }: StepPageParams) =>
      new ApiRequest<CompletedEducationStep>({
        method: 'PUT',
        url: `/api/gamification/way/courses/${courseId}/blocks/${blockId}/modules/${moduleId}/steps/${stepId}/complete`,
      }),
    onSuccess: () => {
      openModal();
      queryClient.invalidateQueries({ queryKey: ['courses'] });
      queryClient.invalidateQueries({ queryKey: ['blocks'] });
      queryClient.invalidateQueries({ queryKey: ['modules'] });
      queryClient.invalidateQueries({ queryKey: ['steps'] });
    },
    onError: (error) => {
      Toast.error({ message: error.message });
    },
  });
};
