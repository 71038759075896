import React from 'react';

import { Icon, IconProps } from '../Icon';

export const IconDiagramCircle = (props: IconProps) => {
  return (
    <Icon id="IconDiagramCircle" {...props}>
      <path
        d="M11 3.05469C6.50005 3.55213 3 7.3672 3 11.9998C3 16.9703 7.02944 20.9998 12 20.9998C16.6326 20.9998 20.4476 17.4997 20.9451 12.9998H11V3.05469Z"
        stroke="#ACAFB5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M20.4878 8.99976H15V3.51206C17.5572 4.41589 19.5839 6.4426 20.4878 8.99976Z"
        stroke="#ACAFB5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
