import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetEducationModules } from '@/features/courses/api';
import { Breadcrumbs, EducationCard, TitleWithBackButton } from '@/features/courses/components';
import { styles } from '@/features/courses/styles';
import type { BlockPageParams } from '@/features/courses/types';
import { ROUTES } from '@/routes';
import { Error, Skeleton } from '@funfarm/kit';
import { PageHeader } from './PageHeader/PageHeader';

export const EducationBlock = () => {
  const { t } = useTranslation();

  const { courseId, blockId } = useParams() as BlockPageParams;

  const { data: modules, isLoading, isError } = useGetEducationModules({ courseId, blockId });

  if (isLoading || !modules) return <Skeleton />;
  if (isError) return <Error message={t('fetchingError')} />;

  const backLink = ROUTES.blocksByCourseId(courseId);
  const blockTitle = modules[0].block_title;

  return (
    <>
      <PageHeader modules={modules} />
      <Breadcrumbs
        links={[
          { href: ROUTES.courses, title: t('education.courses') },
          { href: backLink, title: modules[0].course_title },
          { title: blockTitle },
        ]}
      />
      <TitleWithBackButton title={blockTitle} link={backLink} />
      <div className={styles.container}>
        {modules.map((module) => (
          <EducationCard
            key={module.id}
            {...module}
            link={ROUTES.stepsByModuleId({ courseId, blockId, moduleId: module.id })}
          />
        ))}
      </div>
    </>
  );
};
