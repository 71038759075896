import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import css from './radio.module.scss';

interface RadioGroupProps extends PropsWithChildren {
  className?: string;
}

const RadioGroup = (props: RadioGroupProps) => {
  const { className, children } = props;

  return (
    <form className={classNames(css.radio_group, className)}>
      <fieldset className={css.radio_fieldset}>{children}</fieldset>
    </form>
  );
};

export default RadioGroup;
